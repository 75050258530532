var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.xl
  )?_c('v-row',{staticStyle:{"margin-top":"10px","margin-bottom":"5px","margin-left":"0px !important","padding-left":"0px !important"},attrs:{"align":"center","justify":"flex-start"}},[_c('v-spacer'),_c('v-col',{staticStyle:{"padding-left":"8px"},attrs:{"cols":"8"}},[_c('span',{staticClass:"peergroup",staticStyle:{"margin-left":"0px !important","margin-right":"0.5em !important"}},[_vm._v("Select peer group:")]),_c('v-slide-group',{attrs:{"center-active":"","mandatory":"","show-arrows":""},on:{"change":_vm.updateSelectedGroup},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.peerGroup),function(n){return _c('v-slide-item',{key:n.text,scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var active = ref.active;
  var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1  text-none ",staticStyle:{"border":"1.5px solid #b9b2bc","color":"#3F2A48","font-size":"1rem !important","letter-spacing":"0px","line-height":"1rem"},attrs:{"input-value":active,"medium":"","rounded":"","height":"2.067rem","elevation":"0","outlined":"","active-class":"secondary white--text text-none"},on:{"click":toggle}},[_vm._v(" "+_vm._s(n.text)+" ")])]}}],null,true)})}),1)],1),_c('v-spacer')],1):_c('v-menu',{staticStyle:{"margin-left":"10px !important"},attrs:{"offset-y":"","dark":""},on:{"change":_vm.updateSelectedGroupDropdown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"dropdown",staticStyle:{"line-height":"31px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$store.getters.getPeerGroupName.text)+" "),_c('v-icon',{staticStyle:{"padding-bottom":"3px !important"},attrs:{"color":"white"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.peerGroup),function(n){return _c('v-list-item',{key:n.text,staticStyle:{"min-height":"31px !important","margin-top":"0px !important"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.text)},on:{"click":function($event){return _vm.updateSelectedGroupDropdown(n.text)}}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }