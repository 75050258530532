

export default {
  currentCurrency(state)
  {

    return state.currentCurrency;

  },
  currencies(state)
  {

    return state.currencies
  },
  currentYear(state)
  {

    return state.currentYear;

  },
  years(state)
  {

    return state.years
  },
  currentQuartal(state)
  {

    return state.currentQuartal;

  },
  currentYearFallback(state)
  {

    return state.currentYearFallback;

  },
  currentQuartalFallback(state)
  {

    return state.currentQuartalFallback;

  },
  quartals(state)
  {

    return state.quartals;

  },displayType(state){
    return state.displayType;
  },
  changes(state){
    return state.changes;
  },
  time(state){
    return state.time;
  },
  entityPeriods(state){
    return state.entityPeriods

  }
};