<template>
  <v-row
    class="ma-0 pa-0 d-flex"
    height="30px"
    align="center"
    style="margin-top: 10px !important"
    justify="flex-start"
    v-if="
      ($vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.xl)
    "
  >
    <kpi-toggle style="margin-right: 15px !important"></kpi-toggle>
    <currency-selector
      style="margin-right: 15px !important"
    ></currency-selector>
    <time-selection></time-selection>
  </v-row>
  <v-menu offset-y eager :close-on-content-click="false" v-else>
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo"
          dark
          height="31"
          v-bind="attrs"
          v-on="on"
           style="margin-top: 15px !important;margin-right:5px;"
        >
          <v-icon size="18">mdi-tune-variant</v-icon>
        </v-btn>
      </template>
    <v-card height="360" width="200" >
      <v-row class="ma-1 pa-1" ><kpi-toggle  ></kpi-toggle></v-row>
      <v-divider  style="margin:8px;border-color:#695074;opacity: 1;"></v-divider>
      <v-row  class="ma-1 pa-1" ><currency-selector 
      
      ></currency-selector></v-row>
       <v-divider dark style="margin:8px;border-color:#695074;opacity: 1;"></v-divider>
      <v-row  class="ma-1 pa-1" ><time-selection></time-selection></v-row>
    </v-card>
  </v-menu>
</template>

<script>
import CurrencySelector from "./CurrencySelector.vue";
import TimeSelection from "./TimeSelection.vue";
import KpiToggle from "./KpiToggle.vue";
export default {
  components: {
    CurrencySelector,
    TimeSelection,
    KpiToggle,
  },
};
</script>

<style scoped>
.v-card.v-sheet{
  background: rgba(105, 80, 116, 0.5);
  color: white;
  box-shadow: none;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.v-btn {
  background: #695074 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  border: 2px solid #695074 !important;
  opacity: 1;
  height: 31px;
  min-width:31px !important;
  padding: 5px 5px 5px 5px !important;
  margin: 5px 10px 0px 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  float: left;
}
button:hover {
  border: 2px solid #ffffff !important;
  background: none !important;
  opacity: 1;
}


</style>