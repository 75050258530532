
import { API } from "aws-amplify";
import AWS from 'aws-sdk';



export default {
  async updatePeergroup(context, peergroupName)
  {
    let entities = context.getters.getEntities;

    let peerGroup
    if (!entities.length) {
      await this.dispatch('getEntities')
      entities = context.getters.getEntities;
      //console.log(entities)
      //console.log(peergroupName.value)
    }
    if (peergroupName.value === "GCC") {
      peerGroup = entities.filter(entity => entity.subRegion === peergroupName.value)
    } else if ((peergroupName.value === "Top 10 (Size)")) {
      // Top 10 Size => based on SOURCE_DATA: T1|Target_1|MKTCAP field
    } else if ((peergroupName.value === "Top 10 (Premium)")) {
      // Top 10 Premium/Discount: based on WATERFALL_DATA: predicted_target - WATERFALL_DATA: baseline
    } else if ((peergroupName.value === "Bottom 10 (Discount)")) {
      // Top 10 Premium/Discount: based on WATERFALL_DATA: predicted_target - WATERFALL_DATA: baseline
    } else {
      peerGroup = entities.filter(entity => entity.country === peergroupName.value)
      
    }

    context.commit('setPeerGroup', peerGroup);
    context.commit('setPeerGroupName', peergroupName);

  },
  updateEntity(context, entity)
  {

    context.commit('setEntity', entity);

  },
  async getEntities(context)
  {
    if (!context.getters.getEntities.length) {

      //console.log(context)

      const resultEntities = []

      const data = await API.get('quantlensapi', '/entities')
      const items = data.Items

      for (let i = 0; i < items.length; i++) {
        resultEntities.push(AWS.DynamoDB.Converter.unmarshall(items[i]));
      }
      //console.log(resultEntities)
      context.commit('setEntities', resultEntities)
    }


  },
  async getSensitivity(context)
  {
    if (!context.getters.sensitivityData) {

      //console.log(context)

      const resultData = []

      const data = await API.get('quantlensapi', '/sensitivity')
      const items = data.Items

      for (let i = 0; i < items.length; i++) {
        const item = AWS.DynamoDB.Converter.unmarshall(items[i])
        let dataArray = [];
        let valuesString = item.value.replace(/\[|\]/g, '')
        let targetString = item.target.replace(/\[|\]/g, '')
        let values = valuesString.split(",")
        let targets = targetString.split(",")
        //console.log(values)
        values = values.map(str =>
        {
          return Number(str);
        });
        targets = targets.map(str =>
        {
          return Number(str);
        });
        //for( let y = 0; y<values.length;y++){
        dataArray.push({ values: values, targets: targets })
        //}
        resultData.push({ id: item.id, data: dataArray });
      }
      //console.log(resultData)
      context.commit('setSensitivityData', resultData)
    }


  },
  async getWaterfallData(context)
  {
    context.commit("setLoadingPred", true)
    let averagePredictedPeer = 0;
    let resultData = []
    let sourceData = []
    const queryfilter = []
    //console.log('Test')
    let entityData = await loadEntityData(context);
    //.log(entityData)
    const entities = context.getters.getPeerGroup;
    let quartal = context.getters.currentQuartal;
    let year = context.getters.currentYear;
    const quartalFallback = context.getters.currentQuartalFallback;
    const yearFallback = context.getters.currentYearFallback;
    if (quartalFallback != null) {
      year = yearFallback;
      quartal = quartalFallback;
    }
   //console.log(year)

    const displayType = context.getters.displayType;
    const currerentCurrency = context.getters.currentCurrency;
    //console.log(entities)
    if (entities.length) {


      entities.forEach(element =>
      {
        queryfilter.push(element.id)

      });
      //console.log(context)
      //console.log(queryfilter)
      let waterfallDataAllRaw = context.getters.waterfallDataAllRaw;
     
      if(!waterfallDataAllRaw){
         const items = await API.get('quantlensapi', '/waterfall', {
        'queryStringParameters': {
          'entities': queryfilter,
          'quartal': year.text + '_' + quartal.text.split("").reverse().join(""),


        }
      }) 
       const response = items.Responses['waterfall-main']

       for (let i = 0; i < response.length; i++) {
        resultData.push(AWS.DynamoDB.Converter.unmarshall(response[i]));
      }

      }else{
        //console.log("fastTrack")
        resultData = JSON.parse(JSON.stringify(waterfallDataAllRaw.filter(el => queryfilter.includes(el.id)&&el.quartal === year.text + '_' + quartal.text.split("").reverse().join(""))))
        //console.log(resultData)
        //console.log(queryfilter)
      }
     
      //console.log(displayType)


      const sourceItems = await API.get('quantlensapi', '/source', {
        'queryStringParameters': {
          'entities': queryfilter,
          'quartal': year.text + '_' + quartal.text.split("").reverse().join(""),


        }
      })

      let sourceResponse = sourceItems.Responses['sourcedata-main']
      for (let i = 0; i < sourceResponse.length; i++) {
        sourceData.push(AWS.DynamoDB.Converter.unmarshall(sourceResponse[i]));
      }


      //console.log(sourceData)
     
      //console.log(response)
      context.commit('setSourceDataPeer', sourceData)


      averagePredictedPeer = resultData.reduce((accumulator, element) =>
      {
        let number = formatValue(element.predicted_target);
        if (number) {
          return accumulator + number
        } else {
          return accumulator
        }
      }, 0);
      averagePredictedPeer = averagePredictedPeer / resultData.length
      context.commit("setAveragePredictedPeer", averagePredictedPeer)
      let mktcap = 0;



      let actual_target = 0;
      if (displayType === "marketValue") {
        let sourcedataEntity = context.getters.getSourceDataEntity;
        actual_target = formatValue(entityData.actual_target);
        mktcap = sourcedataEntity.MKTCAP_FD
        mktcap = Number(mktcap.replace(/,/, '.'))
        //console.log(actual_target)
      }
      await processEntityData(context, entityData, mktcap);
      for (let i = 0; i < resultData.length; i++) {


        for (const par in resultData[i]) {
          if (par != "id" && par != "quartal") {

            if (displayType === "multiple") {
              resultData[i][par] = formatValue(resultData[i][par])
            } else if (displayType === "marketValue") {
              //console.log("market")
              //console.log(resultData[i])
              //console.log(mktcap)
              resultData[i][par] = (formatValue(resultData[i][par])/ actual_target) * mktcap * currerentCurrency.value
            } else {
              //console.log(Number(resultData[i][par].replace(/,/, '.') * 100) / averagePredictedPeer)
              resultData[i][par] = (formatValue(resultData[i][par]) * 100) / averagePredictedPeer

            }

          }

        }
      }

      context.commit('setWaterfallDataPeer', resultData);
      //console.log("loadingset")
      context.commit("setLoadingPred", false);
      //console.log(resultData)
    }
  },


  async getWaterfallEntityAll(context)
  {
    context.commit("setLoadingArea", true)
    const entity = context.getters.getEntity;
    const quartals = context.getters.time

    const entities = context.getters.getPeerGroup;

    //console.log(context)
    //console.log(entities)
    //console.log(quartals)
    let formatedTime = []
    if (quartals.length) {
      quartals.forEach(element =>
      {
        //let year = element.substring(2)
        //console.log(element.substring(0, 2))
        formatedTime.push(element.substring(2) + '_' + element.substring(1, 2) + element.substring(0, 1))
      });
      //console.log(formatedTime)
    }
    if (entity && entities.length) {
      //await getLastNPeriods(context, entity, entities, formatedTime.slice(-5))
      let queryfilter = [];
      let mktcap = 0;
      let waterfallData = []
      let waterfallDataAllRaw = context.getters.waterfallDataAllRaw;

      const displayType = context.getters.displayType;
      const currerentCurrency = context.getters.currentCurrency;

      if (entity && entities.length) {
        entities.forEach(element =>
        {
          queryfilter.push(element.id)

        });
        queryfilter.push(entity.id)

        const sourceItems = await API.get('quantlensapi', '/source', {
          'queryStringParameters': {
            'entities': entity.id,
            'quartal': formatedTime
          }
        })

        let sourceResponse = sourceItems.Responses['sourcedata-main']

        let marketCapList = [];
        for (let i = 0; i < sourceResponse.length; i++) {
          let item = AWS.DynamoDB.Converter.unmarshall(sourceResponse[i]);
          marketCapList.push({ quartal: item.quartal, value: Number(item.MKTCAP_FD.replace(/,/, '.')) })


        }

        //TODO : set Market cap List 
        context.commit('setMarketCapTimeline', marketCapList)
        //console.log("Market123")
       //console.log(marketCapList)


        //console.log(queryfilter)
        //for (let i = 0; i < queryfilter.length; i = i + 4) {
        //console.log(queryfilter.slice(i, i + 4))

        if (!waterfallDataAllRaw) {
          const result = await API.get('quantlensapi', '/waterfall', {
            'queryStringParameters': {
              'entities': queryfilter,
              'quartal': formatedTime

            }
          })
          //console.log(result)
          //console.log(entities)

          const localData = []
          const responseData = result.Items
          for (let i = 0; i < responseData.length; i++) {
            localData.push(AWS.DynamoDB.Converter.unmarshall(responseData[i]));
          }
          waterfallDataAllRaw = localData;
          context.commit('setWaterfallDataAllRaw', waterfallDataAllRaw)
        }
        waterfallDataAllRaw = context.getters.waterfallDataAllRaw;

        //}
        //console.log(queryfilter)
        let filteredWaterfall = waterfallDataAllRaw.filter(ar => queryfilter.find(rm => (rm === ar.id)))
        //Make DeepCopy of data
        waterfallData = JSON.parse(JSON.stringify(filteredWaterfall))
        context.commit('setWaterfallDataAllRaw', waterfallDataAllRaw)

        //console.log(waterfallData)
        let averagePerQuartal = []

        //console.log("data gathered")
        if (displayType === "percentage") {
          let timeLine = [...new Set(waterfallData.map((a) => a.quartal))];
          for(let quartal of timeLine){ 
            let averagePredictedPeer = 0;
            let waterfallPeer = JSON.parse(JSON.stringify(waterfallData));
            if(!entities.find(rm => (rm.id === entity.id))){
              //delete if not in peergroup
              waterfallPeer = waterfallData.filter(el => el.id != entity.id)
              //console.log("filter")
            }
            waterfallPeer = waterfallPeer.filter(ent => ent.quartal ===quartal)
             averagePredictedPeer = waterfallPeer.reduce((accumulator, element) =>
          {
            let number = Number(element.predicted_target.replace(/,/, '.'));
            if (number) {
              return accumulator + number
            } else {
              return accumulator
            }
          }, 0);
          averagePredictedPeer = averagePredictedPeer / waterfallPeer.length
          //console.log(averagePredictedPeer)
          averagePerQuartal.push({quartal:quartal,value:averagePredictedPeer});
          }
         
          

        }
        //console.log(averagePredictedPeer)
        //let waterfalldataNew = [[]]
        const targetvalues = JSON.parse(JSON.stringify(waterfallData.filter(ent => (ent.id === entity.id))));
        for (let i = 0; i < waterfallData.length; i++) {

          //console.log(waterfallData[i].actual_target)
          //console.log(waterfallData[i])
  
          let quartal = waterfallData[i].quartal
          let entityDataCalc = targetvalues.find(ent => (ent.quartal===quartal));
         //console.log(entity.id)
         //console.log(quartal)
         //console.log(entityDataCalc)
          let actual_target = 0;
          if(entityDataCalc){
             actual_target =formatValue(entityDataCalc.actual_target);
             //console.log(actual_target)
          }
         //console.log(waterfallData)
         //console.log(actual_target)
          if(actual_target){
          mktcap = marketCapList.find(el => el.quartal === quartal)
         //console.log(mktcap)
          for (let par in waterfallData[i]) {
           //console.log(waterfallData[i]["id"])
            if (par != "id" && par != "quartal") {

              if (displayType === "multiple") {
                //console.log(waterfallData[i][par])
                
                waterfallData[i][par] = formatValue(waterfallData[i][par])
              } else if (displayType === "marketValue") {


                //console.log(currerentCurrency.value)
                //console.log(actual_target)
               ////console.log(quartal)
               
               
               //console.log(actual_target)
                if(!waterfallData[i][par]){
                  waterfallData[i][par] = 0
                }
                waterfallData[i][par] = (formatValue(waterfallData[i][par]) / actual_target) * mktcap.value * currerentCurrency.value
               //console.log(waterfallData[i][par])
              } else {
                //console.log("Per")
                let peerAverage = averagePerQuartal.find(ent => ent.quartal === quartal)
               ////console.log(waterfallData[i][par])
                waterfallData[i][par] = (formatValue(waterfallData[i][par]) * 100) / peerAverage.value

              }

            }

          }
          //context.commit("setLoading", false)
        }
      }

        waterfallData.sort(sortByQuarterYear)


        let entityData = waterfallData.filter(el => el.id === entity.id)
        //console.log(queryfilter.find(rm => (rm === entity.id)))
        if(!entities.find(rm => (rm.id === entity.id))){
          waterfallData = waterfallData.filter(el => el.id != entity.id)
          //console.log("filter")
        }
       
        //console.log(entityData)
        //console.log(waterfallData)
        entityData.sort(sortByQuarterYear)
        context.commit('setWaterfallEntityTimeline', entityData)
        context.commit('setWaterfallPeerTimeline', waterfallData)
        entityData = []
        waterfallData = []
        waterfallDataAllRaw = [];
        context.commit("setLoadingArea", false);

      }
    }
  }





};
async function processEntityData(context, entityData, mktcap)
{

  const displayType = context.getters.displayType;
  const currerentCurrency = context.getters.currentCurrency;
  let actual_target = formatValue(entityData.actual_target);

  for (const par in entityData) {
    if (par != "id" && par != "quartal") {

      if (displayType === "multiple") {
        entityData[par] = formatValue(entityData[par])
      } else if (displayType === "marketValue") {
        //console.log("entity")
        //console.log(mktcap)
        //console.log(currerentCurrency.value)
        //console.log(actual_target)
        entityData[par] = (formatValue(entityData[par]) / actual_target) * mktcap * currerentCurrency.value
      } else {
        let averageBaseline = context.getters.averagePredictedPeer
        if (averageBaseline == 0) {
          await this.dispatch("getWaterfallData")
        }
        averageBaseline = context.getters.averagePredictedPeer


        entityData[par] = (formatValue(entityData[par]) / averageBaseline) * 100
      }

    }
  }
  context.commit('setWaterfallEntity', entityData)
}

async function loadEntityData(context)
{
  context.commit("setLoadingPred", true)
  //let mktcap = 0;
  const entity = context.getters.getEntity;
  let currentQuartal = context.getters.currentQuartal;
  let currentYear = context.getters.currentYear;
  if (entity != null) {
    let waterfallDataAllRaw = context.getters.waterfallDataAllRaw;
    let responseData ; 
    
    if (!waterfallDataAllRaw) {

      const result = await API.get('quantlensapi', '/waterfall', {
        'queryStringParameters': {
          'entities': entity.id,
          'quartal': "latest"

        }
      })
      responseData = result.Items
    }else{
      //console.log("test")
      //deep copy
      responseData = JSON.parse(JSON.stringify(waterfallDataAllRaw.filter(el => el.id == entity.id)))
     
    }

     
    responseData.sort(sortByQuarterYear)
    const entityTime = responseData.find(el => el.quartal === (currentYear.text + '_' + currentQuartal.text.split("").reverse().join("")))
    const availablePeriods = []
    responseData.forEach(element =>
    {
      let time = element.quartal.split("_")
      let quartalString = time[1].split("").reverse().join("")
      let period = { quartal: quartalString, year: time[0] };
      availablePeriods.push(period);

    });
    context.commit("setEntityPeriods", availablePeriods)
    let entityData = null;
    if (entityTime) {
      entityData = entityTime
    } else {
      entityData = responseData[responseData.length - 1];
    }
    let time = entityData.quartal.split("_")
    let quartalString = time[1].split("").reverse().join("")
    let quartal = { text: quartalString, value: quartalString };
    let year = { text: time[0], value: Number(time[0]) };
    //console.log(quartal)
    //console.log(year)
    if (year != currentYear || quartal != currentQuartal) {
      context.commit("setYearFallback", year)
      context.commit("setQuartalFallback", quartal)
    } else {
      context.commit("setYear", year)
      context.commit("setQuartal", quartal)
      context.commit("setYearFallback", null)
      context.commit("setQuartalFallback", null)
    }

    //const entityData = AWS.DynamoDB.Converter.unmarshall(latestData)
    //console.log(entityData)
    //console.log(entityData.quartal)
    const sourceItems = await API.get('quantlensapi', '/source', {
      'queryStringParameters': {
        'entities': entity.id,
        'quartal': entityData.quartal



      }
    })

    let sourceResponse = sourceItems.Responses['sourcedata-main']

    let item = AWS.DynamoDB.Converter.unmarshall(sourceResponse[0]);
    //mktcap = Number(item.MKTCAP_FD.replace(/,/, '.'))


    context.commit('setSourceDataEntity', item)
    return entityData;
  }
}
var quarterToMonthMap = {
  "Q1": 0,
  "Q2": 3,
  "Q3": 6,
  "Q4": 9
}


function sortByQuarterYear(lhs, rhs)
{
  var lhsQuarterYear = lhs.quartal.split("_");
  var rhsQuarterYear = rhs.quartal.split("_");
  var lhsDate = new Date(lhsQuarterYear[0], quarterToMonthMap[lhsQuarterYear[1]]);
  var rhsDate = new Date(rhsQuarterYear[0], quarterToMonthMap[rhsQuarterYear[1]]);
  return lhsDate.getTime() - rhsDate.getTime();
}
function formatValue(value){
  if(typeof value === 'number'){
    return value;

  }
  return Number(value.replace(/,/, '.'));
}