import { render, staticRenderFns } from "./TabNavigation.vue?vue&type=template&id=2bd82123&scoped=true&"
import script from "./TabNavigation.vue?vue&type=script&lang=js&"
export * from "./TabNavigation.vue?vue&type=script&lang=js&"
import style0 from "./TabNavigation.vue?vue&type=style&index=0&id=2bd82123&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd82123",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu,VSlideGroup,VSlideItem})
