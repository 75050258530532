

export default {
  setEntities(state, entities)
  {
    state.entities = entities;
  },
  setEntity(state, entity)
  {
    state.entity = entity;
  },
  setDefaultEntity(state, defaultEntity)
  {
    state.defaultEntity = defaultEntity;
  },
  setPeerGroup(state, payload)
  {
    state.peerGroup = payload
  },
  setPeerGroupName(state, name)
  {
    state.peerGroupName = name;
  },
  setWaterfallEntity(state, data)
  {
    state.waterfallEntity = data;
  },
  setWaterfallDataPeer(state, data)
  {
    state.waterfallDataPeer = data;
  },
  setSourceDataPeer(state, data)
  {
    state.sourcedataPeer = data
  },
  setSourceDataEntity(state, data)
  {
    state.sourcedataEntity = data
  }, setWaterfallEntityTimeline(state, data)
  {
    state.waterfallEntityTimeline = data
  },
  setWaterfallPeerTimeline(state, data)
  {
    state.setWaterfallPeerTimeline = data
  },
  setMarketCapTimeline(state, data)
  {
    state.marketCapTimeline = data;
  },
  setAveragePredictedPeer(state, data)
  {
    state.averagePredictedPeer = data;
  },
  setLoadingPred(state,data){
    state.loadingPred = data;
  },
  setLoadingArea(state,data){
    state.loadingArea = data;
  },
  setSensitivityData(state,data){
    state.sensitivityData = data;
  },
  setSensitivityDataCache(state,data){
    state.sensitivityDataCache = data;
  },
  setWaterfallDataAllRaw(state,data) {
    state.waterfallDataAllRaw = data;
  }

};
