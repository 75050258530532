<template>
    
    <v-row align="center" justify="flex-start" style="margin-top:10px; margin-bottom: 5px; margin-left:0px !important;padding-left:0px !important;"   v-if="
      $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.xl
    " >
      <v-spacer></v-spacer>
      <v-col cols="8" style="padding-left:8px;">
        
     
        <span class="peergroup" style="margin-left:0px !important;margin-right:0.5em !important;">Select peer group:</span>
      
        <v-slide-group
          center-active=""
          mandatory
          show-arrows
          v-model="model"
          @change="updateSelectedGroup"
        >
          <v-slide-item
            v-for="n in peerGroup"
            :key="n.text"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
            
              medium
              class="ma-1  text-none "
              style="border: 1.5px solid #b9b2bc; color:#3F2A48;font-size: 1rem !important;letter-spacing: 0px;line-height:1rem;"
              rounded
              height="2.067rem"
              
              
              elevation="0"
              outlined
              active-class="secondary white--text text-none"
              @click="toggle"
            >
              {{ n.text }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      
      </v-col>
       <v-spacer></v-spacer>
    </v-row>
      <v-menu offset-y dark v-else @change="updateSelectedGroupDropdown" style="margin-left:10px !important;">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" class="dropdown" style="line-height:31px;">{{$store.getters.getPeerGroupName.text}} <v-icon color="white" style="padding-bottom:3px !important">mdi-chevron-down</v-icon></span>
    </template>
      <v-list>
        <v-list-item v-for="n of peerGroup" :key="n.text" style="min-height: 31px !important; margin-top: 0px !important">
          <v-list-item-title v-text="n.text" @click="updateSelectedGroupDropdown(n.text)"></v-list-item-title>
        </v-list-item>
      
      </v-list>
    
  </v-menu>

 
</template>

<script>
export default {
  data() {
    return {
      peerGroup: [
        {text: "GCC",value:"GCC"},
        {text: "KSA",value:"Saudi Arabia"},
        {text: "UAE",value:"United Arab Emirates"},
        {text: "Qatar",value:"Qatar"},
        {text: "Kuwait",value:"Kuwait"},
        {text: "Bahrain",value:"Bahrain"},
        {text: "Oman",value:"Oman"},
        //{text: "Top 10(Size)",value:"Top 10(Size)"},
        //{text: "Top 10 (Premium)",value:"Top 10 (Premium)"},
        //{text: "Bottom 10 (Discount)",value:"Bottom 10 (Discount)"},
      ],
      model:null,
    };
  },

  methods: {
    updateSelectedGroup(selecteditem) {
      const selected = this.peerGroup[selecteditem];
      this.$store.dispatch("updatePeergroup", selected);
      this.$store.dispatch("getWaterfallData")
    },
     updateSelectedGroupDropdown(selecteditem) {
      //console.log(selecteditem)
      const selected = this.peerGroup.find(el => el.text === selecteditem);
      this.$store.dispatch("updatePeergroup", selected);
      this.$store.dispatch("getWaterfallData")
    },
  },
  beforeMount(){
     const selected = this.$store.getters.getPeerGroupName
    //console.log(selected)
     this.model = selected;
     this.$store.dispatch("updatePeergroup",  selected);
  }
};
</script>

<style scoped>
.chip-container {
  background: none;
  margin-right: auto !important;
  margin-left: 1em !important;
}
.peergroup {
  margin-top: 0.5em;
  margin-left: 1em;
  float: left;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  background:  rgba(105, 80, 116, 0.5);
  color: white;
  box-shadow: none;
  
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.dropdown{
  background: #695074 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  border: 2px solid #695074 !important;
  opacity: 1;
  height: 31px;
  margin-left:10px !important;
  line-height:13px !important;
    margin-top:15px !important;
  min-width:51px !important;
  padding: 2px 5px 0px 7px !important;
  text-align: left;
  letter-spacing: 0px;
  vertical-align: top;
  color: #ffffff !important;
  opacity: 1;
  float: left;
}
.dropdown:hover {
  border: 2px solid #ffffff !important;
  background: none !important;
  opacity: 1;
}

</style>