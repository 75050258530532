<template>
  <v-container fluid class="container-new">
    <v-row align="center" class ="ma-1 pa-1">
      <v-col cols="12" align="center" class="pa-0 ma-0">
        <v-slide-group
          center-active=""
          mandatory
          :show-arrows="isMobile && categories.length>4"
          @change="updateSelectedGroup"
        >
          <v-slide-item
            v-for="n in categories"
            :key="n"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
              medium
              class="ma-1 text-none "
              style="border: 1.5px solid #b9b2bc; color:#3F2A48;letter-spacing: 0px; font-size:1rem !important;line-height:1rem;"
              rounded
              height="2.067rem"
              width="6.067rem"
              elevation="0"
              outlined
              active-class="secondary white--text"
              @click="toggle"
            >
              {{ n }}
            </v-btn>
          </v-slide-item>
        </v-slide-group></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  props: [
    "categories"
  ],
  computed : {
    isMobile() {
  //&&console.log(this.categories.length<4)
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
  },
  mounted () {
    this.updateSelectedGroup(0)
  },
  methods: {
    updateSelectedGroup(selecteditem) {
        //console.log(this.categories[selecteditem])
        this.$store.commit('setSelectedCategory',this.categories[selecteditem])
      //const selected = this.peerGroup[selecteditem];
      //this.$store.dispatch("updatePeergroup", selected);
    },
  },
};
</script>

<style scoped>

span {
  margin-top: 0.5em;
  margin-left: 1em;
  float: left;
}
@media (min-width:960px) {
  .container-new{
  background-color:none !important;
  margin-left:0px !important;
  padding-bottom:0px !important
}
}

@media (max-width:960px) {
  .container-new{
    padding-top:2px !important;
    padding-bottom:0px !important
}
}


</style>