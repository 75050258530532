import { render, staticRenderFns } from "./CurrencySelector.vue?vue&type=template&id=1fb89684&scoped=true&"
import script from "./CurrencySelector.vue?vue&type=script&lang=js&"
export * from "./CurrencySelector.vue?vue&type=script&lang=js&"
import style0 from "./CurrencySelector.vue?vue&type=style&index=0&id=1fb89684&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb89684",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VIcon,VSelect})
