<template>
  <v-slide-group
    multiple
    show-arrows
    dark
    
    style="margin-top: 5px !important"
    v-if="
      $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.xl
    "
  >
    <v-slide-item v-for="page in pages" :key="page.to">
      <router-link class="tab-link" :to="page.to">{{ page.des }} <v-icon></v-icon></router-link>
    </v-slide-item>
  </v-slide-group>
  <v-menu offset-y dark v-else :items="pages">
    <template v-slot:activator="{ on, attrs }">
    <div v-bind="attrs" v-on="on"  style="display:inline-block; float:left;line-height:31px;padding: 5px 5px 0px 7px !important;margin-top:10px !important;width:210px"><span style="margin-top:0px !important;padding-top:7px !important;">{{$route.name}}</span> <v-icon color="white" style="margin-left: -30px;padding-bottom:3px;">mdi-chevron-down</v-icon></div>
    </template>
      <v-list>
        <v-list-item @click="$router.push('/predicted-valuation')"  style="min-height: 31px !important; margin-top: 0px !important">
          <v-list-item-title class="text-white text-left"
            >AI Predicted Valuation</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="$router.push('/valuation-timeline')"  style="min-height: 31px !important; margin-top: 0px !important">
          <v-list-item-title class="text--white text-left "
            >Valuation Timeline</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="$router.push('/valuation-simulator')"  style="min-height: 31px !important; margin-top: 0px !important">
          <v-list-item-title class="text-white text-left"
            >Valuation Simulator
          </v-list-item-title>
        </v-list-item>
      </v-list>
    
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      pages: [
        { to: "/predicted-valuation", des: "AI Predicted Valuation" },
        // { to: "/valuation-drivers", des: "Valuation Drivers" },
        { to: "/valuation-timeline", des: "Valuation Timeline" },
        { to: "/valuation-simulator", des: "Valuation Simulation" },
      ],
    };
  },
};
</script>

<style scoped>
a:link,
a:visited {
  text-decoration: none;
  color: white;
}
section {
  margin-top: 10px;
}

.tab-link {
  background: #695074 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: 2px;
  padding: 0.267rem 1.067rem 0.267rem 1.067rem;
  margin: 1rem 0.75rem 1rem 0rem;
  text-align: left;
  letter-spacing: 0px !important;
  height: 2.4rem;
  font-size: 1.2rem;
  color: #ffffff;
  opacity: 1;
  z-index: 0;
}
.tab-link.router-link-active {
  background: #5ab7b7;
  border: 2px solid #5ab7b7;
  padding: 0.133rem 0.933rem 0.133rem 0.933rem;
}

.tab-link:hover {
  border: 2px solid #ffffff;
  background: none;
  padding: 0.133rem 0.933rem 0.133rem 0.933rem;
}

.v-sheet.v-list:not(.v-sheet--outlined) {
  background:  rgba(105, 80, 116, 0.5);
  color: white;
  box-shadow: none;
  
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
span{
  background: #695074 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  border: 2px solid #695074 !important;
  opacity: 1;
  height: 31px;
  line-height:13px !important;
  margin-top:15px !important;
  min-width:200px !important;
  padding: 2px 5px 0px 7px !important;
  text-align: left;
  letter-spacing: 0px;
  vertical-align: top;
  color: #ffffff !important;
  opacity: 1;
  float: left;
}
span:hover {
  border: 2px solid #ffffff !important;
  background: none !important;
  opacity: 1;
}


</style>
