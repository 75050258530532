import { Auth } from "aws-amplify";


export default {
  async login(context, payload)
  {

    //let user;
    try {
      await Auth.signIn(payload.email, payload.password);
    } catch (error) {
      if(error.message!="Pending sign-in attempt already in progress"){
        //console.log(error)
      const error_message = new Error(
        'Invalid email or password!'
      );
      throw error_message;
      }
     
    }
    Auth.currentAuthenticatedUser().then((user) =>
    {
     
      const currentUser = {
          
        gender: user.attributes["gender"],
        name : user.attributes["given_name"],
        familyName: user.attributes["family_name"],
        company:user.attributes["custom:company"]
      }
  

    localStorage.setItem('gender',currentUser.gender)
    localStorage.setItem('name',currentUser.name)
    localStorage.setItem('familyName',currentUser.familyName)
    
    });
   
    //console.log(Auth.currentAuthenticatedUser())
    //set name

  },
  async logout()
  {
    localStorage.removeItem('currencyText')
    localStorage.removeItem('currencyValue')
    localStorage.removeItem('user')
    localStorage.removeItem('displayType')
    localStorage.removeItem('selectedEntity')
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      //console.log('error signing out: ', error);
      await Auth.signOut();
    }
  },

};
