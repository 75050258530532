<template>
  <div height="100%">
    <v-row align="center" justify="start">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12" >
        <v-sheet rounded="lg" elevation="0">
          <card-container></card-container>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider
      dark
      style="margin: 0px 15px 10px 15px; border-color: #f2f2f2; opacity: 1; z-index: 30"
      v-if="isMobile"
    ></v-divider>
    <v-row align="center" justify="start">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12">
        <v-sheet rounded="lg" elevation="0" :height="isMobile?370:650">
          <div class="title">{{ getTitle }}</div>
          <category-slidegroup :categories="categories"></category-slidegroup>
          <waterfall-chart
            :placeholderData="placeholderData"
            :waterfallLabel="waterfallLabel"
            :waterfallDataPos="waterfallDataPosGlobal"
            :waterfallDataNeg="waterfallDataNeg"
            :entityName="entityName"
            :peerGroupName="peerGroupName.text"
            :selectedCategory="selectedCategory"
            :labelString="labelString"
            
          ></waterfall-chart>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider
      dark
      style="margin: 15px; border-color: #f2f2f2; opacity: 1; z-index: 30"
      v-if="isMobile"
    ></v-divider>
    <v-row
      align="center"
      justify="start"
      style="margin-bottom: 20px !important"
    >
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12">
        <comment-section
          :waterfallData="waterfallData"
          :waterfallLabels="waterfallLabel"
          :waterfallObjects="waterfallObjects"
        ></comment-section>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import CardContainer from "../components/ValuationDrivers/CardContainer.vue";
import WaterfallChart from "@/components/ValuationDrivers/WaterfallChart.vue";
import CategorySlidegroup from "@/components/layout/CategorySlidegroup.vue";
import CommentSection from "@/components/ValuationTImeline/CommentSection.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CardContainer,
    WaterfallChart,
    CategorySlidegroup,
    CommentSection,
  },
  data() {
    return {
      waterfallLabel: [],
      waterfallDataPos: [],
      waterfallDataPosGlobal: [],
      waterfallDataNeg: [],
      waterfallData: [],
      placeholderData: [],
      placeholderSum: 0,
      counter: 0,
      waterfallObjects: [],
      categories: ["Summary", "Company", "Financial", "Market", "Economic"],
      labelString: "",
      finishedLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
      waterfallDataPeer: "getWaterfallDataPeer",
      entity: "getEntity",
      peerGroupName: "getPeerGroupName",
      companyFactors: "companyFactors",
      economicFactors: "economicFactors",
      financialFactors: "financialFactors",
      marketFactors: "marketFactors",
      displayType: "displayType",
      currentCurrency: "currentCurrency",
      peerGroup: "getPeerGroup",

      // ...
    }),
 
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    entityName() {
      if (this.entity) {
        return this.entity.entityCode;
      } else {
        return "";
      }
    },
    getTitle() {
      return (
        this.entityName +
        " Bank Valuation Drivers Relative to Average Valuation of " +
        this.peerGroupName.text +
        " Banks " +
        this.displayTypeString
      );
    },
    displayTypeString() {
      if (this.displayType === "marketValue") {
        return `(${this.currentCurrency.text} million)`;
      } else if (this.displayType == "percentage") {
        return "(%)";
      } else {
        return "(P/TBV)";
      }
    },
  },
  methods: {
    prepareValueForCur(value) {
      if (this.displayType === "marketValue") {
        return Number(Math.round(value));
      } else if (this.displayType === "multiple") {
        return Number(value.toFixed(2));
      } else {
        return Number(value.toFixed(1));
      }
    },

    prepareLabelstring(value) {
      if (this.displayType === "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.displayType === "multiple") {
        return Number(value.toFixed(2));
      } else {
        return Number(value.toFixed(1));
      }
    },
    prepareDataForCategoriesPeer() {
      //console.log("triggered");
      this.waterfallObjects = [];

      const entityData = this.$store.getters.getWaterfallEntity;
      this.waterfallDataPosGlobal = [];

      if (
        this.waterfallDataPeer &&
        entityData &&
        this.waterfallDataPeer.length
      ) {
        let dataPeer = this.waterfallDataPeer;
        //console.log(dataPeer.map((item) => item.id));
        dataPeer = dataPeer.filter((el) => el.id != entityData.id);

        ////console.log(dataPeer)

        for (const peer of dataPeer) {
          dataPeer = [];
          dataPeer = this.waterfallDataPeer.slice();

          //dataPeer = dataPeer.filter((el) => el.id != peer.id);
          dataPeer = dataPeer.filter((el) => el.id != entityData.id);
          //console.log(dataPeer);

          this.counter = 0;
          this.placeholderSum = 0;
          this.waterfallLabel = [];
          (this.waterfallDataNeg = []), (this.waterfallDataPos = []);
          this.placeholderData = [];
          this.waterfallData = [];
          if (dataPeer && dataPeer.length - 1) {
            this.calculateWaterfallforPeer(peer, dataPeer);
          }
        }
        this.counter = 0;
        this.placeholderSum = 0;
        this.waterfallLabel = [];
        (this.waterfallDataNeg = []), (this.waterfallDataPos = []);
        this.placeholderData = [];
        this.waterfallData = [];

        dataPeer = this.$store.getters.getWaterfallDataPeer.slice();
        let checkDuplicates = dataPeer.filter((el) => el.id == entityData.id);
        if (checkDuplicates) {
          //dataPeer = dataPeer.slice(checkDuplicates[0],1)
          //dataPeer.push(entityData);
        }
        this.calculateWaterfallforPeer(entityData, dataPeer);

        //console.log();
        //dataPeer.push(entityData);
        //console.log("index" + dataPeer.indexOf(checkDuplicates[0]));
        //console.log(dataPeer.filter((el) => el.id == entityData.id));

        this.waterfallDataPosGlobal = this.waterfallDataPos;
      }
    },
    calculateWaterfallforPeer(peer, dataPeer) {
      let baseline = 0;
      for (let i = 0; i < dataPeer.length; i++) {
        ////console.log(dataPeer[i])
        baseline += Number(dataPeer[i].predicted_target);
      }

      baseline = Number((baseline / dataPeer.length).toFixed(2));
      const predicted = Number(peer.predicted_target);
      if (!this.isMobile) {
        this.waterfallLabel.push(
          `Average Valuation for ${this.peerGroupName.text} banks`
        );
      } else {
        this.waterfallLabel.push(this.peerGroupName.text);
      }

      this.waterfallData.push([this.counter, 0]);
      this.waterfallDataPos.push({
        value: this.prepareValueForCur(baseline),
        itemStyle: {
          color: "#B9B2BC",
          borderColor: "white",
        },
        label: {
          show: true,
          position: "top",
          offset: [0, 0],
        },
      });
      this.waterfallDataNeg.push([this.counter, "-"]);
      this.placeholderData.push([this.counter, this.placeholderSum]);
      this.counter++;
      this.placeholderSum += baseline;

      this.prepareDataperCategoryPeer(
        dataPeer,
        peer,
        this.companyFactors,
        "Company"
      );
      this.prepareDataperCategoryPeer(
        dataPeer,
        peer,
        this.financialFactors,
        "Financial"
      );
      this.prepareDataperCategoryPeer(
        dataPeer,
        peer,
        this.marketFactors,
        "Market"
      );
      this.prepareDataperCategoryPeer(
        dataPeer,
        peer,
        this.economicFactors,
        "Economic"
      );
      if (!this.isMobile) {
        this.waterfallLabel.push(`${this.entityName} AI-PredictedValuation`);
      } else {
        this.waterfallLabel.push(this.entityName);
      }

      this.waterfallDataPos.push({
        value: this.prepareValueForCur(predicted),
        itemStyle: {
          color: "#3F2A48",
          borderColor: "white",
        },
        label: {
          show: true,
          position: "top",
          offset: [0, 0],
        },
      });
      //this.waterfallDataNeg.push([this.counter, '-']);
      this.placeholderData.push([this.counter, 0]);
      const obj = { id: peer.id };

      this.waterfallLabel.forEach((element, index) => {
        //console.log(element);
        if (this.waterfallData[index]) {
          obj[element] = this.waterfallData[index].value;
        } else {
          obj[element] = 0;
        }
      });
      //console.log(obj);
      this.waterfallObjects.push(obj);

      //console.log(this.waterfallObjects);
    },
    prepareDataperCategoryPeer(data, entityData, factors, factorString) {
      let sum = 0;
      let entityFactorSum = 0;
      let addedFactors = 0;
      //console.log(factors);
      //iterate through all factors
      for (let i = 0; i < factors.length; i++) {
        //get factor to calculate
        let id = factors[i].id;
        //console.log("test");
        // get label
        let desc;
        if (this.isMobile) {
          desc = factors[i].mobileCode;
        } else {
          desc = factors[i].description;
        }

        let factor = 0;
        let entityFactor = entityData[id];

        //iterate trough all datasets for this factor

        for (let i = 0; i < data.length; i++) {
          let item = data[i];

          let dataItem = item[id];
          if (dataItem) {
            factor += dataItem;
          }
        }

        if (this.$store.getters.selectedCategory === factorString) {
          factor = entityFactor - Number(factor / data.length);
          console.log(factor)
          if (factor && this.prepareValueForCur(Number(factor))!= 0) {
            //console.log(factor);
            //console.log(this.placeholderSum);
            //console.log(desc);
            if (factor < 0) {
              this.placeholderData.push([
                this.counter,
                Number((this.placeholderSum + factor).toFixed(2)),
              ]);
              this.waterfallLabel.push(desc.replace(/-/, " "));
              this.waterfallDataNeg.push([
                this.counter,
                this.prepareValueForCur(Number(-factor)),
              ]);
              this.waterfallDataPos.push([this.counter, "-"]);
              this.waterfallData.push({
                name: desc,
                value: this.prepareValueForCur(Number(factor)),
              });
              addedFactors +=1;
            } else {
              this.placeholderData.push([
                this.counter,
                Number(this.placeholderSum),
              ]);
              this.waterfallLabel.push(desc.replace(/-/, " "));
              this.waterfallDataNeg.push([this.counter, "-"]);
              this.waterfallDataPos.push([
                this.counter,
                this.prepareValueForCur(Number(factor)),
              ]);
              this.waterfallData.push({
                name: desc,
                value: this.prepareValueForCur(Number(factor)),
              });
              addedFactors +=1;
            }

            this.counter++;
            this.placeholderSum += factor;
            if(this.prepareValueForCur(Number(factor))!= 0){
             sum += factor;
          }
            if (entityFactor&&  this.prepareValueForCur(Number(entityFactor))!= 0) {
              entityFactorSum += entityFactor;
            }
          }
          let symbol = "";
          if (sum > 0) {
            symbol = "+";
          }
          this.labelString =
            factorString + " " + symbol + this.prepareLabelstring(sum);
        } else {
          if(this.prepareValueForCur(Number(factor))!= 0){
             sum += factor;
          }
         
          if (entityFactor &&  this.prepareValueForCur(Number(entityFactor))!= 0) {
            entityFactorSum += entityFactor;
          }

          factor = 0;
        }
      }
      console.log(this.$store.getters.selectedCategory)
      console.log(factorString)
      console.log(addedFactors)
      if (this.$store.getters.selectedCategory != factorString || addedFactors==0) {
        //(this.$store.getters.selectedCategory);
        sum = entityFactorSum - Number(sum / data.length);
        this.placeholderSum += sum;
        console.log(sum)
        if (sum!=null) {
          if (sum < 0) {
            //console.log(sum);
            //console.log(this.placeholderSum);
            this.placeholderData.push([
              this.counter,
              Number(this.placeholderSum.toFixed(2)),
            ]);
            this.waterfallLabel.push(this.getFactorString(factorString));
            this.waterfallDataNeg.push([
              this.counter,
              this.prepareValueForCur(Number(-sum)),
            ]);
            this.waterfallDataPos.push([this.counter, "-"]);
            this.waterfallData.push({
              name: this.getFactorString(factorString),
              value: this.prepareValueForCur(Number(sum)),
            });
          } else {
            this.placeholderData.push([
              this.counter,
              Number(this.placeholderSum - sum),
            ]);
            this.waterfallLabel.push(this.getFactorString(factorString));
            this.waterfallDataNeg.push([this.counter, "-"]);
            this.waterfallDataPos.push([
              this.counter,
              this.prepareValueForCur(Number(sum)),
            ]);
            this.waterfallData.push({
              name: this.getFactorString(factorString),
              value: this.prepareValueForCur(Number(sum)),
            });
          }
          this.counter++;
        }
      }
    },
    getFactorString(factorString) {
      if (!this.isMobile) {
        return factorString;
      }
      switch (factorString) {
        case "Economic":
          return "ECO";
        case "Market":
          return "MKT";
        case "Financial":
          return "FIN";
        case "Company":
          return "CMP";
      }
    },
  },
  created() {
    document.body.className = "default";

    //this.$store.dispatch("updatePeergroup",  {text: "GCC",value:"GCC"});
  },
  watch: {
    "$store.getters.selectedCategory": {
      immediate: true,
      deep: true,
      handler() {
        this.prepareDataForCategoriesPeer();
      },
    },

    "$store.getters.getWaterfallDataPeer": {
      immediate: true,
      deep: true,
      handler() {
        //console.log("test");
        this.prepareDataForCategoriesPeer();
      },
    },
  },
};
</script>
<style scoped>
section {
  height: 1000px;
  background-color: #f7f7f9;
}
.maindiv {
  margin: auto;
}
.card__container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 25vh;
  z-index: 0;
}
@media (min-width: 960px) {
  .title {
    margin-left: 25px;
    padding-top: 30px !important;
    font-size: 1.2rem !important;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
  }
}
@media (max-width: 960px) {
  .title {
     padding-left: 25px !important;
    padding-right:10px !important;
    padding-top: 0px !important;
    font-size: 14px !important;
    line-height:1.5rem !important;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
  }
}
.small_card {
  font-weight: bold;
  box-shadow: none;
  overflow: none;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: auto;
  justify-content: center;
  background-color: white;
  width: auto;
}
</style>