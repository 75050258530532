//import { Auth } from 'aws-amplify';
export default {
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state){
    return !!state.user;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  }
};