<template>
  <v-row align="center" class="ma-0 pa-0 align-center border-header" :key="key" v-show="selectedFactor==factorData.description || !isMobile" >
    <v-col cols="12" lg="3" xl="3" md="3" sm="12" xs="12" class="spacer-heading ma-0 pa-0" >
      <span class="ma-1 pa-0 align-left spacer-heading" :style="isMobile?'float: left;margin-left:2px !important;':'float:left;margin-left:0px !important;'">
     {{
        factorData.description
      }}</span>
    </v-col>
    <div style="width:100%; height:20px;" v-if="isMobile"></div>
    <v-col cols="10" lg="6" xl="6" md="6" sm="10" xs="10" class="ma-0 pa-0"
      ><slider-component
        :value="factorData.value"
        :factorId="factorId"
        :reset="reset"
        :min="sliderMin"
        :max="sliderMax"
        @resetCheckboxes="resetCheckbox"
        @recalculateWaterfall="interpolateValue"
      ></slider-component
    ></v-col>
    <v-col cols="2" class="ma-0 pa-0" v-if="!isMobile">
      <sparkline-component 
        :description="factorData.description"
        :data="factorData.sensitivity"
        :markpoint="markpoint"
      ></sparkline-component>
    </v-col>
    <v-col cols="1" class="ma-0 pa-0"
      ><v-checkbox
        class="ma-0 pa-0 align-center"
        v-model="resetLocal"
        style="margin-bottom: 0px !important;margin-left:22px !important;"
      >
      </v-checkbox
    ></v-col>
  </v-row>
</template>

<script>
import SliderComponent from "./SliderComponent.vue";
import SparklineComponent from "./SparklineComponent.vue";

export default {
  components: {
    SliderComponent,
    SparklineComponent,
  },
  props: ["factorData", "resetAll","counter","selectedFactor","resetParent"],
  data() {
    return {
      reset: false,
      resetLocal: false,
      key:0,
      markpoint: [{ xAxis: this.factorData.value, yAxis: 1 }],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    
    sliderMin() {
      if (this.factorData.sensitivity) {
        return this.factorData.sensitivity.data[0].values[0];
      } else {
        return 0;
      }
    },
    sliderMax() {
      if (this.factorData.sensitivity) {
        return this.factorData.sensitivity.data[0].values[
          this.factorData.sensitivity.data[0].values.length - 1
        ];
      } else {
        return 0;
      }
    },
    factorId() {
      //console.log(this.factorData);
      if (this.factorData.sensitivity) {
        return this.factorData.sensitivity.id;
      } else {
        return "";
      }
    },
  },
  methods: {
    interpolateValue(sourceValue) {
      if (this.factorData.sensitivity) {
        //console.log("markedPoint")
        const linear = require("everpolate").linear;

        const interpolatedValue = linear(
          sourceValue,
          this.factorData.sensitivity.data[0].values,
          this.factorData.sensitivity.data[0].targets
        );
        this.markpoint = [
          {
            xAxis: sourceValue,
            yAxis: interpolatedValue,
          },
        ];
        //console.log(this.markpoint);
        this.$emit("recalculateWaterfall", {
          id: this.factorId,
          value: interpolatedValue[0],
        });
      } else {
        //this.$emit("recalculateWaterfall",{id : this.factorId, value:0})
      }
    },
    resetCheckbox() {
      this.resetLocal = false;
      this.reset = false;
      this.$emit("resetCheckBoxValues");
    },
  },

  watch: {
    factorData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.factorData.sensitivity) {
          //console.log(this.factorData)
          const linear = require("everpolate").linear;

          const interpolatedValue = linear(
            this.factorData.value,
            this.factorData.sensitivity.data[0].values,
            this.factorData.sensitivity.data[0].targets
          );
          this.markpoint = [
            {
              xAxis: this.factorData.value,
              yAxis: interpolatedValue[0],
            },
          ];
          //console.log(this.markpoint);
        }
        this.key++;
      },
    },
    resetAll: {
      immediate: true,
      deep: true,
      handler() {
     // //console.log(this.resetAll);
     // //console.log(this.resetLocal);
        if (this.resetAll != "none") {
          if ( this.resetAll) {
            //console.log("resetall");
            this.reset = this.resetLocal;
            //this.resetLocal = this.resetLocal;
          } else {
            this.reset = this.resetLocal;
          }
          //this.interpolateValue(this.factorData.value);
        }
      },
    },
    resetParent: {
      immediate: true,
      deep: true,
      handler() {
        //console.log(this.resetAll);
       this.resetLocal = this.resetParent;
      },
    },
  },
};
</script>

<style scoped>
::v-deep .v-messages {
  min-height: 0px !important;
}
 

@media(max-width:960px){
 
  .border-header{
     border: 1px solid #F2F2F2 !important;
     border-radius:10px !important;
     padding: 10px !important;
  }
}

</style>