<template>
  <v-row style="margin-top: 10px" class="ma-0 pa-0" height="15px">
    <v-slider
      v-model="factorValue"
      :max="max"
      style="width: 250px !important; height: 30px !important"
      :min="min"
      color="secondary"
      class="align-center ma-0 pa-0"
      height="20"
      step="0.001"
      width="350"
      @change="returnValue"
      @click="returnValue"
    >
      <template v-slot:prepend>
        <v-text-field
          v-model="encodeCategoricalFactors"
          class="align-center ma-0 pa-0"
          filled
          rounded
          dense
          readonly
          color="#707070"
          type="string"
          style="
            width: 80px;
            height: 10px !important;
            font-size: 0.75rem !important;
            color: #707070 !important;
            text-align: center !important;
            padding: -5px !important;
          "
        ></v-text-field>
      </template>
    </v-slider>
  </v-row>
</template>

<script>
export default {
  props: ["value", "factorId", "reset", "min", "max"],
  data() {
    return {
      factorValue: this.value,
      defaultvalue: 0,
    };
  },
  computed: {
    maxValue() {
      return this.defaultvalue + this.defaultvalue * 0.1;
    },
    encodeCategoricalFactors() {
      //value = Number(value.replace(/,/, "."));
      if (this.factorId && !this.isCategory) {
        if (this.factorId === "R13") {
          let encoding = this.$store.getters.esgEncoding;
          //console.log(value);
          return encoding.find(
            (el) => el.value === Math.round(this.factorValue)
          ).short;
        } else if (this.factorId === "R28") {
          let encoding = this.$store.getters.msciEncoding;
          return encoding.find(
            (el) => el.value === Math.round(this.factorValue)
          ).name;
        } else if (this.factorId === "R09") {
          let encoding = this.$store.getters.creditratingEncoding;
          //console.log(value);
          return encoding.find(
            (el) => el.value === Math.round(this.factorValue)
          ).short;
        } else if (this.factorId === "R10") {
          let encoding = this.$store.getters.ratingWatch;
          return encoding.find(
            (el) => el.value === Math.round(this.factorValue)
          ).name;
        } else if (this.factorId === "R29") {
          let encoding = this.$store.getters.irQualityEncoding;
          return encoding.find(
            (el) => el.value === Math.round(this.factorValue)
          ).name;
        } else if (this.factorId === "R11") {
          return Math.round(this.factorValue)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else if (this.factorId === "R42") {
          return "USD " + this.factorValue.toFixed(1);
        } else if (this.factorId === "R04" || this.factorId === "R05") {
          return (this.factorValue * 100).toFixed(1) + "%";
        } else if (
          this.factorId === "R01" ||
          this.factorId == "R02" ||
          this.factorId == "R03" ||
          this.factorId == "R07" ||
          this.factorId== "R36"
        ) {
          return (this.factorValue * 100).toFixed(2) + "%";
        } else if (this.factorId === "R21") {
          //console.log(value)
          return Math.round(this.factorValue);
        }  else {
          ////console.log(value)
          return Math.round(this.factorValue * 100) + "%";
        }
      }
      return "";
    },
  },
  methods: {
    returnValue() {
      this.$emit("recalculateWaterfall", this.factorValue);
    },
  },
  created() {
    this.defaultvalue = this.value;
    this.$emit("recalculateWaterfall", this.factorValue);
  },
  watch: {
    reset: {
      immediate: true,
      deep: true,
      handler(reset) {
        //console.log("resetSlider")
        //console.log(reset);
        if (reset == true) {
          //console.log(this.factorValue);
          this.factorValue = this.defaultvalue;
          this.$emit("resetCheckboxes");
          this.$emit("recalculateWaterfall", this.factorValue);
        }
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("recalculateWaterfall", this.factorValue);
      },
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  min-height: 20px !important;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 0px !important;
}
::v-deep .v-slider--horizontal {
  margin-bottom: 25px;
}
::v-deep
  .v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
::v-deep .v-input input {
  text-align: center;
}
</style>