<template>
  <img :src="graphic" class="rotate" />
</template>

<script>
export default {
  data() {
    return {
      graphic: require("../../assets/Iridium_grahpic_rotate.svg"),
    };
  },
};
</script>

<style scoped>

@media (max-width: 920px) {
img {
  width: 300%;
  height: 160%;
  left: -30%;
  position: fixed;
  top: -30%;
  transform: translate(0px, -50%);
}
}
@media (min-width: 920px) {
 img {
  width: 100%;
  height: 160%;
  right: -20%;
  position: fixed;
  top: -30%;
  transform: translate(0px, -50%);
}
}
.rotate {
  animation: rotation 45s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>