<template>
<v-container fluid class="align-center ma-0 pa-0 justify-center">
  <div
    class="align-center ma-0 pa-0"
    :id="chartName"
    style="width: 80px; height: 25px; margin:auto !important"
  ></div>
  </v-container>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
export default {
  data() {
    return {
      name: "Sparkline" + this.description,
      chart: null,
      chartData: [],
    };
  },
  props: ["description", "data", "markpoint"],
  computed: {
    chartName() {
      return "sparkline" + this.description;
    },
  },

  methods: {
    produceChartData() {
      const resultArrays = [];
      for (let i = 0; i < this.data.data[0].targets.length; i++) {
        resultArrays.push([
          this.data.data[0].values[i],
          this.data.data[0].targets[i],
        ]);
      }
      this.chartData = resultArrays;
    },
    drawGraph() {
      //console.log(this.data);
      if (this.chart == null) {
        return;
      }

      ////console.log(this.markpoint);
      this.chart.setOption({
        textStyle: {
          fontFamily: "guardianegyp-medium",
        },

        xAxis: {
          type: "value",

          splitLine: { show: false },
          min: function (value) {
            return value.min*0.98;
          },
          max: function (value) {
            return value.max*1.05;
          },
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
        },
        yAxis: {
          axisLine: { show: true },
          type: "value",
          show: true,
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          min: function (value) {
            return value.min*0.98;
          },
          max: function (value) {
            return value.max*1.1;
          },
        },
        grid: {
          left: "5%",
          top: "0%",
          right: "0%",
          bottom: "5%",
          backgroundColor: "#FAFAFC",
          borderWidth: 0,

          show: true,
        },

        series: [
          {
            data: this.chartData,
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: "#5AB7B7",
            },
            markPoint: {
              data: this.markpoint,
              label: {
                show: false,
              },
              itemStyle : {
                 color:"#3F2A48",
              },
              symbol: "circle",
              symbolSize: 5,
            },
          },
        ],
      });
    },
    resizeChart() {
      if (this.chart != null) {
        //console.log("now");
        this.chart.resize();
      }
    },
  },
  mounted() {
    //console.log("Mounted");
    echarts.use([
      GridComponent,
      LineChart,
      CanvasRenderer,
      UniversalTransition,
    ]);
    this.chart = echarts.init(document.getElementById(this.chartName), null);
    if (this.data && this.data.data[0]) {
      this.produceChartData();
      this.drawGraph();
    }
  },
  created() {
    window.addEventListener("resize", this.resizeChart,{passive: true});
    if (this.data && this.data.data[0]) {
      this.produceChartData();
      this.drawGraph();
    }
  },
  watch: {
    "$store.getters.selectedCategory": {
      immediate: true,
      deep: true,
      handler() {
        if (this.data && this.data.data[0]) {
          this.produceChartData();
          ////console.log(this.chartData);
          this.drawGraph();
        }
      },
    },
    data: {
      immediate: true,
      deep: true,
      handler() {
        //console.log(this.data);
        if (this.data && this.data.data[0]) {
          this.produceChartData();

          this.drawGraph();
        }
      },
    },
    markpoint: {
      immediate: true,
      deep: true,
      handler() {
        //console.log("drawpPoint");
        if (this.data) {
          
          this.drawGraph();
        }
      },
    },
  },
};
</script>

<style>
</style>