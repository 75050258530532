/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "quantlensapi",
            "endpoint": "https://ud4o01lf02.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:b5ee8b4b-93a5-44b3-a3fc-ab2e72636b59",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TtFqZHV7P",
    "aws_user_pools_web_client_id": "1pdrrvhldq51sh8g2uive7gcgr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "LOCALE"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "currencies-main",
            "region": "us-east-1"
        },
        {
            "tableName": "entities-main",
            "region": "us-east-1"
        },
        {
            "tableName": "factors-main",
            "region": "us-east-1"
        },
        {
            "tableName": "sensitivity-main",
            "region": "us-east-1"
        },
        {
            "tableName": "sourcedata-main",
            "region": "us-east-1"
        },
        {
            "tableName": "waterfall-main",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
