//import { Auth } from 'aws-amplify';
export default {
  factorCategories(state)
  {

    return state.factorCategories;

  },
  selectedCategory(state)
  {

    return state.selectedCategory;

  },
  financialFactors(state)
  {

    return state.financialFactors;

  },
  economicFactors(state)
  {

    return state.economicFactors;

  },
  marketFactors(state)
  {

    return state.marketFactors;

  },
  companyFactors(state)
  {

    return state.companyFactors;

  },
    esgEncoding(state){
      return state.esgEncoding
    },
    creditratingEncoding(state){
      return state.creditratingEncoding
    },
    ratingWatch(state){
      return state.ratingWatch
    },
    msciEncoding(state){
      return state.msciEncoding
    },
    irQualityEncoding(state){
      return state.irQualityEncoding;
    },
    featureImportance(state){
      return state.featureImportance;
    }

};
