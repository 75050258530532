import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import BaseCard from './components/UI/BaseCard';
import PeerGroupFilter from './components/UI/PeerGroupFilter'

Amplify.configure(awsconfig);


Vue.config.productionTip = false

Vue.component('base-card', BaseCard);
Vue.component('peer-group-filter',PeerGroupFilter);

new Vue({
  router,
  store,
  vuetify,

  
  render: h => h(App),
}).$mount('#app')

