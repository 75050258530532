import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state()
  {
    return {
      entity: null,
      defaultEntity:"ADX:ADIB",
      peerGroupName :  {text: "GCC",value:"GCC"},
      peerGroup: [],
      entities :[],
      waterfallEntity: null,
      waterfallDataPeer : null,
      waterfallDataAllRaw : null,
      sourcedataPeer : [],
      sourcedataEntity : null,
      averagePredictedPeer: 0,
      waterfallEntityTimeline : [],
      marketCapTimeline : [],
      setWaterfallPeerTimeline: [],
      sensitivityData : null,
      sensitivityDataCache : [] ,
      loadingPred:false,
      loadingArea:true

    };
  },
  mutations,
  actions,
  getters
};