<template>
  <v-menu offset-y auto dark origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <v-row v-bind="attrs" v-on="on" class="menu">
        <v-col align="right" class="ma-0 pa-0">
          <p
            class="ma-0 pa-0 d-none d-md-block"
            style="color: white !important"
          >
            <v-avatar size="40">
              <span
                class="text-white text-subtitle-1"
                style="color: white !important; margin-left:0px !importan"
                >{{ initials }}</span
              >
            </v-avatar>
            <span
              style="
                color: white !important;
                margin-right: 60px;
                margin-left: 10px;
              
              "
              >Hello {{gender}}. {{familyName}}</span
            ><v-icon color="secondary">mdi-chevron-down</v-icon>
          </p>

          <p class="d-md-none">
            <v-avatar size="40">
              <span
                class="text-white text-subtitle-1"
                style="color: white !important; margin-left:0px !important"
                >{{ initials }}</span
              >
            </v-avatar>
            <v-icon color="secondary">mdi-chevron-down</v-icon>
          </p>
        </v-col>
      </v-row>
    </template>
    <v-list>
      <v-list-item @click="logout">
        <v-list-item-title class="text-white text-left"
          >Logout</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      gender: "",
      familyName: "",
      initials: "",
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("auth");
    },
    getUserData() {
      let user = this.$store.getters.user;
      if(!user){
        user = {
          gender :     localStorage.getItem('gender'),
          name: localStorage.getItem("name"),
          familyName: localStorage.getItem("familyName")
   
        }
      }
      //console.log(user)
      const first = user.name.split("")[0];
      const second = user.familyName.split("")[0];

      this.initials = first + second;
      this.gender = user.gender;
      this.familyName = user.familyName;
    },
  },
  mounted() {
    //console.log("created")
    this.getUserData();
  },
};
</script>

<style scoped>
.menu {
  color: white;
  margin-right: 5px;
  max-width: 400px;
  float: right;
}

.v-avatar {
  background: #695074 0% 0% no-repeat padding-box;
}

a:link,
a:visited {
  text-decoration: none;
  color: white;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  background: rgba(198, 197, 201, 0.23);
  color: white;
  border-radius: 10px;
  backdrop-filter: blur(14.4px);
  -webkit-backdrop-filter: blur(14.4px);
}
</style>