<template>
  <div height="100%">
    <v-row align="center" justify="start">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12" :style="isMobile ? 'padding-top:15px !important;padding-bottom:0px!important':''">
        <v-card rounded="lg" elevation="0" width="100%" :loading="loading">
          <div class="title">
            Simulate your predicted valuation by changing the valuation drivers
          </div>
          <category-slidegroup :categories="categories"></category-slidegroup>
          <table-component
            :sourceData="sourceDataFiltered"
            @recalculateWaterfall="sensitivityBasedData"
            @resetAll="resetAll"
            style="margin-left: 15px; padding-right: 30px !important"
          ></table-component>
          <template slot="progress">
            <v-overlay
              absolute
              class="d-flex flex-column text-center"
              color="white"
              opacity="0.9"
            >
              <div>
                <v-progress-circular
                  size="23"
                  :width="5"
                  color="#3057b8"
                  indeterminate
                >
                </v-progress-circular>
                <span style="margin-left: 10px">loading</span>
              </div>
            </v-overlay>
          </template>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-divider
        dark
        style="margin: 0px 15px 0px 15px; border-color: #f2f2f2; opacity: 1; z-index: 1"
        v-if="isMobile"
      ></v-divider>
    </v-row>

    <v-row align="center" justify="start">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12">
        <v-sheet rounded="lg" elevation="0" :height="isMobile?370:600">
          <div class="title">
            {{ getTitle }}
          </div>
          <waterfall-chart
            :placeholderData="placeholderData"
            :waterfallLabel="waterfallLabel"
            :waterfallDataPos="waterfallDataPos"
            :waterfallDataNeg="waterfallDataNeg"
            :entityName="entityName"
            :peerGroupName="peerGroupName.text"
            :selectedCategory="selectedCategory"
            :labelString="labelString"
          ></waterfall-chart>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import TableComponent from "@/components/ValuationSimulator/TableComponent.vue";
import CategorySlidegroup from "@/components/layout/CategorySlidegroup.vue";
import WaterfallChart from "@/components/ValuationDrivers/WaterfallChart.vue";
import { mapGetters } from "vuex";
export default {
  components: { TableComponent, CategorySlidegroup, WaterfallChart },

  data() {
    return {
      waterfallLabel: [],
      waterfallDataPos: [],
      waterfallDataNeg: [],
      waterfallData: [],
      placeholderData: [],
      placeholderSum: 0,
      counter: 0,
      loading: true,
      

      newTargetValue: [],
      labelString: "",
      sourceDataFiltered: [],
      categories: ["Company", "Financial", "Market", "Economic"],
    };
  },
  computed: {
    ...mapGetters({
      peerGroup: "getPeerGroup",
      entity: "getEntity",
      waterfallDataPeer: "getWaterfallDataPeer",
      peerGroupName: "getPeerGroupName",
      sourceData: "getSourceDataEntity",
      financialFactors: "financialFactors",
      economicFactors: "economicFactors",
      marketFactors: "marketFactors",
      companyFactors: "companyFactors",
      selectedCategory: "selectedCategory",
      sensitivityData: "sensitivityData",
      sensitivityDataCache: "sensitivityDataCache",
      displayType: "displayType",
      currentCurrency: "currentCurrency",
      // ...
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    entityName() {
      if (this.entity) {
        return this.entity.entityCode;
      } else {
        return "";
      }
    },
    getTitle() {
      return (
        this.entityName +
        " Bank Valuation Drivers Relative to Average Valuation of " +
        this.peerGroupName.text +
        " Banks " +
        this.displayTypeString
      );
    },
    displayTypeString() {
      if (this.displayType === "marketValue") {
        return `(${this.currentCurrency.text} million)`;
      } else if (this.displayType == "percentage") {
        return "(%)";
      } else {
        return "(P/TBV)";
      }
    },
    filteredFactors() {
      let factors = [];
     //console.log(this.selectedCategory);
      if (this.selectedCategory === "Company") {
        factors = this.companyFactors;
       //console.log(this.companyFactors);
      } else if (this.selectedCategory === "Financial") {
        factors = this.financialFactors;
      } else if (this.selectedCategory === "Market") {
        factors = this.marketFactors;
      } else if (this.selectedCategory === "Economic") {
        factors = this.economicFactors;
      }
     //console.log(factors);
      return factors;
    },
    xCoordinatesRect() {
      let xStart = -1;

      ////console.log(this.selectedCategory)
      if (this.selectedCategory == "Company") {
        xStart = 1;
      } else if (this.selectedCategory == "Financial") {
        xStart = 2;
      } else if (this.selectedCategory == "Market") {
        xStart = 3;
      } else if (this.selectedCategory == "Economic") {
        xStart = 4;
      }
      //console.log(xStart);
      return xStart;
    },
  },
  methods: {
    prepareLabelstring(value) {
      if (this.displayType === "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.displayType === "multiple") {
        return Number(value.toFixed(2));
      } else {
        return Number(value.toFixed(1));
      }
    },
    filterSourceData() {
      this.sourceData;
      this.sourceDataFiltered = [];
     //console.log(this.sensitivityData);
     //console.log("refreshed");
      for (let factor of this.filteredFactors) {
        let sourceFactor = 0;
        if (this.getSensitivityData(factor)) {
          if (this.sourceData[factor.id]) {
            sourceFactor = this.sourceData[factor.id].replace(/,/, ".");
          }
          this.sourceDataFiltered.push({
            id: factor.id,
            description: factor.description,
            value: sourceFactor,
            sensitivity: this.getSensitivityData(factor),
          });
        }
      }
    },
    getSensitivityData(factor) {
      if (this.sensitivityData) {
        let data = this.sensitivityData.find((el) => el.id == factor.id);
        this.loading = false;
        if (data) {
          //console.log(data);
          return data;
        }
      }
      return null;
    },

    adaptWaterfallSensitivity(newTarget) {
      // summiere alle werte auf außer den betroffenen wert
      // berechne change factor for predicted valuations based on history
      // berechne gap in waterfall -> neuer value für waterfall
      // Todo: ausnahme Wert gerade nicht in Waterfall

      //baseline
      console.log("adapt")
     
      let sum = this.waterfallData[0][1];
      let indexSensitivity = 0;
      console.log(this.sensitivityDataCache)

      //sum up all values for waterfall except the changed one and the entitiy valuation
      for (let i = 1; i < this.waterfallData.length - 1; i++) {
        if (this.waterfallData[i].name == newTarget.id) {
          indexSensitivity = i;
        } else {
          sum += this.waterfallData[i].value;
        }
      }
      let targetValue = this.newTargetValue.find((el) => el.id == newTarget.id);
      let targetValueCached = this.sensitivityDataCache.find(
        (el) => el.id == newTarget.id
      );
     //console.log(targetValue.value);
     //console.log(targetValueCached.value);
     //console.log(this.waterfallData[this.waterfallData.length - 1]);
      let newTargetVal = 0;
      console.log(this.waterfallData)
      console.log(targetValue)
      console.log(targetValueCached)
      if (targetValue.value != targetValueCached.value) {
        newTargetVal =
          this.waterfallData[this.waterfallData.length - 1][1] *
          (targetValue.value / targetValueCached.value);

        console.log(newTargetVal)
        let waterfallFactor = newTargetVal - sum;
       //console.log(waterfallFactor);
       //console.log(this.waterfallData[this.waterfallData.length - 1]);
       //console.log(sum);
        if (indexSensitivity != 0) {
          this.waterfallData[indexSensitivity].value = waterfallFactor;
        } else {
          if (waterfallFactor != 0) {
           //console.log(waterfallFactor);
            this.waterfallData.splice(this.xCoordinatesRect, 0, {
              name: newTarget.id,
              value: waterfallFactor,
            });
          }
        }
       //console.log(this.waterfallData);
        this.waterfallData[this.waterfallData.length - 1][1] = newTargetVal;
        this.updateChartData();
        const index = this.sensitivityDataCache.indexOf(targetValueCached);
        console.log(index)
        if (index != -1) {
          this.sensitivityDataCache[index] = newTarget;
        }
      }
 
    },
    updateChartData() {
      console.log("updateChartData")
      const waterfallDataNew = [];
      this.counter = 0;
      this.placeholderSum = 0;
      this.waterfallLabel = [];

     

      (this.waterfallDataNeg = []), (this.waterfallDataPos = []);
      this.placeholderData = [];
      if (!this.isMobile) {
        this.waterfallLabel.push(
          `Average Valuation for ${this.peerGroupName.text} banks`
        );
        waterfallDataNew.push([
          `Average Valuation for ${this.peerGroupName.text} banks`,
          this.waterfallData[0][1],
        ]);
      } else {
        this.waterfallLabel.push(this.peerGroupName.text);
        waterfallDataNew.push([
          this.peerGroupName.text,
          this.waterfallData[0][1],
        ]);
      }
      console.log(this.waterfallData)
      this.waterfallDataPos.push({
        value: this.prepareValueForCur(this.waterfallData[0][1]),
        itemStyle: {
          color: "#B9B2BC",
          borderColor: "white",
        },
        label: {
          show: true,
          position: "top",
          offset: [0, 0],
        },
      });
      this.waterfallDataNeg.push([this.counter, "-"]);
      this.placeholderData.push([this.counter, this.placeholderSum]);
      this.counter++;
      this.placeholderSum += this.waterfallData[0][1];
      let categorySum = 0;
      for (let i = 1; i < this.waterfallData.length - 1; i++) {
        const factor = this.waterfallData[i].value;
        let desc = this.waterfallData[i].name;
        let id = this.waterfallData[i].name;
       //console.log(this.waterfallData[i])
        if (!["Market", "Economic", "Financial", "Company","FIN","ECO","MKT","CMP"].includes(desc)) {
          let factorObject = this.filteredFactors.find(
            (el) => el.id == this.waterfallData[i].name
          );
         //console.log(factorObject)
          if (this.isMobile) {
            desc = factorObject.mobileCode;
          } else {
            desc = factorObject.description;
          }

          id = factorObject.id;
          categorySum += factor;
        }

        if( this.prepareValueForCur(Number(factor))!=0){
       
        
        if (factor < 0) {
          this.placeholderData.push([
            this.counter,
            Number((this.placeholderSum + factor).toFixed(2)),
          ]);
          this.waterfallLabel.push(desc);
          this.waterfallDataNeg.push([
            this.counter,
            this.prepareValueForCur(Number(-factor)),
          ]);
          this.waterfallDataPos.push([this.counter, "-"]);
          waterfallDataNew.push({
            name: id,
            value: Number(factor),
          });
        } else {
          this.placeholderData.push([
            this.counter,
            Number(this.placeholderSum),
          ]);
          this.waterfallLabel.push(desc);
          this.waterfallDataNeg.push([this.counter, "-"]);
          this.waterfallDataPos.push([
            this.counter,
            this.prepareValueForCur(Number(factor)),
          ]);
          waterfallDataNew.push({
            name: id,
            value: Number(factor),
          });
        }
        this.counter++;
        this.placeholderSum += factor;
      }
    }
      let symbol = "";
      if (categorySum > 0) {
        symbol = "+";
      }
      this.labelString =
        this.selectedCategory +
        " " +
        symbol +
        this.prepareLabelstring(categorySum);

     //console.log(this.waterfallData);
      if (!this.isMobile) {
        this.waterfallLabel.push(` ${this.entityName} AI-Predicted Valuation`);
        waterfallDataNew.push([
          ` ${this.entityName} AI-Predicted Valuation`,
          this.waterfallData[this.waterfallData.length - 1][1],
        ]);
      } else {
        this.waterfallLabel.push(this.entityName);
        waterfallDataNew.push([
          this.entityName,
          this.waterfallData[this.waterfallData.length - 1][1],
        ]);
      }
      this.waterfallDataPos.push({
        value: this.prepareValueForCur(
          this.waterfallData[this.waterfallData.length - 1][1]
        ),
        itemStyle: {
          color: "#3F2A48",
          borderColor: "white",
        },
        label: {
          show: true,
          position: "top",
          offset: [0, 0],
        },
      });
      //this.waterfallDataNeg.push([this.counter, '-']);
      this.placeholderData.push([this.counter, 0]);
     //console.log(this.placeholderData);
     //console.log(this.waterfallDataNeg);
     //console.log(this.waterfallDataPos);
      this.waterfallData = waterfallDataNew;
    },

    prepareValueForCur(value) {
      if (this.displayType === "marketValue") {
        return Number(Math.round(value));
      } else if (this.displayType === "multiple") {
        return Number(value.toFixed(2));
      } else {
        return Number(value.toFixed(1));
      }
    },
    sensitivityBasedData(newTargetValue) {
      if (newTargetValue.value) {
        //if not init then process otherwise init
        let newValue = this.newTargetValue.find(
          (el) => el.id == newTargetValue.id
        );
        if (newValue) {
          //console.log("replace");
          let copiedTargetValue = this.newTargetValue.filter(
            (el) => el.id != newTargetValue.id
          );
          //console.log(copiedTargetValue);
         //console.log(newTargetValue);
          copiedTargetValue.push(newTargetValue);
          this.newTargetValue = copiedTargetValue;
         //console.log();
         //console.log(newTargetValue);
          this.adaptWaterfallSensitivity(newTargetValue);
        } else {
          this.sensitivityDataCache.push(newTargetValue);
          this.newTargetValue.push(newTargetValue);
         //console.log("first");
        }
        //this.newTargetValue = newTargetValue;
        //console.log(this.newTargetValue);
      }
      //console.log(this.newTargetValue);

      //this.prepareDataForCategoriesPeer();
    },
    resetAll() {
      //this.newTargetValue = [];
      this.waterfallData = [];
      this.prepareDataForCategoriesPeer();
    },
    prepareDataForCategoriesPeer() {
      //console.log("triggered");
      this.waterfallObjects = [];
      console.log("new")

      const entityData = this.$store.getters.getWaterfallEntity;
     //console.log(this.selectedCategory);
      if (
        this.waterfallDataPeer &&
        entityData &&
        this.waterfallDataPeer.length
      ) {
        let dataPeer = this.$store.getters.getWaterfallDataPeer.slice();
        const companyFactors = this.$store.getters.companyFactors;

        //console.log(dataPeer);
        const economicFactors = this.$store.getters.economicFactors;
        const financialFactors = this.$store.getters.financialFactors;
        const marketFactors = this.$store.getters.marketFactors;
        let baseline = 0;
        ////console.log(dataPeer)

        //console.log(dataPeer);

        this.counter = 0;
        this.placeholderSum = 0;
        this.waterfallLabel = [];
        (this.waterfallDataNeg = []), (this.waterfallDataPos = []);
        this.placeholderData = [];

        if (dataPeer && dataPeer.length) {
          for (let i = 0; i < dataPeer.length; i++) {
            ////console.log(dataPeer[i])
            baseline += Number(dataPeer[i].predicted_target);
          }
          baseline = Number((baseline / dataPeer.length).toFixed(2));
          let predicted = Number(entityData.predicted_target);
          if (!this.isMobile) {
            this.waterfallLabel.push(
              `Average Valuation for ${this.peerGroupName.text} banks`
            );
            this.waterfallData.push([
              `Average Valuation for ${this.peerGroupName.text} banks`,
              baseline,
            ]);
          } else {
            this.waterfallLabel.push(this.peerGroupName.text);
            this.waterfallData.push([this.peerGroupName.text, baseline]);
          }

          this.waterfallDataPos.push({
            value: this.prepareValueForCur(baseline),
            itemStyle: {
              color: "#B9B2BC",
              borderColor: "white",
            },
            label: {
              show: true,
              position: "top",
              offset: [0, 0],
            },
          });
          this.waterfallDataNeg.push([this.counter, "-"]);
          this.placeholderData.push([this.counter, this.placeholderSum]);
          this.counter++;
          this.placeholderSum += baseline;
          ////console.log(companyFactors);

          this.prepareDataperCategoryPeer(
            dataPeer,
            entityData,
            companyFactors,
            "Company"
          );

          this.prepareDataperCategoryPeer(
            dataPeer,
            entityData,
            financialFactors,
            "Financial"
          );

          this.prepareDataperCategoryPeer(
            dataPeer,
            entityData,
            marketFactors,
            "Market"
          );

          this.prepareDataperCategoryPeer(
            dataPeer,
            entityData,
            economicFactors,
            "Economic"
          );

         //console.log(this.waterfallData);
 
          if (!this.isMobile) {
             this.waterfallData.push([
            `${this.entityName} AI-PredictedValuation`,
            predicted,
          ]);
          this.waterfallLabel.push(
            `${this.entityName} AI-PredictedValuation`
          );
          } else {
            this.waterfallLabel.push(this.entityName);
            this.waterfallData.push([this.entityName, predicted]);
          }

          this.waterfallDataPos.push({
            value: this.prepareValueForCur(predicted),
            itemStyle: {
              color: "#3F2A48",
              borderColor: "white",
            },
            label: {
              show: true,
              position: "top",
              offset: [0, 0],
            },
          });
          //this.waterfallDataNeg.push([this.counter, '-']);
          this.placeholderData.push([this.counter, 0]);

          //this.waterfallData.forEach((element, index) => {
          //console.log(element);
          //TODO : sum up all elements except changing one
          // new waterfall value is difference between sum and
          //changed Predicted Value (based on percentage change of old target sensitivity value and new target senisitivity)
          //});
          //console.log(this.waterfallLabel);
          //console.log(this.waterfallDataPos);
          //console.log(this.waterfallDataNeg);
          //console.log(this.placeholderData);
          const obj = { id: entityData.id };

          this.waterfallLabel.forEach((element, index) => {
            //console.log(element);
            if (this.waterfallData[index]) {
              obj[element] = this.waterfallData[index].value;
            } else {
              obj[element] = 0;
            }
          });
          //console.log(obj);
          this.waterfallObjects.push(obj);
          //console.log(this.waterfallData);
          //console.log(this.waterfallObjects);
          this.$store.commit(
            "setSensitivityDataCache",
            this.sensitivityDataCache
          );
        }
      }
    },
    prepareDataperCategoryPeer(data, entityData, factors, factorString) {
      let sum = 0;
      let entityFactorSum = 0;
      //console.log("selected" + this.selectedCategory);

      //iterate through all factors
      for (let i = 0; i < factors.length; i++) {
        //get factor to calculate
        let id = factors[i].id;
        //console.log("test");
        // get label
        let desc;
        if (this.isMobile) {
          desc = factors[i].mobileCode;
        } else {
          desc = factors[i].description;
        }
        let factor = 0;
        let entityFactor = entityData[id];

        //iterate trough all datasets for this factor

        for (let i = 0; i < data.length; i++) {
          let item = data[i];

          let dataItem = item[id];
          if (dataItem) {
            factor += dataItem;
          }
        }
        //calculate individual factor impact

        if (this.$store.getters.selectedCategory === factorString) {
          factor = entityFactor - Number(factor / data.length);

          if (factor && this.prepareValueForCur(factor) != 0) {
            //changed Value of sensitivity slider
            // let newValue = this.newTargetValue.find((el) => el.id === id);
            // if (newValue) {
            //   //console.log(newValue);
            //   //console.log(entityData);
            //   factor = factor + (newValue.value - entityData.predicted_target);
            //   //console.log(factor);
            //   //console.log("addedFactor");
            // }
            if (factor < 0) {
              this.placeholderData.push([
                this.counter,
                Number((this.placeholderSum + factor).toFixed(2)),
              ]);
              this.waterfallLabel.push(desc);
              this.waterfallDataNeg.push([
                this.counter,
                this.prepareValueForCur(Number(-factor)),
              ]);
              this.waterfallDataPos.push([this.counter, "-"]);
              this.waterfallData.push({
                name: id,
                value: Number(factor),
              });
            } else {
              this.placeholderData.push([
                this.counter,
                Number(this.placeholderSum),
              ]);
              this.waterfallLabel.push(desc);
              this.waterfallDataNeg.push([this.counter, "-"]);
              this.waterfallDataPos.push([
                this.counter,
                this.prepareValueForCur(Number(factor)),
              ]);
              this.waterfallData.push({
                name: id,
                value: Number(factor),
              });
            }

            this.counter++;
            this.placeholderSum += factor;
            if(this.prepareValueForCur(factor)!=0){
               sum += factor;
            }
           
            if (entityFactor && this.prepareValueForCur(entityFactor)!=0) {
              entityFactorSum += entityFactor;
            }
          }
          let symbol = "";
          if (sum > 0) {
            symbol = "+";
          }
          this.labelString =
            factorString + " " + symbol + this.prepareLabelstring(sum);
        } else {
          if(this.prepareValueForCur(factor)!=0){
               sum += factor;
            }
          if (entityFactor&&this.prepareValueForCur(entityFactor)!=0) {
            entityFactorSum += entityFactor;
          }

          factor = 0;
        }
      }
      // else add grouped and summed factor
      if (this.$store.getters.selectedCategory != factorString) {
        sum = entityFactorSum - Number(sum / data.length);
        this.placeholderSum += sum;
        if (sum) {
          if (sum < 0) {
            //console.log(sum);
            //console.log(this.placeholderSum);
            this.placeholderData.push([
              this.counter,
              Number(this.placeholderSum.toFixed(2)),
            ]);
            this.waterfallLabel.push(this.getFactorString(factorString));
            this.waterfallDataNeg.push([
              this.counter,
              this.prepareValueForCur(Number(-sum)),
            ]);
            this.waterfallDataPos.push([this.counter, "-"]);
            this.waterfallData.push({
              name: this.getFactorString(factorString),
              value: Number(sum),
            });
          } else {
            this.placeholderData.push([
              this.counter,
              Number(this.placeholderSum - sum),
            ]);
            this.waterfallLabel.push(this.getFactorString(factorString));
            this.waterfallDataNeg.push([this.counter, "-"]);
            this.waterfallDataPos.push([
              this.counter,
              this.prepareValueForCur(Number(sum)),
            ]);
            this.waterfallData.push({
              name: this.getFactorString(factorString),
              value: Number(sum),
            });
          }
          this.counter++;
        }
      }
    },
    getFactorString(factorString) {
      if (!this.isMobile) {
        return factorString;
      }
      switch (factorString) {
        case "Economic":
          return "ECO";
        case "Market":
          return "MKT";
        case "Financial":
          return "FIN";
        case "Company":
          return "CMP";
      }
    },
  },
  created() {
    this.$store.dispatch("getSensitivity");
    //this.selectedCategory="Company"
  },
  watch: {
    "$store.getters.selectedCategory": {
      immediate: true,
      deep: true,
      handler() {
        this.$store.commit(
            "setSensitivityDataCache",
            []
          );
        this.filterSourceData();
        this.newTargetValue = [];
        this.waterfallData = [];
        this.prepareDataForCategoriesPeer();
      },
    },

    "$store.getters.getWaterfallDataPeer": {
      immediate: true,
      deep: true,
      handler() {
        //console.log("test");
        this.waterfallData = [];
        this.newTargetValue = [];
        this.prepareDataForCategoriesPeer();
        this.filterSourceData();
      },
    },
    "$store.getters.getEntity": {
      immediate: true,
      deep: true,
      handler() {
        //this.$store.dispatch("getWaterfallData");
        this.newTargetValue = [];
        this.waterfallData = [];
        this.prepareDataForCategoriesPeer();
        this.filterSourceData();

        //this.$store.dispatch('getWaterfallDataEntity')
      },
    },
    "$store.getters.sensitivityData": {
      immediate: true,
      deep: true,
      handler() {
        //this.$store.dispatch("getWaterfallData");
        this.filterSourceData();
        if (this.sourceDataFiltered.length) {
          this.loading = false;
        }

        //this.$store.dispatch('getWaterfallDataEntity')
      },
    },
    "$store.getters.getWaterfallEntity": {
      immediate: true,
      deep: true,
      handler() {
        //this.$store.dispatch("getWaterfallData");
        this.$store.commit(
            "setSensitivityDataCache",
            []
          );
        this.newTargetValue = [];
        this.waterfallData = [];
        this.prepareDataForCategoriesPeer();
        this.filterSourceData();


        //this.$store.dispatch('getWaterfallDataEntity')
      },
    },
    
  },
};
</script>

<style scoped>
@media (min-width: 960px) {
  .title {
    margin-left: 25px;
    padding-top: 30px !important;
    font-size: 1.2rem !important;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
  }
}
@media (max-width: 960px) {
  .title {
    margin-left: 25px;
    padding-top: 5px !important;
    font-size: 14px !important;
    line-height:1.5rem;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
  }
}
</style>
