

export default {
  setSelectedCategory(state, category)
  {
    state.selectedCategory = category
  },
  setFinancialFactors(state, financialFactors)
  {

    state.financialFactors = financialFactors

  },
  setEconomicFactors(state, economicFactors)
  {

    state.economicFactors = economicFactors

  },
  setMarketFactors(state, marketFactors)
  {

    state.marketFactors = marketFactors

  },
  setCompanyFactors(state, companyFactors)
  {

    state.companyFactors = companyFactors;

  },
  setFactors(state, factors)
  {
    state.factors = factors
  }
};