<template>
  <v-card
    elevation="0"
    rounded="lg"
    class="pa-0"
    style="width: 100%; height: 100%"
    ><div style="height:80px;margin-bottom:5px;">
      <div class="title">{{ chartName }}</div>
      <div class="subTitle">{{ subTitle }}</div>
      <v-select
        v-model="selectedFactors"
        justify="end"
        class="ma-1 pa-1 dropdown"
        align="end"
        multiple
        :items="sortedFactors"
        item-text="description"
        item-value="id"
        background-color="secondary"
        dense
        color="white"
        item-color="white"
        placeholder="Select Factors"
        :menu-props="{ maxHeight: 600 }"
        rounded
        dark
        mandatory
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action
              style="margin-right: 10px !important; margin-top: px !important"
            >
              <v-icon color="white">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="white--text"
                style="margin-top: -4px !important"
              >
                Select all
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            style="min-height: 31px !important; margin-top: 0px !important"
          >
            <v-list-item-action
              style="margin-right: 10px !important; margin-top: 6px !important"
            >
              <v-icon color="white">{{ itemIcon(item) }}</v-icon>
            </v-list-item-action>
            <v-list-item-content
              style="
                color: white !important;
                min-height: 31px !important;
                padding-top: 2px !important;
              "
            >
              <v-list-item-title
                color="white"
                v-html="item.description"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
          <span v-if="allSelected" style="color: white; font-size: 13px">
            <span
              v-if="index === selectedFactors.length - 1"
              style="color: white"
            >
              ALL
            </span>
          </span>
          <div v-else>
            <span v-if="index === 0" style="color: white; font-size: 13px">{{
              item.code
            }}</span>

            <span v-if="index === 1" style="color: white; font-size: 13px">
              &nbsp; ( +{{ selectedFactors.length - 1 }} )
            </span>
          </div>
        </template>
      </v-select>
    </div>
    <div :id="name" 
    :style="isMobile?'width: 100%; height: 200px':'width: 100%; height: 350px'"
    ></div>
  </v-card>
</template>

<script>
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import {
  GridComponent,
  TitleComponent,
  MarkPointComponent,
  DataZoomComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
export default {
  props: [
    "subTitle",
    "chartName",
    "factors",
    "factorDataPeer",
    "factorDataEntity",
    "zoom",
  ],
  data() {
    return {
      name: this.chartName,
      chart: null,
      quartals: [],
      valuesNegative: [],
      valuesPositive: [],
      markPointNegative: [],
      markPointPositive: [],
      selectedFactors: [],
      quarterToMonthMap: {
        "1Q": 0,
        "2Q": 3,
        "3Q": 6,
        "4Q": 9,
      },
    };
  },
  computed: {
    isMobile(){
      return ((this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ))
    },
    titleFontSize(){
      if(this.isMobile){
        return 14;
      }
      return 18;
    },
    bottomGrid(){
      if(this.isMobile){
        return "10%";
      }
      return "15%";
    },
    subTitleFontSize(){
      if(this.isMobile){
        return 13;
      }
      return 15;
    },
    marginLeft(){
      if(this.isMobile){
        return 65;
      }
      return 65;
    },
    marginRight(){
      if(this.isMobile){
        return 25;
      }
      return 45;
    },
    entityData() {
      let dataTimeline = [];
      ////console.log(this.selectedFactors);
      //console.log(this.selectedFactors);
      //console.log(this.factors);
      for (let factor of this.selectedFactors) {
        for (let i = 0; i < this.factorDataEntity.length; i++) {
          if (this.factorDataEntity[i][factor]) {
            dataTimeline.push({
              quartal: this.factorDataEntity[i].quartal,
              value: this.factorDataEntity[i][factor],
            });
          }
        }
      }

      let result = [];
      console.log(dataTimeline)
      // dataTimeline.reduce(function (res, value) {
      //   if (!res[value.quartal]) {
      //     res[value.quartal] = { quartal: value.quartal, value: value.value };
      //     result.push(res[value.quartal]);
      //   }

      //   res[value.quartal].value += value.value;
      //   return res;
      // }, {});
      let quartals = [...new Set(dataTimeline.map((item) => item.quartal))];
      for (let quartal of quartals) {
        const currentQuartal = dataTimeline.filter((el) => el.quartal === quartal);
       
        let sum = 0;
        
        for (let item of currentQuartal) {
          sum += item.value;
          
        }
        console.log(sum);
        result.push({ quartal: quartal, value: sum});
      }
      console.log(this.chartName)
      console.log(result);

      return result;
    },

    peerGroupData() {
      let resultDistinct = [];
      let result = [];
      console.log(this.factorDataPeer);
      for (let peer of this.factorDataPeer) {
        let sum = 0; 
        let quartal = peer.quartal;
        for (let factor of this.selectedFactors) {
          
          if (peer[factor]!=null) {
            sum += peer[factor];
          }else{
            console.log(peer[factor])
          }
        }

        result.push({ quartal: quartal, value: sum });
      }
      let quartals = [...new Set(result.map((item) => item.quartal))];
      for (let quartal of quartals) {
        const currentQuartal = result.filter((el) => el.quartal === quartal);
       
        let sum = 0;
        
        for (let item of currentQuartal) {
          sum += item.value;
          
        }
        console.log(quartal)
        console.log(this.chartName)
        console.log(sum/currentQuartal.length)
        resultDistinct.push({ quartal: quartal, value: sum / currentQuartal.length });
      }

      return resultDistinct;
    },
    preparePremiumData() {
      const peergroup = this.peerGroupData;
      const result = [];
      for (let ent of this.entityData) {
        let dif = 0;
        let quartal = ent.quartal;
        let peerValue = peergroup.find((el) => el.quartal === quartal);

        dif = ent.value - peerValue.value;

        result.push({ quartal: this.formatQuartal(quartal), value: dif });
      }
      console.log(result)
      return result;
    },
    allSelected() {
      return this.selectedFactors.length === this.factors.length;
    },
    someSelected() {
      return this.selectedFactors.length > 0 && !this.allSelected;
    },
    icon() {
      ////console.log(this.selectedFactors.length);
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    sortedFactors() {
      const factors = this.factors;
      return factors.sort(this.compare);
    },
    min() {
     
      let min = 0;
      //console.log(arr[1]);
      for (let j = 0; j < this.valuesNegative.length; j++) {
        if (this.valuesNegative[j] != "-" && this.valuesNegative[j] < min) {
          min = this.valuesNegative[j];
        }
      }
     
      return min;
    },
      max() {
     
      let max = 0;
      //console.log(arr[1]);
      for (let j = 0; j < this.valuesPositive.length; j++) {
        if (this.valuesPositive[j] != "-" && this.valuesPositive[j] > max) {
          max = this.valuesPositive[j];
        }
      }
     
      return max;
    },
  },

  methods: {
    formatQuartal(quartalString) {
      let quartal = quartalString.split("_");

      let formatted =
        quartal[1].split("").reverse().join("") + " " + quartal[0];

      return formatted;
    },
    prepareValueForCur(value) {
      this.displayType = this.$store.getters.displayType;
      if (this.displayType === "marketValue") {
        return Number(Math.round(value));
      } else if (this.displayType === "multiple") {
        return Number(value.toFixed(2));
      } else {
        return Number(value.toFixed(2));
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedFactors = [];
        } else {
          this.selectedFactors = this.factors.slice().map((a) => a.id);
        }
      });
    },
    sortByQuarterYear(lhs, rhs) {
      var lhsQuarterYear = lhs.quartal.split(" ");
      var rhsQuarterYear = rhs.quartal.split(" ");
      //console.log(lhsQuarterYear)
      var lhsDate = new Date(
        lhsQuarterYear[1],
        this.quarterToMonthMap[lhsQuarterYear[0]]
      );
      var rhsDate = new Date(
        rhsQuarterYear[1],
        this.quarterToMonthMap[rhsQuarterYear[0]]
      );
      return lhsDate.getTime() - rhsDate.getTime();
    },

    itemIcon(item) {
      ////console.log();
      if (this.selectedFactors.includes(item.id)) {
        return "mdi-checkbox-outline";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },
    prepareDataForChart() {
      let data = this.preparePremiumData;
      let counter = 0;
      this.valuesNegative = [];
      this.valuesPositive = [];
      this.markPointNegative = [];
      this.markPointPositive = [];
      this.quartals = data.map((item) => item.quartal);
      //console.log(data)
      data = data.sort(this.sortByQuarterYear);
      for (let value of data) {
        if (value.value > 0) {
          this.valuesPositive.push(this.prepareValueForCur(value.value));
          this.markPointPositive.push({
            //value: value.value.toFixed(2),
            xAxis: counter,
            yAxis: this.prepareValueForCur(value.value),
          });
          this.valuesNegative.push("-");
        } else {
          this.valuesNegative.push(this.prepareValueForCur(value.value));
          this.markPointNegative.push({
            //value: value.value.toFixed(2),
            xAxis: counter,
            yAxis: this.prepareValueForCur(value.value),
          });
          this.valuesPositive.push("-");
        }
        counter++;
      }
    },
    dispatchZoom() {
      if (this.chart) {
        this.chart.dispatchAction({
          type: "dataZoom",
          dataZoomIndex: 0,
          start: this.zoom.start,
          end: this.zoom.end,
        });
      }
    },
    formatValues(value) {
      if (this.$store.getters.displayType == "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.$store.getters.displayType == "multiple") {
        //console.log(value)
        return value.toFixed(2);
      } else {
        return value.toFixed(1);
      }
    },
    formatLabels(value) {
      if (this.$store.getters.displayType == "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.$store.getters.displayType == "multiple") {
        //console.log(value)
        if(this.max<0.1 && this.min>-0.1){
           return value.toFixed(3);
        }
        return value.toFixed(2);
      } else {
        if(this.max<0.5 && this.min>-0.5){
           return value.toFixed(2);
        }
        return value.toFixed(1);
      
      }
    },
     

    drawGraph() {
      if (this.chart == null) {
        return;
      }
      this.chart.setOption({
        textStyle: {
          fontFamily: "guardiansans-regular",
          color: "#3F2A48",
        },
        xAxis: {
          type: "category",
          data: this.quartals,
          splitLine: { show: false },
          axisLine: { onZero: true },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
        },
        grid: {
          left: "65",
          bottom:this.bottomGrid,
          right: this.marginRight,
          top: "5%",
          backgroundColor: "#FAFAFC",
          borderWidth: 0,

          show: true,
        },
        yAxis: {
          axisLine: { show: true },
          type: "value",
          show: true,
          splitLine: { show: false },
          boundaryGap: ["0%", "20%"],
          axisLabel: {
            formatter: function (number) {
              return this.formatLabels(number);
            }.bind(this),
          },
        },
        dataZoom: [
          {
            show: false,
            start: 0,
            filterMode: "None",
          },
        ],

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
          valueFormatter: function (number) {
            if (number != "-") {
              return this.formatValues(number);
            }
            return number;
          }.bind(this),
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          extraCssText: "width: 200px;text-align:left",
        },

        series: [
          {
            name: "Premium",
            data: this.valuesPositive,
            type: "bar",
            itemStyle: {
              color: "#5AB7B7",
            },

            markPoint: {
              data: this.markPointPositive,
              label: {
                show: true,
                position: "top",
                distance: 3,
              },
              symbol: "roundRect",
              symbolSize: 7,
            },
            barWidth: 3,
          },
          {
            name: "Discount",
            data: this.valuesNegative,
            type: "bar",
            itemStyle: {
              color: "#C61151",
            },

            markPoint: {
              data: this.markPointNegative,
              label: {
                show: true,
                position: "bottom",
                distance: 3,
              },
              symbol: "roundRect",
              symbolSize: 7,
            },
            barWidth: 3,
          },
        ],
      });
      this.dispatchZoom();
    },
    resizeChart() {
      if (this.chart != null) {
        ////console.log("now");
        this.chart.resize();
      }
    },
    compare(a, b) {
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  mounted() {
    echarts.use([
      GridComponent,
      BarChart,
      CanvasRenderer,
      UniversalTransition,
      TitleComponent,
      MarkPointComponent,
      DataZoomComponent,
    ]);
    this.selectedFactors = this.factors.map((a) => a.id);
    this.chart = echarts.init(document.getElementById(this.name), null);
    this.drawGraph();
    this.chart.showLoading({
      fontFamily: "guardiansans-regular",
      fontSize: 15,
      textColor: "#3F2A48",
    });
  },
  created() {
    window.addEventListener("resize", this.resizeChart,{passive: true});
  },
  watch: {
    zoom: {
      immediate: true,
      deep: true,
      handler() {
        //console.log(this.zoom);
        //console.log(this.chart);
        this.dispatchZoom();
      },
    },
    factors: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedFactors = this.factors.map((a) => a.id);
      },
    },
    selectedFactors: {
      immediate: true,
      deep: true,
      handler() {
        this.prepareDataForChart();
        this.drawGraph();
      },
    },
    factorDataEntity: {
      immediate: true,
      deep: true,
      handler() {
        this.prepareDataForChart();
        this.drawGraph();
      },
    },
    factorDataPeer: {
      immediate: true,
      deep: true,
      handler() {
        this.prepareDataForChart();
        this.drawGraph();
      },
    },
    "$store.getters.isLoadingArea": {
      immediate: true,
      deep: true,
      handler(loading) {
        //console.log("loading" + loading);
        if (this.chart) {
          //console.log("loadingChart" + loading);
          if (loading) {
            this.chart.showLoading({
              fontFamily: "guardiansans-regular",
              fontSize: 15,
              textColor: "#3F2A48",
            });
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.v-sheet.v-list:not(.v-sheet--outlined) {
  background: #5ab7b7;
  color: white;

  max-height: 800px !important;
}
.v-sheet {
  box-shadow: none;
}
::v-deep .v-list-item {
  min-height: 26px !important;
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0px 6px 0px 14px !important;
}

@media(min-width:960px) {

.dropdown{
  width: 180px !important;
          margin-right: 40px !important;
          margin-top:-55px !important;
          z-index: 5;
          max-height: 600px !important;
          float:right;
}
.title {
    margin-left: 16px;
    padding-top: 20px !important;
    font-size: 18px !important;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
    display: block;
    float:left;
    width:100%
  }
  .subTitle {
    margin-left: 16px;
    width:80%;
    font-size: 15px !important;
    font-family: guardiansans-regular !important;
    color: #3f2a48;
    display: block;
    float:left;
  }
  
}
@media(max-width:960px) {

.dropdown{
  max-width: 180px !important;
          margin-left: 12px !important;
          margin-bottom: -125px !important;
          z-index: 5;
          max-height: 600px !important;
}
.title {
    margin-left: 18px;
    
    font-size: 14px !important;
    font-family: guardianegyp-medium !important;
    color: #3f2a48;
    display: block;
  }
  .subTitle {
    margin-left: 18px;
    font-size: 13px !important;
    font-family: guardiansans-regular !important;
    color: #3f2a48;
  }
span {
  font-size:11px !important;
}
  
}
          
        
</style>