

//import { Auth } from 'aws-amplify';
export default {
  getEntity(state)
  {
    return state.entity;
  },
  defaultEntity(state){
    return state.defaultEntity;
  },

  getEntities(state)
  {
    return state.entities;
  },
  getPeerGroup(state)
  {
    return state.peerGroup;
  },
  getPeerGroupName(state)
  {
    return state.peerGroupName;
  }, getWaterfallEntity(state)
  {
    return state.waterfallEntity;
  },
  getWaterfallDataPeer(state)
  {
    return state.waterfallDataPeer;
  },
  getSourceDataPeer(state)
  {
    return state.sourcedataPeer
  },
  getSourceDataEntity(state)
  {
    return state.sourcedataEntity
  },
  waterfallEntityTimeline(state)
  {
    return state.waterfallEntityTimeline
  },
  waterfallPeerTimeline(state)
  {
    return state.setWaterfallPeerTimeline
  },

  marketCapTimeline(state)
  {
    return state.marketCapTimeline;
  },
  averagePredictedPeer(state)
  {
    return state.averagePredictedPeer;
  },
  isLoadingPred(state){
    return state.loadingPred;
  },
  isLoadingArea(state){
    return state.loadingArea;
  },
  sensitivityData(state){
    return state.sensitivityData;
  },
  sensitivityDataCache(state){
    return state.sensitivityDataCache;
  },
  waterfallDataAllRaw(state) {
    return state.waterfallDataAllRaw;
  }


};