import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#695074',
                secondary: '#5AB7B7'


            },
        },
    },
    breakpoint: {
        thresholds: {
          xs: 430,
          sm: 960,
          md: 1200,
          lg: 1440,
        },
    }
});
