<template>
  <v-app :style="determineBackground">
    <the-header v-if="$route.name != 'auth'"></the-header>

    <v-main>
      <peer-group-filter
        v-if="
          $route.name != 'auth' &&
          ($vuetify.breakpoint.lg ||
            $vuetify.breakpoint.md ||
            $vuetify.breakpoint.xl)
        "
      ></peer-group-filter>
      <router-view></router-view
    ></v-main>
  </v-app>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import PeerGroupFilter from "./components/UI/PeerGroupFilter.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    PeerGroupFilter,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    determineBackground() {
      if (this.$route.name != "auth") {
        //document.body.style.zoom = (window.innerWidth / window.outerWidth)*0.75
        //document.body.style.transform = 'scale(0.75)';
        if (this.isMobile) {
          document.documentElement.style.fontSize = "13px";
          return "background-color:white";
        } else {
          document.documentElement.style.fontSize = "15px";
          return "background-color:#f7f7f9";
        }
      } else {
        //document.body.style.zoom = (window.innerWidth / window.outerWidth)
        //console.log();
        document.documentElement.style.fontSize = "15px";
        const url = require("./assets/background-big.svg");
        return (
          "background: transparent url(" +
          url +
          ");no-repeat center center fixed; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover; background-size: cover; "
        );
      }
    },
  },
};
</script>

<style>
#app {
  text-align: left;
  letter-spacing: 0px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


  
  html {
    overflow-x: hidden !important;
    overflow-y:scroll !important;
  }


.v-sheet {
  box-shadow: none !important;
}

.v-application {
  font-family: "guardiansans-regular" !important;
  font-size: 1rem !important;
  letter-spacing: 0px !important;

  padding: 0;
  margin: 0;
}
p {
  color: #3f2a48;
}
span {
  color: #3f2a48;
}
@font-face {
  font-family: guardianegyp-medium;
  letter-spacing: 0px !important;

  src: url("./assets/fonts/guardian_egyptian_headline/GuardianEgyp-Medium-Web.woff");
}
@font-face {
  font-family: "GuardianSans-light";
  src: url("./assets/fonts/guardian_sans_headline/GuardianSans-Light.otf");
}

@font-face {
  font-family: guardiansans-regular;
  letter-spacing: 0px;
  src: url("./assets/fonts/guardian_sans_text/GuardianTextSans-Regular-Web.woff");
}
@font-face {
  font-family: guardiansans-thin;
  letter-spacing: 0px;
  src: url("./assets/fonts/guardian_sans_headline/GuardianSans-Thin-Web.woff2");
}
@font-face {
  font-family: guardiansans-medium;
  letter-spacing: 0px;
  src: url("./assets/fonts/guardian_sans_text/GuardianTextSans-Medium-Web.woff2");
}
@font-face {
  font-family: guardianegyp-regular;
  letter-spacing: 0px;
  src: url("./assets/fonts/guardian_egyptian_headline/GuardianEgyp-Regular-Web.woff");
}
</style>

