import { API } from "aws-amplify";
import AWS from 'aws-sdk';



export default {
  updateCurrentCurrency(context,payload){
    localStorage.removeItem('currencyText')
    localStorage.removeItem('currencyValue')
    
    localStorage.setItem('currencyText',payload.text)
    localStorage.setItem('currencyValue',payload.value)
    context.commit('setCurrentCurrency',payload)
  },
  async getCurrencies(context){
    //console.log(context)
    const currencies = []

    const data = await API.get('quantlensapi', '/currency')
    const items = data.Items
   //console.log(items)
    for (let i = 0; i < items.length; i++) {
      currencies.push(AWS.DynamoDB.Converter.unmarshall(items[i]));
    }
   //console.log(currencies)
    let validCurrencies = [];
    const periodString = context.getters.currentQuartal.value+context.getters.currentYear.value
    //console.log(context.getters.currentQuartal.value)
  //console.log(periodString)
    for(let i = 0; i < currencies.length; i++){
      if(currencies[i].quartal === periodString){
        validCurrencies.push({text: currencies[i].id ,value: Number(currencies[i].xrate)})
      } 
    }
    
    const years = [...new Set(currencies.map(item => item.quartal.substring(2)))].sort()
    let yearsObject = []
    let timeObject = [...new Set(currencies.map(item => item.quartal))]
    for(let i = 0; i < years.length; i++){
      yearsObject.push({text:years[i], value:Number(years[i])})
     
    }
    //console.log(yearsObject)
   //console.log(validCurrencies)
    context.commit('setCurrencies', validCurrencies);
    context.commit('setYears',yearsObject);
    context.commit('setTime',timeObject)
    
  }

  // calculateValueByCurrency(context, payload)
  // {
  //   const value = payload.value;
  //   const currentCurrency = context.getters.currentCurrency;
  //   const currencies = context.getters.currencies;
  //   //console.log(currentCurrency)
  //   //console.log(value)
  //   //console.log(currencies)

  //   const xchangeRate = currencies.find(
  //     (currency) => currency.short === currentCurrency
  //   );
  //   //console.log("results")
  //   //console.log(xchangeRate.value)
  //   const xchangeValue = Number(value) * Number(xchangeRate.value);
  //   //console.log(xchangeValue)
  //   return value * xchangeRate.value
  // }

  //context.commit('setUser', {
  // user: payload.user
  //});

//   (1) (WATERFALL DATA: FACTOR predicted_target /  WATERFALL DATA: FACTOR actual_target) x SOURCE_DATA: T1|Target_1|MKTCAP
// (2) WATERFALL DATA: FACTOR predicted_target
// (3) WATERFALL DATA: FACTOR predicted_target / WATERFALL DATA: FACTOR baseline [for all GCC banks]
// OR
// (3) WATERFALL DATA: FACTOR predicted_target / AVERAGE(WATERFALL DATA: FACTOR predicted_target for peer group banks) [for other peer group selection]
// WHERE: the peer group is set as 100% and the chosen/default bank valuation is then a percentage relative to that - e.g. 120% for chosen bank implies 20% premium over peer group,


};
