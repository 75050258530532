import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state()
  {
    return {
      currentCurrency: { text: 'USD', value: 1.0 },
      currentYear :  { text: '2022', value: 2022 },
      currentQuartal : { text: '4Q', value: "4Q" },
      entityPeriods : [],
      currentYearFallback : null,
      currentQuartalFallback : null,
      displayType: '',
      currencies: [ ],
      years : [],
      time: [],
      changes:'',
      quartals : [
        { text: '1Q', value: "1Q" },
        { text: '2Q', value: "2Q" },
        { text: '3Q', value: "3Q" },
        { text: '4Q', value: "4Q" },

      ],
    };
  },
  mutations,
  actions,
  getters
};