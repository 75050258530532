<template>
  <v-radio-group
    v-model="displayType"
    :row="
      $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.xl
    "
    dark
    dense
    mandatory
    class="ma-0 pa-0"
    style="margin-left: 0px !important"
    @change="updateValue"
  >
    <template v-slot:label>
      <div>Display as</div>
    </template>

    <v-radio
      color="secondary"
      label="Valuation Multiple"
      value="multiple"
      class="ma-0 pa-0"
      style="margin-right: 5px !important"
    ></v-radio>
    <v-radio
      label="Market value"
      color="secondary"
      value="marketValue"
      class="ma-0 pa-0"
      style="margin-right: 5px !important"
    ></v-radio>
    <v-radio
      class="ma-0 pa-0"
      color="secondary"
      label="Percentage"
      value="percentage"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  data() {
    return {
      displayType: "",
    };
  },
  methods: {
    updateValue() {
      localStorage.removeItem("displayType");
      localStorage.setItem("displayType", this.displayType);
      this.$store.commit("setDisplayType", this.displayType);
    },
  },
  mounted() {
    this.displayType = localStorage.getItem("displayType");
    this.$store.commit("setDisplayType", this.displayType);
  },
};
</script>

<style scoped>
::v-deep .v-label {
  font-size: 1rem !important;
  color: white;
}
::v-deep .v-icon.v-icon--dense {
  font-size: 1rem !important;
}
::v-deep v-input--selection-controls__ripple {
  margin-right: 0px;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0px !important;
}
@media (max-width: 960px) {
  ::v-deep .v-messages {
    display: none;
  }
}
</style>