import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);


import authModule from './modules/auth/index.js';
import currencyModule from './modules/options/index.js';
import valuationSimModule from './modules/valuationSimulator/index.js'
import dataModule from './modules/data/index.js'
import factorModule from "./modules/factors/index.js";





const store = new Vuex.Store({

  modules: {
    curr : currencyModule,
    auth: authModule,
    valSim : valuationSimModule,
    data: dataModule,
    factors:factorModule

  }
});

export default store;