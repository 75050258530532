<template>
<div class="selection-container-outer ">
 <div v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm )" style="margin-bottom:15px;">Period Selection</div>
  <div
    class="selection-container-inner "
  >
   
    <v-select
      v-model="currentQuartal"
      class="ma-0 pa-0"
      :items="computedQuartals"
      background-color="#695074"
      dense
      style="width: 230px !important; font-size: 1rem !important;margin-top: -1.5px !important;"
      rounded
      dark
      mandatory
      @change="updateQuartal"
      id="currencySelector"
    >
      <template v-slot:prepend v-if="($vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.xl)">
        <div class="d-none d-md-block" style="min-width: 120px !important;margin-top:2.5px !important">Period Selection</div>
      </template>
      <template v-slot:append>
        <v-icon>mdi-chevron-down</v-icon>
      </template>
    </v-select>

    <v-select
      v-model="currentYear"
      class="ma-0 pa-0"
      :items="years"
      background-color="#695074"
      dense
      style="max-width: 120px !important; font-size: 1rem !important;margin-top: -1.5px !important;"
      rounded
      dark
      mandatory
      @change="updateYears"
      @click="filterQuartal"
    >
      <template v-slot:append>
        <v-icon>mdi-chevron-down</v-icon>
      </template>
    </v-select>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quartals: [],
      currentQuartal: "",
      currentYear: "",
    };
  },
  computed: {
    computedQuartals () {
        const availableQuartals= [];
      const availablePeriods = this.$store.getters.entityPeriods
    
      for(let quartal of this.quartals){
        //console.log(quartal)
        let check = availablePeriods.find(el => quartal.text === el.quartal && this.currentYear.text === el.year)
        if(check){
          availableQuartals.push({text:quartal.text,value:quartal.value,disabled:false})
        }else{
            availableQuartals.push({text:quartal.text,value:quartal.value,disabled:true})
        }
      }
      //console.log(availableQuartals)
     return availableQuartals;
    },
    years() {
      const availableYears= [];
      const years = this.$store.getters.years;
      const availablePeriods = this.$store.getters.entityPeriods
      //console.log(availablePeriods)
      //console.log(years)
      //console.log(this.currentQuartal)
      for(let year of years){
        //console.log(year)
        let check = availablePeriods.find(el => year.text === el.year && this.currentQuartal.value === el.quartal)
        if(check){
          availableYears.push({text:year.text,value:year.value,disabled:false})
        }else{
            availableYears.push({text:year.text,value:year.value,disabled:true})
        }
      }
      //console.log(availableYears)
     return availableYears;
    },
  },
  methods: {
    filterQuartal(){
      //console.log(this.$store.getters.entityPeriods)
      //console.log("activated")
    },
    updateQuartal() {
      const updateQuartal = this.quartals.find(
        (value) => this.currentQuartal === value.value
      );
      this.currentQuartal = updateQuartal;
      //console.log(updateQuartal);
      this.$store.commit("setQuartalFallback", null);
      this.$store.commit("setYearFallback", null);
      this.$store.commit("setQuartal", updateQuartal);
    },
    updateYears() {
      const updateYears = this.years.find(
        (value) => this.currentYear === value.value
      );
      this.currentYear = updateYears;
      //console.log(updateYears);
      this.$store.commit("setQuartalFallback", null);
      this.$store.commit("setYearFallback", null);
      this.$store.commit("setYear", updateYears);
    },
  },
  beforeMount() {
    this.quartals = this.$store.getters.quartals;
    this.currentQuartal = this.$store.getters.currentQuartal;
    this.currentYear = this.$store.getters.currentYear;
    //console.log(this.quartals);
  },
  watch: {
    "$store.getters.currentQuartalFallback": {
      immediate: true,
      deep: true,
      handler() {
        let quartal = this.$store.getters.currentQuartalFallback;
        let year = this.$store.getters.currentYearFallback;
        if (year && quartal) {
          this.currentQuartal = quartal;
          this.currentYear = year;
        } else {
          this.currentQuartal = this.$store.getters.currentQuartal;
          this.currentYear = this.$store.getters.currentYear;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-icon {
  background-color: #5ab7b7;

  margin-right: -22px;
  margin-top: 1px;
  border-radius: 25px;
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 12px;
  padding-right: 24px;
  padding-left: 10px;
}

::v-deep .v-input__slot {
  width: 100px !important;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  margin: 3px 4px 3px 0 !important;
}
.selection-container-inner {
  min-width: 300px;
      display: inherit;
      margin-left: 0px !important;
      font-size: 1rem !important;     
}
.selection-container-outer {
  min-width: 300px;
      display: inherit;
      margin-left: 0px !important;
      font-size: 1rem !important;     
}
@media(max-width:960px) {
  .selection-container-outer {
     display: block;
  }
  .selection-container-inner {
     display: inline-block;
  }
    
}
</style>