<template>
  <v-card
    rounded="lg"
    elevation="0"
    height="100%"
    width="100%"
    :loading="loading"
  >

    <v-container fluid height="100%" width="100%" class="pa-1 ma-0" :style="!isMobile&&triangle? 'margin-left:-15px !important;':''">
      <v-card-title
        class="ma-0 pa-0 title-card"
        
        v-if="!!title"
      >
        <span class="title"
          >{{ getTitle }}
          <span v-if="triangle" style="margin-left:0px !important;">
            <v-icon small :class="getColor"> mdi-triangle </v-icon>
            {{ result }}%</span
          >
          <v-tooltip bottom max-width="300" color="white" v-if="!isMobile">
            <template v-slot:activator="{ on, attrs }">
              <v-icon black style="margin-left: 10px" v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span class="text--white" style="max-width: 300px"
              ><slot></slot
            ></span> </v-tooltip
        ></span>

        <v-spacer></v-spacer>
        <!-- <v-card-actions>
            <v-btn text color="teal accent-4" @click="reveal = true">
             
            </v-btn>
          </v-card-actions>-->
      </v-card-title>
      <v-card-subtitle class="pa-0" v-if="!!title">
        <p
          style="font-size: 1rem; color: #3f2a48; letter-spacing: 0px"
          align="left"
        >
          <span v-if="!!result">vs</span><span v-else>for</span> {{ forType }}
          <span v-if="peer">banks</span>
        </p></v-card-subtitle
      >

      <div v-if="!mobileNumber">
        <p
          class="currency_container"
          align="left"
          v-if="displayType === 'marketValue'"
        >
          <span>{{ currency }}</span>
          <span class="number-font ma-1">{{
            Math.round(exchangeValue)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}</span>
          <span> million</span>
        </p>
        <p
          class="currency_container"
          align="left"
          v-if="displayType === 'multiple'"
        >
          <span class="number-font ma-1">{{ exchangeValue.toFixed(2) }}</span>
          <span> P/TBV</span>
        </p>
        <p
          class="currency_container"
          align="left"
          v-if="displayType === 'percentage'"
        >
          <span class="number-font ma-1">{{ exchangeValue.toFixed(1) }} %</span>
        </p>
      </div>
    </v-container>
    <template slot="progress">
      <v-overlay
        absolute
        class="d-flex flex-column text-center"
        color="white"
        opacity="0.9"
      >
        <div>
          <v-progress-circular
            size="23"
            :width="5"
            color="#3057b8"
            indeterminate
          >
          </v-progress-circular>
          <span style="margin-left: 10px">loading</span>
        </div>
      </v-overlay>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: false,
    },
    forType: {
      type: String,
      required: false,
    },
    result: {
      type: Number,
      required: false,
    },
    peer: {
      type: Boolean,
      required: false,
      default: true,
    },
    triangle: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { reveal: false, loading: true };
  },
  computed: {
     isMobile(){
      return ((this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ))
    },
    displayType() {
      return this.$store.getters.displayType;
    },
    exchangeValue() {
      return this.value;
    },
    currency() {
      return this.$store.getters.currentCurrency.text;
    },
    getColor() {
      if (this.result > 0) {
        //console.log(this.result)
        return "positive";
      } else {
        return "negative";
      }
    },
    getTitle() {
      if (this.triangle) {
        if (this.result > 0) {
          return "Valuation Premium is";
        } else {
          return "Valuation Discount is";
        }
      }
      return this.title;
    },
  },
  beforeCreate: function () {
    document.body.className = "default";
  },
  watch: {
    "$store.getters.isLoadingPred": {
      immediate: true,
      deep: true,
      handler(loading) {
        //console.log("loading" + loading);
       //console.log(loading);
        //console.log("loadingChart" + loading);

        this.loading = loading;
      },
    },
  },
};
</script>
<style scoped>
.positive {
  margin-bottom: 3px;
  color: #5ab7b7;
}
.negative {
  color: red;
  padding-top: 0px !important;
  margin-bottom: 3px;
  transform: rotate(180deg);
}
@media (min-width: 960px) {
  .number-font {
    font-family: guardianegyp-regular;
    font-size: 2.667rem;
    letter-spacing: 0px;
    color: #3f2a48;
  }
  .currency_container {
    font-size: 1.2rem;
    color: #3f2a48;
    letter-spacing: 0p;
  }
   .title-card{
    min-width:300px;
  }
  .title {
    font-size: 1.2rem !important;
    font-family: guardiansans-medium !important;

    letter-spacing: 0px;
    color: #3f2a48;
    float: left;
    min-width:300px;

    margin-bottom: 1.4vh;
  }
  .subtitle {
    font-size: 1.2rem;
  }
}
@media (max-width: 960px) {
  .number-font {
    font-family: guardianegyp-regular !important;
    font-size: 25px;
    letter-spacing: 0px;
    color: #3f2a48;
  }
  .currency_container {
    font-size: 1rem;
    color: #3f2a48;
    letter-spacing: 0px;
  }
 
  .title {
    font-size: 13px !important;
    font-family: guardiansans-medium !important;

    letter-spacing: 0px;
    color: #3f2a48;
    float: left;

    margin-bottom: 12px;;
  }
  p {
    margin-bottom:4px;
  }
  .subtitle {
    font-size: 13px;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
}
.icon-right-top {
  position: absolute !important;
  right: 10px !important;
  top: 14px !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}
.spacer {
  margin-top: 20px;
}
</style>