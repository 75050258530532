

export default {
   setCurrentCurrency(state, payload) {
     state.changes ='cur'
     state.currentCurrency = payload
     //this.dispatch('getWaterfallDataEntity')
     this.dispatch('getWaterfallData')
     
   },
   setQuartal(state,quartal){
    state.changes ='quar'
     state.currentQuartal = quartal;
     //this.dispatch('getWaterfallDataEntity')
     this.dispatch('getWaterfallData')
   },
   setYear(state,year){
    state.changes ='year'
     state.currentYear = year;
     //this.dispatch('getWaterfallDataEntity')
     this.dispatch('getWaterfallData')
   },
   setQuartalFallback(state,quartal){
   
     state.currentQuartalFallback = quartal;
   
   },
   setYearFallback(state,year){
   
     state.currentYearFallback = year;
    
   },
   setDisplayType(state,displayType){
    state.changes ='display'
     state.displayType = displayType;
     //this.dispatch('getWaterfallDataEntity')
     this.dispatch('getWaterfallData')
   },
   setCurrencies(state,currencies){
     state.currencies = currencies;
   },
   setYears(state,years){
     state.years = years
   },
   setTime(state,time){
     state.time = time;
   },
   setEntityPeriods(state,periods){
     state.entityPeriods = periods;
   }
};