<template>
  <v-card style="width: 100%; height: 100%" elevation="0" rounded="lg">
    <div :id="name" :style="isMobile?'width: 100%; height: 450px' :'width: 100%; height: 500px' "></div>
  </v-card>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { graphic } from "echarts";
import { mapGetters } from "vuex";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { VisualMapComponent } from "echarts/components";
import {
  TitleComponent,
  DataZoomComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";

export default {
  data() {
    return {
      name: "AreaChartValuation",
      chart: this.drawGraph(),
    };
  },
  props: [
    "entityName",
    "peerGroupName",
    "entityData",
    "peerGroupData",
    "premiumData",
  ],
  computed: {
    ...mapGetters({
      displayType: "displayType",
      currentCurrency: "currentCurrency",

      // ...
    }),
     isMobile(){
      return ((this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ))
    },
    titleFontSize(){
      if(this.isMobile){
        return 14;
      }
      return 18;
    },
    marginLeft(){
      if(this.isMobile){
        return 65;
      }
      return 65;
    },
    marginRight(){
      if(this.isMobile){
        return 25;
      }
      return 50;
    },
    displayTypeString() {
      if (this.displayType === "marketValue") {
        return `(${this.currentCurrency.text} million)`;
      } else if (this.displayType == "percentage") {
        return "(%)";
      } else {
        return "(P/TBV)";
      }
    },
    titleValuation() {
      return (
        this.entityName +
        " vs. " +
        this.peerGroupName.text +
        " Average Valuation " +
        this.displayTypeString
      );
    },
    titleDif() {
      //console.log(this.peerGroupName);
      return (
        "Valuation Premium: " +
        this.entityName +
        " -  " +
        this.peerGroupName.text +
        " Average Valuation"
      );
    },
  },
  methods: {
       formatValues(value){
      
      if (this.displayType == "marketValue") {
        
       return Math.round(value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
         
      } else if (this.displayType == "multiple") {
       //console.log(value)
        return Number(value).toFixed(2);
      } else {
        return  Number(value).toFixed(1);
      }
      
    },
        formatLabels(value) {
      if (this.$store.getters.displayType == "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.$store.getters.displayType == "multiple") {
        //console.log(value)
       
        return value.toFixed(2);
      } else {
       
        return value.toFixed(1);
      
      }
    },
     
    drawGraph() {
      //console.log(this.peerGroupData)
       //console.log(this.entityData)
      if (this.chart == null) {
        return;
      }
      this.chart.setOption({
        color: ["#695074", "#5AB7B7"],
        textStyle: {
          fontFamily: "guardiansans-regular",
          color: "#3F2A48",
        },
        legend: {
          data: [this.peerGroupName.text, this.entityName],

          itemStyle: {
            color: "inherit",
          },
          top: "30",
          right: this.marginRight,
        },
        title: [
          {
            left: "10",
            top: "2%",
            text: this.titleValuation,
            textStyle: {
              fontFamily: "guardianegyp-medium",
              fontWeight: "lighter",
              color: "#3F2A48",
              fontSize: this.titleFontSize
            },
          },
          {
            top: "62%",
            left: "10",
            text: this.titleDif,
            textStyle: {
              fontFamily: "guardianegyp-medium",
              fontWeight: "normal",
              color: "#3F2A48",
              fontSize: this.titleFontSize
            },
          },
        ],
        xAxis: [
          {
            type: "category",
            data: this.entityData.map((a) => a.quartal),
            boundaryGap: false,
            splitLine: { show: true },
            axisLine: { onZero: false },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
          },

          {
            data: this.premiumData.map((a) => a.quartal),
            gridIndex: 1,
            splitLine: { show: true },
            boundaryGap: false,
            axisLine: { onZero: true },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          valueFormatter: function (number) {
            return this.formatValues(number);
          }.bind(this),
          order: "seriesAsc",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          extraCssText: "width: 200px;text-align:left",
        },
        axisPointer: {
          link: [{ xAxisIndex: "all" }, { yAxisIndex: "1" }],
          label: {
            backgroundColor: "#777",
             formatter: function (params) {
               if(params.axisDimension=='y'){
                 return this.formatValues(params.value);
               }
               return params.value;
            
          }.bind(this)
          },
        },

        yAxis: [
          {
            axisLine: { show: true },
            splitLine: { show: false },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
             axisLabel: {
            formatter: function (number) {
              return this.formatLabels(number);
            }.bind(this),
          },
          },

          {
            axisLine: { show: true },
            splitLine: { show: false },
             axisLabel: {
            formatter: function (number) {
              return this.formatLabels(number);
            }.bind(this),
          },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            gridIndex: 1,
          },
        ],
        visualMap: {
          seriesIndex: 2,
          show: false,

          pieces: [
            {
              min: 0,
              max: 100000,
              color: "rgba(90, 183, 183, 0.3)",
            },
            {
              min: -1000000,
              max: 0,
              color: "rgba(198, 17, 81, 0.3)",
            },
          ],
        },
        grid: [
          {
            backgroundColor: "#FAFAFC",
            show: true,
            borderWidth: 0,
            left: this.marginLeft,
            right: this.marginRight,
            top: "12%",
            height: "40%",
          },
          {
            backgroundColor: "#FAFAFC",
            show: true,
            borderWidth: 0,
            left: this.marginLeft,
            right: this.marginRight,
            bottom: "16%",
            height: "15%",
          },
        ],
        dataZoom: [
          {
            xAxisIndex: [0, 1],
            start: 0,
            filterMode: "None",
            fillerCollor: "rgba(255, 255, 255, 0)",
            dataBackground: {
              areaStyle: {
                color: "#5AB7B7",
              },
            },
            selectedDataBackground: {
              areaStyle: {
                color: "#5AB7B7",
              },
            },
          },
        ],
        series: [
          {
            name: this.peerGroupName.text,
            data: this.peerGroupData.map((a) => a.value),
            type: "line",
            showSymbol: false,

            smooth: true,

            areaStyle: {
              color: new graphic.LinearGradient(0, 0.5, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(105, 80, 116, 0.2)",
                },
                {
                  offset: 0.6,
                  color: "#FAFAFC",
                },
              ]),
            },

            lineStyle: {
              color: "#695074",
              opacity: 1.0,
            },
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: this.entityName,
            data: this.entityData.map((a) => a.value),
            type: "line",
            showSymbol: false,
            smooth: true,

            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0.0,
                  color: "rgba(90, 183, 183,0.2)",
                },
                {
                  offset: 0.6,
                  color: "#FAFAFC",
                },
              ]),
            },
            lineStyle: {
              color: "#5AB7B7",
              opacity: 1.0,
            },
            xAxisIndex: 0,
            yAxisIndex: 0,
          },

          {
            name: "Premium",
            data: this.premiumData.map((a) => a.value),
            type: "line",
            showSymbol: false,
            smooth: true,
            areaStyle: {},
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
        ],
      });

      this.chart.on("datazoom", (evt) => {
        //console.log(evt);
        this.$emit("customZoom", { start: evt.start, end: evt.end });
      });
    },
    resizeChart() {
      if (this.chart != null) {
        this.chart.resize();
      }
    },
  },
  mounted() {
    echarts.use([
      GridComponent,
      LineChart,
      CanvasRenderer,
      UniversalTransition,
      VisualMapComponent,
      TitleComponent,
      DataZoomComponent,
      TooltipComponent,
      LegendComponent,
    ]);
    this.chart = echarts.init(document.getElementById(this.name), null);
    this.drawGraph();
    this.chart.showLoading({fontFamily: "guardiansans-regular",fontSize: this.titleFontSize,textColor: '#3F2A48',});
  },
  created() {
    window.addEventListener("resize", this.resizeChart,{passive: true});
    this.$emit("customZoom", { start: 0, end: 100 });
  },
  watch: {
    peerGroupData: {
      immediate: true,
      deep: true,
      handler() {
        //console.log("reload");
        this.drawGraph();
      },
    },
    "$store.getters.isLoadingArea": {
      immediate: true,
      deep: true,
      handler(loading) {
        //console.log("loading" + loading);
        if (this.chart) {
          //console.log("loadingChart" + loading);
          if (loading) {
            this.chart.showLoading({fontFamily: "guardiansans-regular",fontSize: 15,textColor: '#3F2A48',});
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
  },
};
</script>

<style>
</style>