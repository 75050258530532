<template>
  <v-container fluid style="margin-top: -10px !important; margin-left: 10px">
    <v-row dense align="start">
      <v-spacer></v-spacer>
      <v-col cols="7" lg="3" xl="3" md="3" sm="7" xs="7">
        <KPICard
          title="AI Predicted Valuation"
          :peer="false"
          :forType="entityName"
          :value="entityValue"
          >The AI predicted valuation is the market value predicted by the
          Iridium Quant lens machine learning algorithms for {{ entityName }},
          expressed as a {{ textDisplayType }} value.</KPICard
        >
      </v-col>
      <v-col cols="1" align="center" v-if="!isMobile">
        <v-sheet
          max-width="40"
          rounded="lg"
          style="font-size: 30px; color: #3f2a48; margin-top: 60px"
        >
          -
        </v-sheet></v-col
      >
      <v-col cols="5" lg="3" xl="3" md="3" sm="5" xs="5">
        <v-divider v-if="isMobile"
          vertical
          style="
            z-index: 30;
            margin-left: -20px;
            height: 70px;
            float: left;
            position: relative;
            margin-top: 12px;
          "
        ></v-divider>

        <KPICard
          title="Average Valuation"
          :value="peerGroupValue"
          :forType="peerGroupName"
          >The average valuation for {{ peerGroupName }} banks is the average
          market value predicted by the Iridium Quant lens machine learning
          algorithms for this peer group, expressed relative to
          {{ entityName }} as a {{ textDisplayType }} value.</KPICard
        ></v-col
      >
      <v-col cols="1" align="center" v-if="!isMobile"
        ><v-sheet
          max-width="40"
          rounded="lg"
          :style="
            !isMobile
              ? 'margin-left:-15px !important;font-size: 30px; color: #3f2a48; margin-top: 60px'
              : 'font-size: 30px; color: #3f2a48; margin-top: 60px'
          "
        >
          =
        </v-sheet></v-col
      >
      <v-col cols="12" class="pa-0 ma-0" v-if="isMobile">
        <v-divider
          dark
          style="margin: 0px; border-color: #f2f2f2; opacity: 1; z-index: 30"
        ></v-divider
      ></v-col>

      <v-col cols="7" lg="4" xl="4" md="4" sm="7" xs="7"
        ><KPICard
          title="Valuation Premium is"
          :value="differenceValue"
          :result="difference"
          :forType="peerGroupName"
          :triangle="true"
          :mobileNumber="isMobile"
          >The valuation premium for {{ entityName }} is the difference between
          its AI predicted valuation and the average predicted valuation for
          {{ peerGroupName }} banks, expressed as a
          {{ textDisplayType }} value.</KPICard
        ></v-col
      >
      <v-col cols="5" v-if="isMobile"
        ><KPICard
          title=""
          :value="differenceValue"
          :result="difference"
          :forType="peerGroupName"
          :triangle="true"
          >The valuation premium for {{ entityName }} is the difference between
          its AI predicted valuation and the average predicted valuation for
          {{ peerGroupName }} banks, expressed as a
          {{ textDisplayType }} value.</KPICard
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import KPICard from "@/components/ValuationDrivers/KPICard.vue";
import { mapGetters } from "vuex";

export default {
  components: { KPICard },
  data() {
    return {
      peerGroupValue: 0,
      entityValue: 0,
      difference: 0,
      differenceValue: 0,
      peerGroupName: null,
      entityName: "",
    };
  },
  computed: {
    ...mapGetters({
      displayType: "displayType",
      currentCurrency: "currentCurrency",
      peerGroup: "getPeerGroup",

      // ...
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    textDisplayType() {
      if (this.displayType === "marketValue") {
        return this.currentCurrency.text;
      } else if (this.displayType === "multiple") {
        return "P/TBV";
      } else {
        return "Percentage";
      }
    },
  },
  methods: {
    calcEntityValue() {
      const data = this.$store.getters.getWaterfallEntity;
      //console.log(data);
      if (data != null) {
        const value = data.predicted_target;
        this.entityValue = value;
      } else {
        this.entityValue = 0;
      }
    },
    calcPeervalue() {
      const data = this.$store.getters.getWaterfallDataPeer;
      let sum = 0;
    //  console.log(data.length)
      if (data) {
        data.forEach((element) => {
          sum += element.predicted_target;
       //   console.log(element.predicted_target);
        });
        //console.log(data.length);
        //console.log(sum)
        this.peerGroupValue = sum / data.length;
      } else {
        this.peerGroupValue = 0;
      }
    },
    premiumCalcValue() {
      this.differenceValue = this.entityValue - this.peerGroupValue;
      //console.log(this.differenceValue)
      //console.log(this.entityValue)
      //console.log(this.peerGroupValue)
    },
    premiumCalcPer() {
      let valuePer = Math.round(
        (this.entityValue / this.peerGroupValue - 1) * 100
      );
      if (Object.is(valuePer, -0)) {
        this.difference = 0;
      }
      this.difference = valuePer;
    },
  },
  watch: {
    "$store.getters.getPeerGroupName": {
      immediate: true,
      deep: true,
      handler(peerGroupName) {
        this.peerGroupName = peerGroupName.text;
      },
    },
    "$store.getters.getEntity": {
      immediate: true,
      deep: true,
      handler(entity) {
        if (entity != null) {
          //console.log(entity);
          this.entityName = entity.entityCode;
        } else {
          this.entityName = "";
        }
      },
    },
    "$store.getters.getWaterfallDataPeer": {
      immediate: true,
      deep: true,
      handler() {
        this.calcEntityValue();
        this.calcPeervalue();
        this.calcPeervalue();
        this.premiumCalcValue();
        this.premiumCalcPer();
        //console.log("finish")
      },
    },
  },
};
</script>
<style scoped>
.card__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 25vh;
  z-index: 0;
  margin: 0rem 1rem 2rem 1rem;
}
.small_card {
  font-weight: bold;
  box-shadow: none;
  overflow: none;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: auto;
  justify-content: space between;
  background-color: white;
  width: auto;
}
</style>