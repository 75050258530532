<template>
  <v-container fluid>
    <v-row align="center"  v-if="$vuetify.breakpoint.lg ||$vuetify.breakpoint.md || $vuetify.breakpoint.xl">
      <v-col cols="12" align="center" class="pa-0 ma-0">
        <v-slide-group
          center-active
         mandatory
          show-arrows
          @change="updateSelectedGroup"
          ref="slideGroup"
          :key="counter"
         
        >
          <v-slide-item
            v-for="n in factors"
            :key="n.id"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
              medium
              class="ma-1 text-none "
              style="border: 1.5px solid #b9b2bc; color:#3F2A48;letter-spacing: 0px; font-size:1rem !important;line-height:1rem;"
              rounded
              height="2.067rem"
              
              elevation="0"
              outlined
              active-class="secondary white--text"
              @click="toggle"
            >
              {{ n.description }}
            </v-btn>
          </v-slide-item>
        </v-slide-group></v-col
      >
    </v-row>
    <v-row align="center" style="padding-top:0px !important" v-else>
      <v-col cols="12" class="pa-0 ma-0" style="margin-left:23px !important;">
      <v-select
        v-model="selectedFactor"
        justify="end"
        class="ma-0 pa-0"
        align="end"
        :items="factors"
        item-text="description"
        item-value="description"
        background-color="secondary"
        dense
        height="31"
        color="white"
        item-color="white"
        
        @change="updateSelectedGroupDropdown"
        placeholder="Select Factors"
        :menu-props="{ maxHeight: 600 }"
        style="
          max-width: 220px !important;
          margin-right: 0px !important;
          margin-bottom: 0px !important;
          z-index: 5;
          font-size:13px !important;
          max-height: 600px !important;
        "
        rounded
        dark
        mandatory
      >
       <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            style="min-height: 31px !important; margin-top: 0px !important"
          >
            <v-list-item-content
              style="
                color: white !important;
                min-height: 31px !important;
                padding-top: 2px !important;
                font-size:13px !important;
              "
            >
              <v-list-item-title
                color="white"
                v-html="item.description"
                  style="
                font-size:13px !important;
                line-height:1.2rem;
              "
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select></v-col
      >
    </v-row>
    <v-row style="margin-top:20px !important;" >
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6" md="6" class="ma-0 pa-0 " height="100%"   >
        <individual-drivers-table
          :selectedFactor="selectedFactorCP"
          :waterfallData="individualWaterfall"
        ></individual-drivers-table>
      </v-col>
       <v-divider dark style="margin:1px 15px 1px 15px;border-color:#F2F2F2;opacity: 1;z-index:1" v-if="isMobile"></v-divider>
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6" md="6"  class="ma-0 pa-0" height="100%" >
        <waffle-card :selectedFactor="selectedFactorCP" 
        :waterfallObjects="waterfallObjects"
        ></waffle-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IndividualDriversTable from "./IndividualDriversTable.vue";

import WaffleCard from "../ValuationDrivers/WaffleCard.vue";
export default {
  components: {
    IndividualDriversTable,
    WaffleCard,
  },
  data() {
    return {
      factorCategories: [],
      selectedFactor: null,
      factors: [],
      counter:0
    };
  },
  computed: {
     isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    selectedFactorCP() {
      return this.selectedFactor;
    },
    individualWaterfall() {
      return this.waterfallObjects[this.waterfallObjects.length-1]
    }
  },
  props: ["waterfallData", "waterfallLabels","waterfallObjects"],
  methods: {
    updateSelectedGroup(selecteditem) {
      
      
      this.selectedFactor = this.factors[selecteditem];
      //console.log(this.selectedFactor);
      //const selected = this.peerGroup[selecteditem];
      //this.$store.dispatch("updatePeergroup", selected);
    },
    updateSelectedGroupDropdown(selecteditem) {
      
      
      this.selectedFactor = this.factors.find(el => el.description === selecteditem);
     //console.log(this.selectedFactor);
      //console.log(this.selectedFactor);
      //const selected = this.peerGroup[selecteditem];
      //this.$store.dispatch("updatePeergroup", selected);
    },
     getFactorstring(factor){
    if(this.isMobile){
      return factor.mobileCode
    }
    return factor.description;
  },
  },
 
  beforeCreate() {
    this.factorCategories = this.$store.getters.factorCategories;

    //   this.selectedCategory = this.$store.getters.selectedCategory;
    //   this.financialFactors = this.$store.getters.financialFactors;
    //   this.economicFactors = this.$store.getters.economicFactors;
    //   this.marketFactors = this.$store.getters.marketFactors;
    //   this.companyFactors = this.$store.getters.companyFactors;
  },
  watch: {
    "$store.getters.selectedCategory": {
      immediate: true,
      deep: true,
      handler(selectedCategory) {
        //console.log(this.waterfallLabels);
        let tempFactors = [];
        this.factors = [];
        this.counter++;
        
        
        if (selectedCategory === "Company") {
          tempFactors = this.$store.getters.companyFactors;
          for (let factor of tempFactors) {
            //console.log(factor)
            if (this.waterfallLabels.includes(this.getFactorstring(factor).replace(/-/, " "))) {
              //console.log(factor)
              this.factors.push(factor);
            }
          }
        } else if (selectedCategory === "Financial") {
          tempFactors = this.$store.getters.financialFactors;
          for (let factor of tempFactors) {
            if (this.waterfallLabels.includes(this.getFactorstring(factor).replace(/-/, " "))) {
              this.factors.push(factor);
            }
          }
        } else if (selectedCategory === "Market") {
          tempFactors = this.$store.getters.marketFactors;
          for (let factor of tempFactors) {
            if (this.waterfallLabels.includes(this.getFactorstring(factor).replace(/-/, " "))) {
              this.factors.push(factor);
            }
          }
        } else if (selectedCategory === "Economic") {
          tempFactors = this.$store.getters.economicFactors;
          for (let factor of tempFactors) {
            if (this.waterfallLabels.includes(this.getFactorstring(factor).replace(/-/, " "))) {
              this.factors.push(factor);
            }
          }
        } else {
          this.factors = this.$store.getters.factorCategories;
        }
        this.updateSelectedGroup(0);
        //console.log(this.$refs)
        
        
       
      },
    },
  },
};
</script>

<style scoped>
span {
  margin-top: 0.5em;
  margin-left: 1em;
  float: left;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  background: #5ab7b7;
  color: white;

  max-height: 800px !important;
}
.v-sheet {
  box-shadow: none;
}
::v-deep .v-list-item {
  min-height: 26px !important;
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0px 6px 0px 14px !important;
}</style>