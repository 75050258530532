import { API } from "aws-amplify";
import AWS from 'aws-sdk';

export default {
  async getFactors(context)
  {
    //console.log(context)

    const resultFactors = []

    const data = await API.get('quantlensapi', '/factors')
    const items = data.Items
    let financialFactors = [];
    let marketFactors = [];
    let economicFactors = [];
    let companyFactors =[];

    for (let i = 0; i < items.length; i++) {
      const factor = AWS.DynamoDB.Converter.unmarshall(items[i])
      resultFactors.push(factor);
      if(factor.category ==='Financial'){
        financialFactors.push(factor);
      }else if(factor.category === 'Economic'){
        economicFactors.push(factor);
      }else if(factor.category === 'Company'){
        companyFactors.push(factor);
    }else {
      marketFactors.push(factor)
    }
  }

    //console.log(financialFactors)
    context.commit('setFactors', resultFactors);
    context.commit('setFinancialFactors',financialFactors);
    context.commit('setEconomicFactors',economicFactors);
    context.commit('setMarketFactors',marketFactors);
    context.commit('setCompanyFactors',companyFactors);

  
}

};
