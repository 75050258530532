<template>
  <div height="700px">
    <v-row align="center" justify="start" class="pa-0 ma-0">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12"  xs="12" class="pa-0 ma-0">
        <area-chart
          :entityData="entityData"
          :peerGroupData="peerGroupData"
          :premiumData="preparePremiumData"
          :entityName="entityName"
          :peerGroupName="peerGroupName"
          @customZoom="handleZoom"
        ></area-chart>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align="center" justify="start" style="margin-top: 0px !important" class="pa-0 ma-0">
      <v-spacer></v-spacer>
      <v-col cols="12" md="10" lg="8" xl="8" sm="12" xs="12" class="pa-0 ma-0">
        <bar-chart-container
          :waterfallDataEntity="waterFallEntityTimeline"
          :waterfallDataPeer="waterfallPeerTimeline"
          :entityName="entityName"
          :peerGroupName="peerGroupName"
          :zoom="zoom"
        ></bar-chart-container>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import AreaChart from "@/components/ValuationTImeline/AreaChart.vue";
import { mapGetters } from "vuex";
import BarChartContainer from "@/components/ValuationTImeline/BarChartContainer.vue";

export default {
  components: {
    AreaChart,
    BarChartContainer,
  },
  data() {
    return { zoom: { start: 80, end: 100 }, loading: false };
  },
  beforeCreate() {
    this.$store.dispatch("getFactors");
  },
  computed: {
    ...mapGetters({
      waterFallEntityTimeline: "waterfallEntityTimeline",
      waterfallPeerTimeline: "waterfallPeerTimeline",
      peerGroup: "getPeerGroup",
      entity: "getEntity",
      peerGroupName: "getPeerGroupName",
      // ...
    }),
    entityName() {
      if (this.entity) {
        return this.entity.entityCode;
      } else {
        return "";
      }
    },
    peerGroupLength() {
      return this.peerGroup.length;
    },
    entityData() {
      let dataTimeline = [];
      if (this.waterFallEntityTimeline.length) {
        //console.log(this.waterFallEntityTimeline);
        for (let i = 0; i < this.waterFallEntityTimeline.length; i++) {
          dataTimeline.push({
            quartal: this.formatQuartal(
              this.waterFallEntityTimeline[i].quartal
            ),
            value: Number(
              this.waterFallEntityTimeline[i].predicted_target).toFixed(2)
            ,
          });
        }
        let timeLine = [...new Set(dataTimeline.map((a) => a.quartal))];
        //console.log(timeLine);

        if (dataTimeline.length > timeLine.length) {
          //workaround if entity is in peergroup and data is duplicated
          let cleanedData = [];
          for (let quartal of timeLine) {
            cleanedData.push(dataTimeline.find((el) => el.quartal === quartal));
          }
          dataTimeline = cleanedData;
        }
        return dataTimeline;
      }

      return dataTimeline;
    },

    peerGroupData() {
      let result = [];
    
      if (this.waterfallPeerTimeline.length) {
        //console.log("reducePeergroup");
       //console.log(this.waterfallPeerTimeline)
        this.waterfallPeerTimeline.reduce(function (res, value) {
          if (!res[value.quartal]) {
            res[value.quartal] = { quartal: value.quartal, value: value.predicted_target };
            result.push(res[value.quartal]);
           //console.log(value)
          
          } else {
            res[value.quartal].value += value.predicted_target;
           //console.log(value.predicted_target)
          }

          return res;
        }, {});
        //console.log( this.waterfallPeerTimeline);
        for (let quar of result) {
          let length =  this.waterfallPeerTimeline.filter(el => el.quartal == quar.quartal).length
         //console.log(quar.quartal + length+" "+ quar.value)
          quar.value = Number((quar.value / length).toFixed(2));
          quar.quartal = this.formatQuartal(quar.quartal);
        }
        //console.log(result.map((a) => a.quartal));
      }
      //console.log(result)
      result = result.filter(item => !Number.isNaN(item.value));
     
      return result;
    },
    preparePremiumData() {
      const peergroup = this.peerGroupData;
      const result = [];
      if (peergroup.length && this.entityData.length) {
        //console.log(peergroup);
        for (let ent of this.entityData) {
          let quartal = ent.quartal;
          let peerValue = peergroup.find((el) => el.quartal === quartal);
          //console.log(ent);
          //console.log(peerValue);
          let dif = ent.value - peerValue.value;

          result.push({ quartal: quartal, value: Number(dif.toFixed(2)) });
        }
      }
      //console.log(result);
      return result;
    },
  },
  methods: {
    formatQuartal(quartalString) {
      //console.log(quartalString)
      let quartal = quartalString.split("_");

      let formatted =
        quartal[1].split("").reverse().join("") + " " + quartal[0];

      return formatted;
    },
    handleZoom(event) {
      this.zoom = event;
      //console.log(event);
    },
    loadData() {
      this.$store.dispatch("getWaterfallEntityAll");
      //console.log(this.$store.getters.waterFallEntityTimeline);
      //console.log(this.$store.getters.marketCapTimeline);
    },
  },
  watch: {
    "$store.getters.getPeerGroup": {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch("getWaterfallEntityAll");
      },
    },
    "$store.getters.getEntity": {
      immediate: true,
      deep: true,
      handler() {
        //console.log("triggeredEntity");

        this.$store.dispatch("getWaterfallEntityAll");
      },
    },
    "$store.getters.displayType": {
      immediate: true,
      deep: true,
      handler() {
        //console.log("triggeredEntity");

        this.$store.dispatch("getWaterfallEntityAll");
      },
    },
    "$store.getters.currentCurrency": {
      immediate: true,
      deep: true,
      handler() {
        //console.log("triggeredEntity");

        this.$store.dispatch("getWaterfallEntityAll");
      },
    },

    zoom: {
      immediate: true,
      deep: true,
      handler() {
        //console.log(this.zoom);
      },
    },
  },
};
</script>

<style>
</style>