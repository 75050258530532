<template>
  <v-container class="ma-0 pa-0" style="height: 100%">
    <v-card
      width="100%"
      elevation="0"
      rounded="lg"
      height="100%"
      class="ma-0 pa-2 justify-center"
      :loading="loading"
      align="left"
    >
      <div :style="isMobile ? 'padding:0px 15px 0px 15px; ':'padding: 15px'" class="justify-center">
        <v-row class="ma-0 pa-0" style="height: 100%" align="center">
          <v-col
            class="ma-0 pa-0"
            style="margin-top: 30px !important; margin-bottom: 10px !important;height:80%"
          >
            <div style="margin-top: 10px" class="leaders-div">
              <span
                ><p class="ma-0">"Leaders" {{ getDescription }}</p></span
              >
              <div style="display: inline-block">
                <span style="color: #b9b2bc">{{ leader }} banks</span>
                <v-card outlined elevation="0" class="leaders ma-0 pa-0">
                </v-card>
              </div>
            </div>

            <div style="margin-top: 10px" class="average-div">
              <v-divider v-if="!isMobile"></v-divider>
              <span
                ><p class="ma-0">"Average" {{ getDescription }}</p></span
              >
              <div style="display: inline-block">
                <span style="color: #b9b2bc">{{ average }} banks</span>
                <v-card outlined elevation="0" class="average ma-0 pa-0">
                </v-card>
              </div>
            </div>

            <div style="margin-top: 10px" class="laggards-div">
              <v-divider  v-if="!isMobile"></v-divider>
              <span
                ><p class="ma-0">"Laggards" {{ getDescription }}</p></span
              >
              <div style="display: inline-block">
                <span style="color: #b9b2bc">{{ laggers }} banks</span>
                <v-card outlined elevation="0" class="laggards ma-0 pa-0">
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col class="ma-0 pa-0">
            <v-sheet
              class="d-flex flex-wrap"
              align="bottom"
              style="float: right; margin-top: 1.5em; style=max-width: 400px"
              height="80%"
            >
              <v-tooltip
                top
                v-for="n in bank_list"
                color="white"
                :key="n.counter"
                style="border: 2px solid black !important"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    style="margin: 4px"
                    outlined
                    elevation="0"
                    v-on="on"
                    v-bind="attrs"
                    :style="n.style"
                  >
                  </v-card>
                </template>
                <span style="color: black; display: block !important">
                  <h3>{{ n.code }}</h3>
                  <span style="display: block !important">{{
                    determineText(n.value)
                  }}</span>
                  <span style="display: block !important" v-show="!isCategory"
                    >{{ n.shortCode }}:
                    {{ encodeCategoricalFactors(n.sourceValue) }}</span
                  >
                </span>
              </v-tooltip>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <template slot="progress">
      <v-overlay
        absolute
        class="d-flex flex-column text-center"
        color="white"
        opacity="0.9"
      >
        <div>
          <v-progress-circular
            size="23"
            :width="5"
            color="#3057b8"
            indeterminate
          >
          </v-progress-circular>
          <span style="margin-left: 10px">loading</span>
        </div>
      </v-overlay>
    </template>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      bank_list: this.peerGroupFactorList(),
      selectedDescription: "",
      leader: 0,
      average: 0,
      laggers: 0,
      loading:false
    };
  },
  props: ["selectedFactor", "waterfallObjects"],
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    peerGroupFactor() {
      const sourceData = this.$store.getters.getSourceDataPeer;
      let sum = 0;
      //console.log(sourceData);
     //console.log(this.selectedFactor)
      if (this.selectedFactor) {
        for (let i = 0; i < sourceData.length; i++) {
          let factor = sourceData[i][this.selectedFactor.id];
          if (factor) {
            sum += Number(factor.replace(/,/, "."));
          }
        }
        return (sum / sourceData.length).toFixed(2);
      }
      return 0;
    },
    currentEntity() {
      return this.$store.getters.getEntity;
    },
    getDescription() {
      if (this.selectedFactor) {
        if (
          ["Company", "Financial", "Market", "Economic"].includes(
            this.selectedFactor.description
          )
        ) {
          return this.selectedFactor.description;
        } else {
          return this.selectedFactor.code;
        }
      }
      return "";
    },
    isCategory() {
      //console.log(this.selectedFactor);
      ////console.log(this.waterfallData);
      // Show different Table for Categories, and for the three factors which are derived from the model and have no source data
      if (this.selectedFactor) {
        if (
          [
            "Company",
            "Financial",
            "Market",
            "Economic",
            "Company Specific Effect",
            "Time Effect",
            "Country Effect",
          ].includes(this.selectedFactor.description)
        ) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    determineText(value) {
      let label = "";
      if (value > 0) {
        label = "Premium: ";
      } else {
        label = "Discount: ";
      }
      let valueString = "";
      if (this.$store.getters.displayType === "marketValue") {
        valueString =
          this.$store.getters.currentCurrency.text +
          " " +
          Math.round(value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " million";
      } else if (this.$store.getters.displayType === "multiple") {
        valueString = value.toFixed(2) + " x";
      } else {
        valueString = value.toFixed(1) + " %";
      }
      return label + valueString;
    },
    peerGroupFactorList() {
      let counter = 0;
      this.leader = 0;
      this.average = 0;
      this.laggers = 0;
      const values = [];
      const sourceData = this.$store.getters.getSourceDataPeer;
      const entities = this.$store.getters.getEntities;

      //console.log(this.waterfallObjects);
      if (
        this.waterfallObjects.length &&
        this.selectedFactor &&
        this.currentEntity
      ) {
        this.waterfallObjects.forEach((element) => {
          let obj = {};
          obj.id = element.id;
          obj.code = entities.find((el) => el.id === element.id)["entityCode"];
          const entitySourceData = sourceData.find(
            (el) => el.id === element.id
          );
          //console.log(entitySourceData);
          //console.log(this.selectedFactor);
          if (entitySourceData && entitySourceData[this.selectedFactor.id]) {
            obj["sourceValue"] = entitySourceData[this.selectedFactor.id];
          } else {
            obj["sourceValue"] = "0,0";
          }
          obj["shortCode"] = this.getDescription;

          if (element[this.getFactorstring(this.selectedFactor).replace(/-/, " ")]) {
            obj["value"] =
              element[this.getFactorstring(this.selectedFactor).replace(/-/, " ")];
          } else {
            obj["value"] = 0.0;
          }

          values.push(obj);
        });

        //console.log(values);

        const sortedFactors = values.sort((a, b) => {
          if (a.value < b.value) {
            return 1;
          }
          if (a.value > b.value) {
            return -1;
          }
          return 0;
        });
        ////console.log(sortedFactors);
        //let max = sortedFactors[0].value;
        //console.log("quantiles");
        const q25 = this.calcQuantiles(sortedFactors, 0.25);
        const q75 = this.calcQuantiles(sortedFactors, 0.75);
        //console.log(q25);
        //console.log(q75);
        const grey = require("../../assets/elipsis/grey.svg");
        const aubergine = require("../../assets/elipsis/aubergine.svg");
        const turquise = require("../../assets/elipsis/turquise.svg");
        const selected = require("../../assets/elipsis/selected.svg");
        for (let i = 0; i < sortedFactors.length; i++) {
          if (sortedFactors[i].value < q75) {
            sortedFactors[i].style =
              "background:url(" +
              aubergine +
              ");" +
              this.getStyle(sortedFactors.length);

            this.laggers++;
          } else if (sortedFactors[i].value > q25) {
            this.leader++;
            sortedFactors[i].style =
              "background:url(" +
              turquise +
              ");" +
              this.getStyle(sortedFactors.length);
          } else {
            this.average++;
            sortedFactors[i].style =
              "background:url(" +
              grey +
              ");" +
              this.getStyle(sortedFactors.length);
          }
          sortedFactors[i].counter = counter;
          counter++;
          if (sortedFactors[i].id === this.currentEntity.id) {
            const size = this.getSize(sortedFactors.length);
            sortedFactors[i].style =
              sortedFactors[i].style +
              "background:url(" +
              selected +
              ");" +
              `width: ${size}em; height: ${size}em;border-color: transparent;background-repeat: no-repeat; background-size: 100%; `;
          }
        }

        ////console.log(sortedFactors.reverse());
        ////console.log(sortedFactors);
        //Categorical Variable: small values are better than big values
        // }

        return sortedFactors;
      }
    },
     getFactorstring(factor){
    if(this.isMobile){
      return factor.mobileCode
    }
    return factor.description;
  },
    calcQuantiles(arr, quantile) {
      const pos = (arr.length - 1) * quantile;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (arr[base + 1].value !== undefined) {
        return arr[base].value + rest * (arr[base + 1].value - arr[base].value);
      } else {
        return arr[base].value;
      }
    },
    getSize(length) {
      let size = "4";

      ////console.log(len_list);
      if (length < 5) {
        //
      } else if (length < 20) {
        size = 3;
      } else if (length < 40) {
        size = 2;
      } else {
        size = 1;
      }
      return size;
    },
    getStyle(length) {
      const size = this.getSize(length);
      return `width: ${size}em; height: ${size}em;border-color: transparent; background-repeat: no-repeat; background-size: 100%; `;
    },
    encodeCategoricalFactors(value) {
      value = Number(value.replace(/,/, "."));
      if (this.selectedFactor && !this.isCategory) {
        if (this.selectedFactor.id === "R13") {
          let encoding = this.$store.getters.esgEncoding;
          //console.log(value);
          return encoding.find((el) => el.value === Math.round(value)).short;
        } else if (this.selectedFactor.id === "R28") {
          let encoding = this.$store.getters.msciEncoding;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R09") {
          let encoding = this.$store.getters.creditratingEncoding;
          //console.log(value);
          return encoding.find((el) => el.value === Math.round(value)).short;
        } else if (this.selectedFactor.id === "R10") {
          let encoding = this.$store.getters.ratingWatch;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R29") {
          let encoding = this.$store.getters.irQualityEncoding;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R11") {
          return (
            "USD " +
            Math.round(value)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " million"
          );
        } else if (this.selectedFactor.id === "R42") {
          return "USD " + value.toFixed(1);
        }
         else if (
          this.selectedFactor.id === "R04" ||
          this.selectedFactor.id === "R05"
        ) {
          return (value * 100).toFixed(1) + "%";
        }else if (
          this.selectedFactor.id === "R01" ||
          this.selectedFactor.id == "R02" ||
          this.selectedFactor.id == "R03" ||
          this.selectedFactor.id == "R07"  ||
          this.selectedFactor.id == "R36" 

        ) {
          return (value * 100).toFixed(2) + "%";
        } else if (this.selectedFactor.id === "R21") {
          //console.log(value)
          return Math.round(value);
        } else {
          ////console.log(value)
          return Math.round(value*100) + "%";
        }
      }
      return "";
    },
  },
  watch: {
    selectedFactor: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedFactor) {
          this.selectedDescription = this.selectedFactor;
          this.bank_list = this.peerGroupFactorList();
        }
      },
    },
    waterfallObjects: {
      immediate: true,
      deep: true,
      handler() {
        this.bank_list = this.peerGroupFactorList();
      },
    },
    "$store.getters.isLoadingPred": {
      immediate: true,
      deep: true,
      handler(loading) {
        //console.log("loading" + loading);
       //console.log(loading);
        //console.log("loadingChart" + loading);

        this.loading = loading;
      },
    },
  },
};
</script>


<style scoped>
.leaders {
  width: 1em;
  height: 1em;
  border-color: transparent;
  background: url("../../assets/elipsis/turquise.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin-right: 8px !important;
  margin-top: 4px !important;
}
.laggards {
  width: 1em;
  height: 1em;
  border-color: transparent;
  background: url("../../assets/elipsis/aubergine.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin-right: 8px !important;
  margin-top: 4px !important;
}

.average {
  width: 1em;
  height: 1em;
  border-color: transparent;
  background: url("../../assets/elipsis/grey.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin-right: 8px !important;
  margin-top: 4px !important;
}
@media(max-width:960px) {
  

.leaders-div{
  position:absolute;
  top:10%;
}
.laggards-div{
  position:absolute;
  top:75%;
}
.average-div{
  position:absolute;
  top:42%;
}
}

</style>