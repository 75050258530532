import VueRouter from 'vue-router';


import { Auth } from 'aws-amplify';
import LoginPage from './pages/LoginPage'
import Predictedvaluation from './pages/PredictedValuation'
//import ValuationDrivers from './pages/ValuationDrivers'
import ValuationTimeline from './pages/ValuationTimeline'
import ValuationSimulator from './pages/ValuationSimulator'
import Vue from 'vue';
import store from './store/index.js'




Vue.use(VueRouter);



const router = new VueRouter({
  mode: "history",
  routes: [
    { path: '/', redirect: '/auth', },
    { path: '/auth', name:'auth', component: LoginPage, meta: { requiresAuth: false } },
    { path: '/predicted-valuation',name:'AI Predicted Valuation', component: Predictedvaluation, meta: { requiresAuth: true } },
    //{ path: '/valuation-drivers',name:'AI Predicted Valuation', component: ValuationDrivers, meta: { requiresAuth: true } },
    { path: '/valuation-timeline',name:'Valuation Timeline', component: ValuationTimeline, meta: { requiresAuth: true } },
    { path: '/valuation-simulator',name:'Valuation Simulator', component: ValuationSimulator, meta: { requiresAuth: true } },
    {path : ' </^[^.]+$|.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$)([^.]+$)/>',redirect: '/auth'}



  ]
});
/* router.beforeEach(function(to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        //console.log(store.getters.user)
      next('/auth');
    }else{ 
      next();
    }
  }); */

/* eslint-disable no-unused-vars */
router.beforeResolve((to, from, next) =>
{
  if (to.meta.requiresAuth) {
   
    Auth.currentAuthenticatedUser().then((user) =>
    {
     
      const currentUser = {
          
        gender: user.attributes["gender"],
        name : user.attributes["given_name"],
        familyName: user.attributes["family_name"],
        company:user.attributes["custom:company"]
      }
      store.commit("setUser",currentUser)
      store.commit("setDefaultEntity",currentUser.company)
      //console.log(currentUser)
      next()       
      
    }).catch(() =>
    {
      
      next({
        path: '/auth'
      });
    });
  } else {
   
    next()
  }
})
// router.afterEach()
/* eslint-enable no-unused-vars */

export default router;