<template>
  <v-container >
    <v-row align="center" v-if="!isMobile" >
      <v-col cols="3" class="ma-0 pa-0"><span class="aubergine"  style="margin-left:10px !important">Valuation Drivers</span></v-col>
      <v-col cols="6" class="ma-0 pa-0">
        
        <span class="turquise" style="min-width:80px;padding-right:8px;display:inline-block;float:left;max-width:20%;text-align:center">Input Value</span>

        <span class="turquise" style="display:inline-block;max-width:70%;text-align:center"
          >Change drivers to simulate valuation sensitivity</span
        >
      </v-col>
      <v-col cols="2"  class="ma-0 pa-0" style="text-align:center"><span class="aubergine">Driver sensitivity</span></v-col>
      <v-col cols="1" class="ma-0 pa-0"
        ><span class="aubergine"
          ><v-btn
            class="ma-0 text-none"
            x-small
            rounded
            outlined
            elevation="0"
            @click="resetRows"
            style="margin-bottom:2px !important;letter-spacing:0px;font-size:12px;letter-spacing:0px;color: #3f2a48;"
            >Reset</v-btn
          > </span>
         <v-checkbox
            class="ma-0 pa-0 align-center justify-center"
            style="margin-left:12px !important;"
            v-model="resetAll"
            @change="propagateChild"
          ></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
          <v-select
          v-if="isMobile"
        v-model="selectedFactor"
        justify="end"
        class="ma-0 pa-0"
        align="end"
        :items="sortedSourceData"
        item-text="description"
        item-value="description"
        background-color="secondary"
        dense
        height="31"
        color="white"
        item-color="white"
        
        placeholder="Select Factors"
        :menu-props="{ maxHeight: 600 }"
        style="
          max-width: 250px !important;
          margin-right: 0px !important;
          margin-left:-2px !important;
          margin-bottom: 0px !important;
          z-index: 5;
          font-size:13px !important;
          max-height: 600px !important;
        "
        rounded
        dark
        mandatory
      >
       <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            style="min-height: 31px !important; margin-top: 0px !important"
          >
            <v-list-item-content
              style="
                color: white !important;
                min-height: 31px !important;
                padding-top: 2px !important;
                font-size:13px !important;
              "
            >
              <v-list-item-title
                color="white"
                v-html="item.description"
                  style="
                font-size:13px !important;
                line-height:1.2rem;
              "
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    <row-component 
      v-for="factor in sortedSourceData"
      :key="factor.description + factor.value+key"
      :factorData="factor"
      :resetAll="resetTransition"
      :resetParent="resetChild"
      :selectedFactor="selectedFactor"
      @resetCheckBoxValues="resetCheckBox"
      @recalculateWaterfall="recalculateWaterfall"
    ></row-component>
    <v-row v-if="isMobile">
      <v-col cols="9"><span class="aubergine"
          ></span>
         <v-checkbox
            class="ma-0 pa-0 align-center justify-center"
            style="margin-left:-5px !important; font-size:13px !important"
            v-model="resetAll"
            dense
            @change="propagateChild"
            
          > <template v-slot:label>
        <span style="font-size:12px;margin-bottom:3px">
          Select all drivers
        </span>
      </template></v-checkbox></v-col>
        
      <v-col cols="3"><v-btn
            class="ma-0 text-none"
            x-small
            rounded
            outlined

            elevation="0"
            @click="resetRows"
            style="margin-bottom:2px !important;margin-right:0px !important; font-size:12px;float:right;letter-spacing:0px;color: #3f2a48;"
            >Reset</v-btn
          > </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RowComponent from "./RowComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: { RowComponent },
  data() {
    return {
      resetChild:false,
      resetAll: false,
      resetTransition: "none",
      key:0,
      selectedFactor:""
    };
  },
  props: ["sourceData"],
  computed: {
    ...mapGetters({
      featureImportance: "featureImportance",
    }),
     isMobile(){
      return ((this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ))
    },
    sortedSourceData() {
      
      const featureImp = this.featureImportance;
      const sorted = this.sourceData.slice().sort(function (a, b) {
        return featureImp.indexOf(a.id) - featureImp.indexOf(b.id);
      });
      let counter = 1;
      sorted.forEach((element) => {
        element.description = counter.toString() + ". " + element.description;
        counter++;
      });
      
      return sorted;
    },
  },
  methods: {
    propagateChild(){
      this.resetChild = !this.resetChild
    },
    recalculateWaterfall(newTargetValue) {
      this.$emit("recalculateWaterfall", newTargetValue);
    },
    resetRows() {
      //console.log("resetTable");
      //console.log(this.resetTransition);
      this.resetTransition = this.resetAll;
      this.resetCheckBox();

      //this.$emit("recalculateWaterfall", { id: "", value: 0 });
    },
    resetCheckBox() {
      setTimeout(
        function () {
          this.resetAll = false;
          this.resetTransition = "none";
          this.resetChild = false;
        }.bind(this),
        100
      );
      ////console.log(this.resetAll)
    },
  },
  watch: {
    sourceData: {
      immediate: true,
      deep: true,
      handler() {
        this.key++;
        
      },
    },
    sortedSourceData: {
      immediate: true,
      deep: true,
      handler() {
        if(this.isMobile && this.sortedSourceData[0]){
          this.selectedFactor= this.sortedSourceData[0].description;
         //console.log(this.selectedFactor)
          this.key++;
        }
        
      },
    },
  },
};
</script>

<style scoped>
.turquise {
  color: #5ab7b7 !important;
  font-family: "guardiansans-medium";
  
}

.aubergine {
  color: #3f2a48 !important;
  font-family: "guardiansans-medium";
  text-align:center !important;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  background: #5ab7b7;
  color: white;

  max-height: 800px !important;
}
.v-sheet {
  box-shadow: none;
}
::v-deep .v-list-item {
  min-height: 26px !important;
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0px 6px 0px 14px !important;
}
</style>