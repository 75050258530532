<template>
  <div :id="name" class="chart-container"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, TitleComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import { CustomChart } from "echarts/charts";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "AreaChartValuation",
      chart: null,
      pictorialDataPos: [],
      pictorialDataNeg: [],
      floatingBarData: [],
      rectData: [],
      showRectLabel: { show: false },
      lineChartData: [],
      markPointLine: {},
      counter: 0,
    };
  },
  props: [
    "waterfallLabel",
    "waterfallDataPos",
    "waterfallDataNeg",
    "placeholderData",
    "entityName",
    "peerGroupName",
    "selectedCategory",
    "labelString",
  ],
  computed: {
    ...mapGetters({
      displayType: "displayType",
      currentCurrency: "currentCurrency",

      // ...
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    fontSizeChart() {
      if (this.isMobile) {
        if(this.selectedCategory==="Summary"){
          return 12
        }
        return 9;
      }
      return 15;
    },
    fontSizeAxisChart() {
      if (this.isMobile) {
        if(this.selectedCategory==="Summary"){
          return 12
        }
        return 9;
      }
      return 12;
    },
    fontSizeLabelChart() {
      if (this.isMobile) {
        return 12
      }
      return 15;
    },
    smyboloffsetPos() {
      if (this.isMobile) {
        if(this.selectedCategory==="Summary"){
          return [0, "-300%"]
        }
        return [0, "-275%"];
      }
      else if (this.waterfallLabel.length > 10) {
        return [0, "-340%"];
      }
      return [-15, "-175%"];
    },
    smyboloffsetNeg() {
      if (this.isMobile) {
        if(this.selectedCategory==="Summary"){
          return [0, "-290%"]
        }
        return [0, "-265%"];
        
      } else if (this.waterfallLabel.length > 10) {
        return [0, "-340%"];
      }
      return [-15, "-160%"];
    },
    rotateDeg(){
      if(this.isMobile&& this.waterfallLabel.length>10){
        return 30;
      }
      return 0;
    },

    calculateOffset() {
      if (
        this.displayType === "marketValue" ||
        this.displayType === "percentage"
      ) {
        if (this.isMobile) {
          return 0;
        } else if (this.waterfallLabel.length > 10) {
          return 0;
        }
        return 10;
      } else {
        if (this.isMobile) {
          return 0;
        } else if (this.waterfallLabel.length > 10) {
          return 0;
        }
        return 8;
      }
    },
    overallmax() {
      this.prepareFloatingBarData();

      const summedValues = [];
      for (let j = 1; j < this.waterfallDataPos.length - 1; j++) {
        let sum = 0;
        sum += Number(this.floatingBarData[j].toFixed(1));
        sum += Number(this.placeholderData[j][1].toFixed(1));
        if (this.waterfallDataPos[j][1] != "-") {
          sum += Number(this.waterfallDataPos[j][1].toFixed(1));
        } else {
          sum += Number(this.waterfallDataNeg[j][1].toFixed(1));
        }
        summedValues.push(sum);
      }
     //console.log(summedValues);

      let maxm = summedValues[0];
      for (let j = 0; j < summedValues.length; j++) {
        if (summedValues[j] > maxm) {
          maxm = summedValues[j];
        }
      }

      return maxm;
    },

    max() {
      // Initialize max to 0 at beginning
      // of finding max element of each column
      this.prepareFloatingBarData();
      let [xStart, xEnd] = this.xCoordinatesRect();
      const summedValues = [];
      for (let j = xStart; j < xEnd; j++) {
        let sum = 0;
        sum += this.floatingBarData[j];
        sum += this.placeholderData[j][1];
        if (this.waterfallDataPos[j][1] != "-") {
          sum += this.waterfallDataPos[j][1];
        } else {
          sum += this.waterfallDataNeg[j][1];
        }
        summedValues.push(sum);
      }
     //console.log(summedValues);

      let maxm = summedValues[0];
      for (let j = 0; j < summedValues.length; j++) {
        if (summedValues[j] > maxm) {
          maxm = summedValues[j];
        }
      }

      return maxm;
    },
    min() {
      let [xStart, xEnd] = this.xCoordinatesRect();
      const summedValues = [];
      for (let j = xStart; j < xEnd; j++) {
        let sum = 0;
        sum += this.placeholderData[j][1];

        summedValues.push(sum);
      }
      let maxm = summedValues[0];
      //console.log(arr[1]);
      for (let j = 0; j < summedValues.length; j++) {
        if (summedValues[j] < maxm) {
          maxm = summedValues[j];
        }
      }
     //console.log(summedValues);
      return maxm;
    },

    premiumCalcPer() {
      if (this.waterfallDataPos.length) {
        return Math.round(
          (this.waterfallDataPos[this.waterfallDataPos.length - 1].value /
            this.waterfallDataPos[0].value -
            1) *
            100
        );
      }
      return 0;
    },
  },
  methods: {
    getLineChartData() {
      //console.log(this.waterfallDataPos);
      this.lineChartData = [];
      let lineHeight = this.overallmax;
     //console.log(lineHeight);
      //let max = 0;
      //console.log(this.max);
      const data = [];
      let symbol = "";
      if (this.premiumCalcPer >= 0) {
        symbol = "+";
      }
      if (this.waterfallDataPos.length) {
        const len = this.placeholderData.length;
        this.markPointLine = [
          {
            value: symbol + this.premiumCalcPer + "%",
            xAxis: len / 2,
            yAxis: lineHeight * 1.55,
          },
        ];

        data.push([0.5, this.waterfallDataPos[0].value * 1.3]);
        data.push([0.5, lineHeight * 1.35]);
        data.push([0.7, lineHeight * 1.55]);

        data.push([len / 2, lineHeight * 1.55]);

        data.push([len - 1 + 0.5 - 0.2, lineHeight * 1.55]);
        data.push([len - 1 + 0.5, lineHeight * 1.35]);
        data.push([
          len - 1 + 0.5,
          this.waterfallDataPos[this.waterfallDataPos.length - 1].value +
            this.waterfallDataPos[0].value * 0.3,
        ]);
        //console.log(data);
      }
      this.lineChartData = data;
    },
    prepareFloatingBarData() {
      //console.log(this.waterfallDataPos);
      this.floatingBarData = [];
      for (let i = 0; i < this.waterfallDataPos.length; i++) {
        this.floatingBarData.push(this.waterfallDataPos[0].value * 0.05);
      }
      //console.log(this.floatingBarData);
    },

    xCoordinatesRect() {
      let xStart = -1;
      let xEnd = -1;
      const length = this.waterfallLabel.length;
      ////console.log(this.selectedCategory)
      if (this.selectedCategory == "Company") {
        xStart = 1;
        xEnd = length - 4;
      } else if (this.selectedCategory == "Financial") {
        xStart = 2;
        xEnd = length - 3;
      } else if (this.selectedCategory == "Market") {
        xStart = 3;
        xEnd = length - 2;
      } else if (this.selectedCategory == "Economic") {
        xStart = 4;
        xEnd = length - 1;
      }
      //console.log(xStart);
      return [xStart, xEnd];
    },

    calculateRectangle() {
      let [xStart, xEnd] = this.xCoordinatesRect();
      ////console.log(xStart)
      let y = 0;

      let dif = 0;
      let isCategory = (arr, target) => target.every(v => arr.includes(v));
      console.log(this.waterfallLabel)
      let categories= ["Market","Economic","Financial","Company"]
      if(this.isMobile){
        categories=["MKT","CMP","ECO","FIN"]
      }
      if (this.selectedCategory === "Summary" || isCategory(this.waterfallLabel,categories)) {
        this.showRectLabel = { show: false };
        this.rectData = [[]];
        return;
      }
      if (this.waterfallDataPos.length > 0) {
        // //console.log(this.waterfallDataPos)
        y = this.max * 1.4;
        this.showRectLabel = {
          show: true,
          position: "insideTop",
          color: "#3F2A48",
          borderWidth: 2,
          borderRadius: 12,
          backgroundColor: "white",
          fontSize: this.fontSizeLabelChart,
          borderColor: "#5AB7B7",
          padding: [5, 15, 5, 15],
          distance: -10,
        };
        ////console.log()

        dif = this.max * 1.4 - this.min * 0.71;
        //console.log(this.max);
        //console.log(this.min);
        //console.log(dif);
        ////console.log(sum)
        this.rectData = [
          {
            value: [y, xStart, xEnd, dif, this.labelString],
            itemStyle: {
              color: "#5AB7B7",
              opacity: 0.15,
              borderColor: "#5AB7B7",
              borderWidth: 2,
              fontSize: this.fontSizeLabelChart,

              fontFamily: "guardiansans-regular",
            },
          },
        ];
      }
    },
    pictorialData() {
      this.pictorialDataPos = [];
      this.pictorialDataNeg = [];
      for (let i = 0; i < this.placeholderData.length; i++) {
        if (i > 0 && i < this.placeholderData.length - 1) {
          if (this.waterfallDataNeg[i][1] != "-") {
            this.pictorialDataNeg.push([
              i,
              Number(this.waterfallDataNeg[i][1]) +
                Number(
                  this.placeholderData[i][1] +
                    this.waterfallDataPos[0].value * 0.04
                ),
            ]);
          } else {
            this.pictorialDataPos.push([
              i,
              this.waterfallDataPos[i][1] +
                this.placeholderData[i][1] +
                this.waterfallDataPos[0].value * 0.04,
            ]);
          }
        }
      }
    },
    formatValues(value) {
      if (this.displayType == "marketValue") {
        return Math.round(value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (this.displayType == "multiple") {
       //console.log(value);
        return value.toFixed(2);
      } else {
        return value.toFixed(1);
      }
    },

    drawGraph() {
      if (this.chart == null) {
        return;
      }
      this.chart.setOption({
        textStyle: {
          fontFamily: "guardiansans-regular",
          color: "#3F2A48",
        },

        xAxis: [
          {
            type: "category",
            splitLine: { show: false },
            scale: false,
            axisLabel: {
              width: 75, //fixed number of pixels
              overflow: "break",
              lineHeight: 14,
              rotate: this.rotateDeg, // or 'break' to continue in a new line
              interval: 0,
              fontSize: this.fontSizeAxisChart,
              fontFamily: "guardiansans-regular",
              color: "#3F2A48",
              formatter: function (value) {
                if(this.waterfallLabel.length>8){
                    return value.split(" ").join(" \n");
                }
                return value;
              
              }.bind(this),

              //If the label names are too long you can manage this by rotating the label.
            },
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            data: this.waterfallLabel,
          },
          {
            type: "value",
            min: 0,
            max: this.waterfallLabel.length,
            show: false,
          },
        ],
        yAxis: {
          type: "value",
          show: false,
          min: 0,
          max: function () {
            if (this.waterfallDataPos.length) {
              return this.overallmax * 1.6;
            }
            return 0;
          }.bind(this),
        },
        grid: {
          left: "25",
          top: "10%",
          right: "25",
          bottom: "15%",
        },

        series: [
          {
            name: "Floating",
            type: "bar",
            stack: "waterfall",
            z: 10,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: this.floatingBarData,
          },
          {
            name: "Placeholder",
            type: "bar",
            stack: "waterfall",
            z: 10,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: this.placeholderData,
          },
          {
            name: "WaterfallPos",
            type: "bar",
            stack: "waterfall",
            z: 10,
            barMaxWidth: 70,
            barMinWidth: 30,
            itemStyle: {
              color: "#5AB7B7",
              borderRadius: 3,
            },
            label: {
              show: true,
              position: "top",
              fontSize: this.fontSizeChart,
              color: "#3F2A48",
              fontFamily: "guardiansans-regular",
              offset: [this.calculateOffset, 3],
              formatter: function (params) {
               //console.log(params.value[1]);
                if (params.value[1] != null) {
                  return this.formatValues(params.value[1]);
                } else {
                  return this.formatValues(params.value);
                }
              }.bind(this),
            },
            data: this.waterfallDataNeg,
          },
          {
            name: "WaterfallNeg",
            type: "bar",
            stack: "waterfall",
            z: 10,
            barMaxWidth: 70,
            barMinWidth: 15,
            itemStyle: {
              borderRadius: 3,
              color: "#5AB7B7",
            },
            label: {
              show: true,
              position: "top",
              fontSize: this.fontSizeChart,
              color: "#3F2A48",
              fontFamily: "guardiansans-regular",
              offset: [this.calculateOffset, 1],
              formatter: function (params) {
               //console.log(params.value);
                if (params.value[1] != null) {
                  return this.formatValues(params.value[1]);
                } else {
                  return this.formatValues(params.value);
                }
              }.bind(this),
            },
            data: this.waterfallDataPos,
          },
          {
            type: "pictorialBar",
            name: "pictorial element pos",
            symbol: "triangle",
            symbolPosition: "end",
            symbolSize: [10, 10],
            color: "#5AB7B7",
            symbolOffset: this.smyboloffsetPos,
            z: 15,
            data: this.pictorialDataPos,
          },
          {
            type: "pictorialBar",
            name: "pictorial element neg",
            symbol: "triangle",
            symbolPosition: "end",
            symbolSize: [10, 10],
            symbolRotate: "180",
            color: "red",
            symbolOffset: this.smyboloffsetNeg,
            z: 15,
            data: this.pictorialDataNeg,
          },
          {
            type: "line",
            z: 5,
            name: "Difference Line",
            showSymbol: false,
            smooth: true,
            data: this.lineChartData,
            xAxisIndex: 1,

            markPoint: {
              symbol: "circle",
              symbolSize: 0,
              symbolKeepAspect: false,
              z: 40,

              itemStyle: {
                color: "white",
                borderColor: "#5AB7B7",
              },

              label: {
                align: "center",
                verticalAlign: "middle",
                show: true,
                backgroundColor: "white",
                color: "#3F2A48",
                borderWidth: 2,
                borderRadius: 12,
                fontSize: this.fontSizeLabelChart,

                fontFamily: "guardiansans-regular",

                borderColor: "#5AB7B7",
                padding: [5, 15, 5, 15],
              },
              data: this.markPointLine,
            },

            lineStyle: {
              type: "dotted",
              color: "#3F2A48",
            },
          },
          {
            type: "custom",
            zlevel: -5,
            xAxisIndex: 1,

            renderItem: function (params, api) {
              // This method will be called for each dataItem repectively.
              // Notice: it does not ensure that called according to the order
              // of `dataItem`.

              // Some processes, such as coordinate conversion.
              // `api.value(0)` is used to retrieve the value on the first
              // dimension in the current `dataItem`.
              let style = {};
              var rectShape = {
                // position and location of the rectangular.
                x: -5,
                y: -5,
                width: 0,
                height: 0,
                r: 10,
              };
              if (api.value(0)) {
                var categoryIndex = api.value(0);
                // `api.coord(...)` is used to convert data values to pixel values,
                // will are necessary for graphic elements rendering.
                //console.log(api.value(0));
                //console.log(api.value(1));
                var startPoint = api.coord([api.value(1), categoryIndex]);
                var endPoint = api.coord([api.value(2), categoryIndex]);
                // `api.size(...)` is used to calculate the pixel size corresponding to
                // the a value range that the length is 1 on Y axis.
                var height = api.size([0, api.value(3)])[1];

                // The property `shape` incicates the location and size of thsi
                // element.
                // `echarts.graphic.clipRectByRect` is used for clipping the
                // rectangular when it overflow the bounding box of the current
                // coordinate system (cartesian).
                // If the rect is totally clipped, returns undefined.
                //startPoint[0] -= 40;
                //endPoint[0] += 40;
                style = api.style();
                rectShape = {
                  // position and location of the rectangular.
                  x: startPoint[0],
                  y: startPoint[1],
                  width: endPoint[0] - startPoint[0],
                  height: height,
                  r: 10,
                };
              }

              // Returns definitions for the current `dataItem`.
              return {
                // 'rect' indicates that the graphic element is rectangular.
                // Can also be 'circle', 'sector', 'polygon', ...
                type: "rect",
                shape: rectShape,
                style: style,

                // `api.style(...)` is used to obtain style settings, which
                // includes itemStyle settings in optino and the result of
                // visual mapping.
                // style: {
                //   opacity: 1,
                //   fill: "#5AB7B7",
                //   stroke: "#5AB7B7",
                //   lineWidth: 3,
                // }
              };
            },

            label: this.showRectLabel,
            clip: true,
            encode: {
              label: 4,
            },
            data: this.rectData,
          },
        ],
      });

     //console.log("finished");
    },
    resizeChart() {
      if (this.chart != null) {
        //console.log("now");
        this.chart.resize();
      }
    },
  },
  mounted() {
    echarts.use([
      GridComponent,
      BarChart,
      SVGRenderer,
      UniversalTransition,
      TitleComponent,
      CustomChart,
    ]);

    this.chart = echarts.init(document.getElementById(this.name), null, {
      renderer: "svg",
    });
    this.drawGraph();
    if (this.$store.getters.isLoadingPred) {
      this.chart.showLoading({
        fontFamily: "guardiansans-regular",
        fontSize: this.fontSizeChart,
        textColor: "#3F2A48",
      });
    }
  },
  created() {
    window.addEventListener("resize", this.resizeChart, { passive: true });
  },
  watch: {
    "$store.getters.selectedCategory": {
      immediate: true,
      deep: true,
      handler() {
        this.showRectLabel = { show: false };
      },
    },
    waterfallDataPos: {
      immediate: true,
      deep: true,
      handler() {
        //console.log("testFinished");

        this.pictorialData();
        this.calculateRectangle();
        this.prepareFloatingBarData();
        this.getLineChartData();

        this.drawGraph();
        //this.$store.commit("setLoading", false)
      },
    },
    "$store.getters.isLoadingPred": {
      immediate: true,
      deep: true,
      handler(loading) {
       //console.log("loading" + loading);
        //console.log(lading)
        if (this.chart) {
          //console.log("loadingChart" + loading);
          if (loading) {
            this.chart.showLoading({
              fontFamily: "guardiansans-regular",
              fontSize: this.fontSizeChart,
              textColor: "#3F2A48",
            });
          } else {
            this.chart.hideLoading();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
@media (max-width: 960px) {
  .chart-container {
    width: 100%;
    height: 300px;
  }
}
@media (min-width: 960px) {
  .chart-container {
    width: 100%;
    height: 520px;
  }
}
</style>