<template>
  <div class="background overflow-hidden">
    <header>
      <section class="header-toggled" :class="toggleHeaderclass">
        <v-container fluid>
          <v-row :align="alignment">
            <v-col cols="1" xs="1" xl="2" lg="2" md="2"
              ><logo-component :focused="focused"></logo-component>
            </v-col>
            <v-col cols="8" xl="3" lg="3" md="3" xs="8" sm="3">
              <hr
                v-if="focused"
                style="margin-top:75px;width:90vw !important;margin-left:-6vw;"/>
              <v-autocomplete
                :value="entityValue"
                item-text="name"
                item-value="id"
                :items="entityList"
                rounded
                :menu-props="{ maxHeight: 350 }"
                light
                @change="updateEntity"
                @click="updateHeader"
                color="white"
                item-color="white"
                background-color="white"
                v-if="(isMobile && focused) || (!isMobile && !focused)"
                placeholder="Enter Bank Name"
                height="2.4rem"
                :class="focusAutocomplete"
              >
                <template v-slot:item="data">
                  <v-list-item-content
                    style="color: white !important; min-height: 26px !important"
                    v-text="data.item.name"
                  ></v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-icon color="black" @click="updateHeader"
                    >mdi-magnify</v-icon
                  >
                </template>
                <template v-slot:append-outer>
                  <v-icon
                    v-if="focused"
                    color="white"
                    @click="
                      focused = !focused;
                      toggledOptions = !toggledOptions;
                    "
                    >mdi-window-close</v-icon
                  >
                </template>
              </v-autocomplete>
              <v-btn
                small
                class="white--text text-none selected-bank"
                rounded
                style="background-color: white;margin-left:5px;"
                elevation="0"
                :height="isMobile ? 31 : 36"
                outlined
                width="30"
               
                v-if="isMobile && !focused"
                @click="updateHeader"
              >
                <v-icon color="black" @click="updateHeader">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
              
                :input-value="entityValueDisplay"
                medium
                class="secondary white--text text-none selected-bank"
                rounded
                style="margin-left:10px;"
                elevation="0"
                :height="isMobile ? 31 : 36"
                outlined
                
                v-if="isMobile && hasEntity && !focused"
                @click="resetEntity(true)"
              >
                {{ entityValueDisplay }}
              </v-btn>
            </v-col>
            <v-col cols="1" v-if="!isMobile" class="ma-0 pa-0" justify="end">
              <v-btn
                :input-value="entityValueDisplay"
                medium
                class="secondary white--text text-none selected-bank"
                rounded
                elevation="0"
                :height="isMobile ? 31 : 36"
                outlined
                
                v-if="hasEntity && !focused"
                @click="resetEntity(true)"
              >
                {{ entityValueDisplay }}
              </v-btn>
            </v-col>
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-col cols="3" md="4" sm="5" xs="3" :style="focused?'margin-top:15px !important':''" > <user-menu ></user-menu></v-col>
          </v-row>
          <v-row :style="isMobile?'margin-top:0px !important':''">
            <v-spacer></v-spacer>
            <v-col class="ma-1 pa-0" cols="12" lg="10" md="10" xl="10" sm="12" xs="12"><hr /></v-col>
            <v-spacer></v-spacer>
          </v-row>

          <v-row class="ma-0" align="center" v-show="!focused">
            <v-spacer v-if="$vuetify.breakpoint.sm"></v-spacer>
            <v-col
              cols="2"
              class="ma-0 pa-0"
              v-if="
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.xl
              "
            >
              <button
                v-if="toggledOptions"
                @click="toggleOptions"
                class="toggle-button-show"
                style="font-size: 1rem !important"
              >
                <v-icon color="secondary" dark> mdi-chevron-down </v-icon> Show
                options
              </button></v-col
            >

            <v-col
              cols="12"
              md="10"
              lg="8"
              xl="10"
              sm="12"
              xs="12"
              align="start"
              class="pa-0 ma-0"
            >
              <div class="center-header">
                <options-component
                  v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                ></options-component>
                <tab-navigation></tab-navigation>
                <peer-group-filter
                  v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                  style="margin-left: 5px !important"
                ></peer-group-filter>
              </div>
            </v-col>
          </v-row>

          <!--<div v-if="!toggledOptions" class="border-low"></div> -->
          <v-row v-if="!toggledOptions">
            <v-spacer></v-spacer>
            <v-col
              class="ma-0 pa-0"
              cols="10"
              style="margin-top: -15px !important"
              justify="center" v-if="!isMobile"              ><hr
            /></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <div v-if="!isMobile">
            <v-row
              v-show="!toggledOptions"
              class="container_expanded ma-1 pa-0"
              align="center"
            >
              <v-col
                cols="2"
                class="ma-0 pa-0 toggle-button-hide"
                style="margin-top: -8px !important"
              >
                <button
                  class="ma-0 pa-0"
                  @click="toggleOptions"
                  style="font-size: 1rem !important"
                >
                  <v-icon color="secondary" dark> mdi-chevron-up </v-icon> Hide
                  options
                </button>
              </v-col>
              <v-col cols="9" class="ma-0 pa-0">
                <options-component></options-component>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </div>
        </v-container>
      </section>
    </header>
  </div>
</template>
<script>
import LogoComponent from "../UI/LogoComponent.vue";
import TabNavigation from "./TabNavigation.vue";
import UserMenu from "./UserMenu.vue";
import OptionsComponent from "../Options/OptionsComponent.vue";
import PeerGroupFilter from "../UI/PeerGroupFilter.vue";

export default {
  components: {
    LogoComponent,
    TabNavigation,
    UserMenu,
    OptionsComponent,
    PeerGroupFilter,
  },
  data() {
    return {
      toggledOptions: false,
      entityValue: "",
      entity: null,
      focused: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    toggleOptions() {
      this.toggledOptions = !this.toggledOptions;
    },
    updateHeader() {
      if (this.isMobile) {
        this.focused = true;
        this.toggledOptions = false;
      }
    },
    updateEntity(e) {
      const entity = this.entityList.find((en) => en.id === e);
      this.entity = entity;
      this.entityValue = entity.name;
      localStorage.setItem("selectedEntity", entity.id);
      this.$store.dispatch("updateEntity", entity);
      this.$store.dispatch("getWaterfallData");
      if (this.isMobile) {
        this.focused = !this.focused;
        this.toggledOptions = !this.toggledOptions;
      }

      //console.log(this.$vuetify.breakpoint.mobile);

      //console.log(entity);
    },
    resetEntity(reset) {
      // reset entitiy to default one of user account but only refresh page when bank is actually channing
      // Default to Default -> No Refresh
      let defaultEntity = this.$store.getters.defaultEntity;
      //console.log(reset);
      if (!reset) {
        const storedEntity = localStorage.getItem("selectedEntity");

        if (storedEntity) {
          defaultEntity = storedEntity;
        }
      } else {
        localStorage.removeItem("selectedEntity");
      }

      const entity = this.entityList.find((en) => en.id === defaultEntity);

      if (this.entityValue != entity.name) {
        this.entityValue = entity.name;
        this.entity = entity;

        this.$store.dispatch("updateEntity", entity);
        this.$store.dispatch("getWaterfallData");
        this.$store.dispatch("getWaterfallEntityAll");
      }
    },
    async initialize() {
      await this.$store.dispatch("getEntities");
      await this.$store.dispatch("getCurrencies");
      this.$store.dispatch("getFactors");
      this.resetEntity(false);
    },
    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getEntities");
  },

  mounted() {
    this.initialize();
    if(this.isMobile){
        this.toggledOptions=true;
      }else{
        this.toggledOptions=false;
      }
  },
  computed: {
  
    toggleHeaderclass() {
      if (this.toggledOptions) {
        return "header-toggled";
      } else {
        return "header-untoggled";
      }
    },
    focusAutocomplete() {
      if (this.focused) {
        return "autocomplete-focused";
      }
      return "";
    },
    alignment() {
      if (this.focused) {
        return "start";
      }
      return "center";
    },
    headerMargin() {
      if (this.focused) {
        return "start";
      }
      return "center";
    },
    isMobile() {
      //console.log(this.focused);
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    entityValueDisplay() {
      if (this.entity) {
        //console.log(this.$vuetify.breakpoint.xs);
        if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
          //console.log(this.entity.entityCode);
          return this.entity.entityCode;
        }
        return this.entity.name;
      }
      return "";
    },
    entityList() {
      let entities = this.$store.getters.getEntities;
      //console.log(entities);
      if (entities.length) {
        return entities.sort(this.compare);
      }
      return entities;
    },
    hasEntity() {
      return !!this.entityValue;
    },
  },
};
</script>

<style scoped>
.border-low {
  width: 80%;

  margin: auto;
  height: 0;
  border-bottom: 1px solid #ccc;
  padding: 0 !important;
  opacity: 0.5;
}
hr {
  height: 1px;
  color: white;
  background-color: white;
  width: 80%;
  margin: auto;
  border: 1px;
  opacity: 0.5;
}
header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.header-toggled {
  background-image: url("../../assets/Iridium_Radial_Backgrounds.svg");
  background-repeat: no-repeat;
  background-size: 400%;
  object-fit: cover;
  opacity: 1;
  height: 190px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
@media (min-width: 1350px) {
  .header-untoggled {
    height: 240px;
  }
}
@media (max-width: 1350px) {
  .header-untoggled {
    height: 280px;
  }
}
@media (max-width: 600px) {
  .center-header {
    margin-left:-10px !important;
    width: fit-content;
  }
 
}

.container {
  padding-top: 20px;
  height: 11vh;
}
.container-1 {
  height: 5.5vh;

  margin-bottom: 0.5vh;
}
.selected-bank {
  border: none;
  margin-bottom: 8px;
  letter-spacing: 0px;
}
button {
  float: left;
  color: white;
  font-size: 0.75em;
}
.v-sheet.v-list {
  background: rgba(105, 80, 116, 0.3);
  color: white;
  box-shadow: none;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
::v-deep .v-list-item {
  min-height: 26px !important;
}
::v-deep .v-list-item__content {
  padding: 6px 0 !important;
}
.v-autocomplete__content.v-menu__content {
  box-shadow: none !important;
  border-radius: 10px !important;
}
@media (min-width: 1350px) {
  .selected-bank {
    border: none;
    margin-bottom: 8px;
    letter-spacing: 0px;
    font-size: 18px !important;
  }
  .toggle-button-hide {
    padding-left: 16px !important;
  }
  .toggle-button-show {
    margin-left: 16px !important;
  }

}
@media (max-width: 1350px) {
  .selected-bank {
    border: none;
    margin-bottom: 8px;
    letter-spacing: 0px;
    font-size: 18px !important;
  }
  .toggle-button-hide {
    padding-left: 12px !important;
  }
  .toggle-button-show {
    margin-left: 12px !important;
  }
  

}
@media (max-width: 960px) {
  hr {
    width:95%;
    margin-right:20px;
    margin-left:7px;
    margin-bottom:3px;
  }
  .toggle-button-hide {
    padding-left: 7px !important;
  }
  .toggle-button-show {
    margin-left: 7px !important;
  }
  .selected-bank {
    border: none;
    margin-bottom: 8px;
    letter-spacing: 0px;
    
    font-size: 13px !important;
  }
  .header-toggled {
    height: 120px;
  }
  .header-untoggled {
    height: 160px;
  }
  .autocomplete {
  }
  .autocomplete-focused {
    min-width: 260px !important;
    margin-left: -6vw;
  
  }
  
}

</style>