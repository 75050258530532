<template>
  <div class="animation-container">
    <Transition mode="out-in" name="fade-in">
      <div class="text" :key="text">{{ text }}</div>
    </Transition>
    <Transition mode="out-in" name="fade-in">
      <div class="subText" :key="subText">{{subText}}</div>
    </Transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "",
      subText:"",
    };
  },
  mounted() {
    this.getValue();
  },
  methods: {
    getValue() {
      //console.log("test");
      //this.text = "Understand";
      let timeout = 0;
      
      while(timeout<50000){
      timeout += 2500;
      setTimeout(
        function () {
          this.text = "Understand";
          this.subText="Valuation"
        }.bind(this),
        timeout
      );
      
      timeout += 5000;
      setTimeout(
        function () {
          this.text = "Monitor";
        }.bind(this),
        timeout
      );
      timeout += 7500;
      //console.log(this.text);
      setTimeout(
        function () {
          this.text = "Unlock";
        }.bind(this),
        timeout
      );
      }

    },
  },
};
</script>

<style scoped>
.animation-container {
  left: 0%;
  margin-left:6vw;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  text-align: left;
}
@media (min-width: 1500px) {
.subText {
  font: normal normal 200 75px/74px "guardiansans-thin";
  color: white;
  letter-spacing: 0px;
  color: white;
  
}
.text {
  color: white;
  font: normal normal 100 75px/74px "guardianegyp-medium";
}
}
@media (max-width: 1500px) {
.subText {
  font: normal normal 200 55px/54px "guardiansans-thin";
  color: white;
  letter-spacing: 0px;
  color: white;
  
}
.text {
  color: white;
  font: normal normal 100 55px/54px "guardianegyp-medium";
}
}
@media (max-width: 1100px) {
.animation-container {
  left: 0%;
  margin-left:2vw;
  top: 50%;
  position: absolute;
  transform: translate(0, -50%);
  text-align: left;
}
}
@media (max-width: 960px) {
.animation-container {
  left: 10%;
  margin-left:2vw;
  top: 80%;
  position: absolute;
  transform: translate(0, -50%);
  text-align: left;
  z-index:10;
}
}

.fade-in-enter-active {
  transition: opacity 1200ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}
</style>