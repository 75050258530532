<template>
  
  <router-link to="/predicted-valuation">
   <img
      contain
      :src="elipsis"
      class="elipsis"
      v-if="(isMobile && $route.name != 'auth' && !focused) "
    />
   <img
      contain
      :src="logo"
      class="logo"
      v-else
    />
  
  </router-link>

</template>

<script>
export default {
  props : ['focused'],
  data() {
    return {
      logo: require("../../assets/IridiumQuantLensLogo.svg"),
      elipsis : require("../../assets/Iridium_Ellipses_RGB.png")
    };
  },
  computed : {
    isMobile() {
     //console.log(this.focused)
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },

  }
};
</script>
<style scoped>
@media (min-width: 1350px) {
  .logo {
    margin-top: 15px !important;
    margin-left: 21px !important;
    width: 180px !important;
    height: 60px !important;
  }
}
@media (max-width: 1350px) {
  .logo {
     margin-top: -6px !important;
    margin-left: 12px !important;
    width: 144px !important;
    height: 48px !important;
  }
}
@media (max-width: 960px) {
  .logo {
     margin-top: 6px !important;
    margin-left: 12px !important;
    width: 144px !important;
    height: 48px !important;
  }
  a {
    height:5px;
  }
}
.elipsis {
      margin-bottom: 5px;
  width:23px;
  height:5px;

}
</style>