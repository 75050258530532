<template>
  <div>
    <v-container
      class="logo"
      ><logo-component></logo-component
    ></v-container>

    <text-animation-component></text-animation-component>
    <radial-graphic-component></radial-graphic-component>

    <div class="card">
      <h1 align="left">Login</h1>
      <form @submit.prevent="submitForm">
        <input type="email" placeholder="Email" v-model.trim="email" />
        <input type="password" placeholder="Password" v-model.trim="password" />
        <p
          style="color: red; margin-left: 15px; margin-bottom: 0px"
          v-if="error"
        >
          Please enter a valid email and password!
        </p>
        <button>Sign in</button>
      </form>
    </div>
  </div>
</template>

<script>
import RadialGraphicComponent from "@/components/UI/RadialGraphicComponent.vue";
import TextAnimationComponent from "@/components/UI/TextAnimationComponent.vue";
import LogoComponent from "@/components/UI/LogoComponent.vue";
export default {
  components: {
    RadialGraphicComponent,
    LogoComponent,
    TextAnimationComponent,
  },
  data() {
    return {
      email: "",
      password: "",
      error: false,
      message: "",
      show1: true,
      show2: false,
      show3: false,
    };
  },

  beforeCreate: function () {
    document.body.className = "login";
    document.documentElement.style.overflow = "hidden";
  },
  mounted() {
    setTimeout(
      function () {
        this.show1 = false;
        this.show2 = true;
        //console.log("hide1")
      }.bind(this),
      6000
    );

    setTimeout(
      function () {
        this.show2 = false;
        this.show3 = true;
        //console.log("hide2")
      }.bind(this),
      12000
    );
  },
  methods: {
    async submitForm() {
      // let user;
      // try {
      //   user = await Auth.signIn(actionPayload.email, actionPayload.password);
      //   //console.log(user);
      // } catch (error) {
      //   //console.log("error signing in", error);
      // }
     //console.log("signIN");
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.$router.push("/predicted-valuation");
      } catch (err) {
       //console.log(err);
        this.error = true;
      }
    },
    renderText(text, timeout) {
      setTimeout(
        function () {
          this.message = text;
          this.classAnimated = "text-animation text-animated-two";
        }.bind(this),
        timeout
      );
    },
  },
};
</script>

<style scoped>
@media (max-width: 480px) {
  .card {
    width: 90vw;
    height: 20rem;
    left: 50%;
    top: 45% !important;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  h1 {
    margin: 5px 5px 30px 10px !important;
    font: normal normal 300 50px/45px "GuardianSans-Light";
    color: white;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}
@media (min-width: 480px) {
  .card {
    min-width: 30rem;
    max-width: 50rem;
    min-height: 20rem;
  }
}

.card {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: auto;
  padding: 2rem;
  background: rgba(105, 80, 116, 0.35);
  border-radius: 16px;

  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}
input,
textarea {
  display: block;
  width: 90%;
  border: 1px solid #ccc;
  font: inherit;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  height: 31px;
  opacity: 1;
  padding-left: 10px;
  margin: 0px 15px 15px 10px;
}

h1 {
  margin: 20px 5px 30px 10px;
  font: normal normal 300 50px/45px "GuardianSans-Light";
  color: white;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
button {
  background: #695074 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: 2px solid #695074;
  opacity: 1;
  height: 31px;
  padding: 2px 10px 5px 10px;
  margin: 15px 10px 10px 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  float: left;
}
button:hover {
  border: 2px solid #ffffff;
  background: none;
  opacity: 1;
}

@media (min-width: 1350px) {
  .logo {
    float: left;
    padding-top: 17px !important;
    padding-left: 9px !important;
  }
}
@media (max-width: 1350px) {
  .logo {
    float: left;
    padding-top: 33px !important;
    padding-left: 13px !important;
  }
}
@media (max-width: 960px) {
  .logo {
    float: left;
    padding-top: 21px !important;
    padding-left: 12px !important;
  }
}
</style>