<template>
  <v-container fluid>
    <v-row style="margin-top:0px;">
      <v-col  class="pa-0 top-left"  cols="12" sm="12" xs="12" xl="6" lg="6" md="6"  
        >
         <v-divider dark style="margin:15px;border-color:#F2F2F2;opacity: 1;z-index:30" v-if="isMobile"></v-divider>
        <lollipop-chart
          chartName="Company Factors"
          :subTitle="titleValuation"
          :factors="companyFactors"
          :factorDataEntity="waterfallDataEntity"
          :factorDataPeer="waterfallDataPeer"
           :zoom="zoom"
        ></lollipop-chart
      >
      </v-col>

      <v-col  cols="12" sm="12" xs="12" xl="6" lg="6" md="6" class="pa-0 top-right"  
        >
         <v-divider dark style="margin:15px;border-color:#F2F2F2;opacity: 1;z-index:30" v-if="isMobile"></v-divider>
        <lollipop-chart
          chartName="Financial Factors"
          :subTitle="titleValuation"
          :factors="financialFactors"
          :factorDataEntity="waterfallDataEntity"
          :factorDataPeer="waterfallDataPeer"
           :zoom="zoom"
        ></lollipop-chart
      ></v-col>
    </v-row>
    <v-row  >
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6" md="6" class="pa-0 left" >
         <v-divider dark style="margin:15px;border-color:#F2F2F2;opacity: 1;z-index:30" v-if="isMobile"></v-divider>
        <lollipop-chart
          chartName="Market Factors"
          :subTitle="titleValuation"
          :factors="marketFactors"
          :factorDataEntity="waterfallDataEntity"
          :factorDataPeer="waterfallDataPeer"
           :zoom="zoom"
        ></lollipop-chart
      ></v-col>
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6" md="6" class="pa-0 right" 
        >
         <v-divider dark style="margin:15px;border-color:#F2F2F2;opacity: 1;z-index:30" v-if="isMobile"></v-divider>
        <lollipop-chart
          chartName="Economic Factors"
          :subTitle="titleValuation"
          :factors="economicFactors"
          :factorDataEntity="waterfallDataEntity"
          :factorDataPeer="waterfallDataPeer"
          :zoom="zoom"
        ></lollipop-chart
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import LollipopChart from "./LollipopChart.vue";
import { mapGetters } from "vuex";

export default {
  components: { LollipopChart },
  props: ["entityName","peerGroupName","waterfallDataPeer", "waterfallDataEntity","zoom"],
  computed: {
    ...mapGetters({
      financialFactors: "financialFactors",
      economicFactors: "economicFactors",
      marketFactors: "marketFactors",
      companyFactors: "companyFactors",
    }),
     isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
     titleValuation() {
       if(this.entityName && this.peerGroupName){
      return (
        this.entityName +
        " premium or discount vs. " +
        this.peerGroupName.text +
        " average"
      );}
      return "Premium or Discount calculation"
    },
  },
  methods: {},
};
</script>

<style scoped>
@media(min-width:960px){
  .top-left {
  padding-right: 8px !important;
  
}
.top-right {
  padding-left: 8px !important;
 
}
  
  .left {
  padding-right: 8px !important;
  margin-top:15px;
}
.right {
  padding-left: 8px !important;
  margin-top:15px;
}
}
@media(max-width:960px){
  .left {
 margin-top:20px;
  
}
.right {
  margin-top:20px;
}
.top-right {
  margin-top:20px;
 
}
.top-left {
  margin-top:0px;
}
}

</style>
