import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state()
  {
    return {
      factorCategories: [
      { description: 'Company', mobileCode: 'CMP', text: `Company factors impacting bank valuations, which include credit and ESG ratings, quality of investor relations, and other intangible company specific factors.` }, 
      { description: 'Financial',mobileCode: 'FIN', text: `Financial risk and return factors impacting bank valuations, which include for example net interest margins, cost to income ratio, cost of risk, non perfoming loans ratio, etc.` },
      { description: 'Market',mobileCode: 'MKT', text: `Stock market related factors impacting bank valuations, which include free float, stock liquidity, share price momentum and volatility, etc.` }, 
      { description: 'Economic', mobileCode: 'ECO',text: `Economic factors impacting bank valuations, which include real GDP growth and government fiscal metrics, as well as country specific and time specific macroeconomic and sociopolitical factors.` }],
      selectedCategory: '',
      financialFactors: [],
      economicFactors: [],
      marketFactors: [],
      companyFactors: [],
      esgEncoding: [
        { value: 7, name: 'Leader', short: "AAA" },
        { value: 6, name: 'Leader', short: "AA" },
        { value: 5, name: 'Average', short: "A" },
        { value: 4, name: 'Average', short: "BBB" },
        { value: 3, name: 'Average', short: "BB" },
        { value: 2, name: 'Laggard', short: "B" },
        { value: 1, name: 'Laggard', short: "CCC" },
        
      ],
      creditratingEncoding: [
        { value: 24, name: 'Prime', short: "AAA" },
        { value: 23, name: 'High grade', short: "AA+" },
        { value: 22, name: 'High grade', short: "AA" },
        { value: 21, name: 'High grade', short: "AA-" },
        { value: 20, name: 'Upper medium grade', short: "A+" },
        { value: 19, name: 'Upper medium grade', short: "A" },
        { value: 18, name: 'Upper medium grade', short: "A-" },
        { value: 17, name: 'Lower medium grade', short: "BBB+" },
        { value: 16, name: 'Lower medium grade', short: "BBB" },
        { value: 15, name: 'Lower medium grade', short: "BBB-" },
        { value: 14, name: 'Non-investment grade', short: "BB+" },
        { value: 13, name: 'Speculative', short: "BB" },
        { value: 12, name: 'Speculative', short: "BB-" },
        { value: 11, name: 'Highly speculative', short: "B+" },
        { value: 10, name: 'Highly speculative', short: "B" },
        { value: 9, name: 'Highly speculative', short: "B-" },
        { value: 8, name: 'Substantial risks', short: "CCC+" },
        { value: 7, name: 'Substantial risks', short: "CCC" },
        { value: 6, name: 'Substantial risks', short: "CCC-" },
        { value: 5, name: 'Extremely speculative', short: "CC" },
        { value: 4, name: 'Default imminent', short: "C" },
        { value: 3, name: 'In default', short: "RD" },
        { value: 2, name: 'In default', short: "SD" },
        { value: 1, name: 'In default', short: "D" },
      ],
      //different acess! Not trough array index
      ratingWatch: [
        { value: -1, name: "Negative" },
        { value: 0, name: "Stable" },
        { value: 1, name: "Positive" },
      ],
      msciEncoding: [
        { value: 0, name: 'Standalone' },
        { value: 1, name: 'Frontier' },
        { value: 2, name: 'Emerging' },
        { value: 3, name: 'Developed' }],
      irQualityEncoding: [
        { value: 0, name: 'Agnostic' },
        { value: 1, name: 'Basic' },
        { value: 2, name: 'Emerging' }],
        featureImportance : [
          "R11",
          "R07",
          "R29",
          "R05",
          "R36",
          "R09",
          "R08",
          "R31",
          "R19",
          "R34",
          "R01",
          "R42",
          "R06",
          "R04",
          "R28",
          "R02",
          "R03",
          "R21",
          "R18",
          "R20",
          "R15",
          "R14",
          "R43",
          "R12"
      ]



    };
  },
  mutations,
  actions,
  getters
};