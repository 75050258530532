<template>
  <div>
    <div class="d-md-none" style="margin-bottom: 15px">Currency</div>
    <v-select
      v-model="currentCurrency"
      class="ma-0 pa-0 currency-selector"
      :items="currencyList"
      background-color="#695074"
      dense
      rounded
      dark
      :disabled="isCurrency"
      mandatory
      @change="updateCur()"
      id="currencySelector"
     
    >
      <template
        v-slot:prepend
        v-if="
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.xl
        "
      >
        <div class="d-none d-md-block" style="margin-top: 1.5px !important">
          Currency
        </div>
      </template>
      <template v-slot:append>
        <v-icon>mdi-chevron-down</v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentCurrency: "",
    };
  },
  computed: {
    currencyList() {
      const currencies = this.$store.getters.currencies;
      return currencies.sort(this.compare);
    },
    isCurrency() {
      const selectedDisplayType = this.$store.getters.displayType;
      //console.log(selectedDisplayType);
      if (selectedDisplayType == "marketValue") {
        return false;
      }
      return true;
    },
  },
  methods: {
    compare(a, b) {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    },
    updateCur() {
      const updatedCur = this.currencyList.find(
        (value) => this.currentCurrency === value.value
      );
      //console.log(updatedCur);
      this.currentCurrency = updatedCur;

      this.$store.dispatch("updateCurrentCurrency", updatedCur);
    },
  },
  created() {
    //console.log("created");
    //console.log(this.$store.getters.currentCurrency)
    //console.log(this.currencyList)

    let value = localStorage.getItem("currencyValue");
    let text = localStorage.getItem("currencyText");
    if (text && value) {
      const storedCur = { text: text, value: Number(value) };
      this.currentCurrency = storedCur;
      this.$store.dispatch("updateCurrentCurrency", storedCur);
    } else {
      //console.log("stored")
      this.currentCurrency = { text: "test", value: 1.0 };
    }
  },
};
</script>

<style scoped>
.v-icon {
  background-color: #5ab7b7;

  margin-right: -22px;
  margin-top: 1px;
  border-radius: 25px;
}
::v-deep .v-input__slot {
  width: 100px !important;
  padding: 0 12px !important;
  padding-right: 24px !important;
  padding-left: 10px !important;
}

::v-deep .v-select.v-input--dense div.v-select__selection--comma {
  margin: 3px 4px 3px 0 !important;
}

@media (min-width: 960px) {
  .currency-selector {
    max-width: fit-content;
    margin-left: 0px !important;
    font-size: 1rem !important;
    margin-top: -7.5px !important;
    height: 2.4rem !important;
  }
}
@media (max-width: 960px) {
  .currency-selector {
    max-width: fit-content;
    margin-left: 0px !important;
    font-size: 1rem !important;
    margin-top: -1.5px !important;
    height: 2.4rem !important;
  }
}
</style>
