var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.lg ||_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.xl)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","align":"center"}},[_c('v-slide-group',{key:_vm.counter,ref:"slideGroup",attrs:{"center-active":"","mandatory":"","show-arrows":""},on:{"change":_vm.updateSelectedGroup}},_vm._l((_vm.factors),function(n){return _c('v-slide-item',{key:n.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1 text-none ",staticStyle:{"border":"1.5px solid #b9b2bc","color":"#3F2A48","letter-spacing":"0px","font-size":"1rem !important","line-height":"1rem"},attrs:{"input-value":active,"medium":"","rounded":"","height":"2.067rem","elevation":"0","outlined":"","active-class":"secondary white--text"},on:{"click":toggle}},[_vm._v(" "+_vm._s(n.description)+" ")])]}}],null,true)})}),1)],1)],1):_c('v-row',{staticStyle:{"padding-top":"0px !important"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"margin-left":"23px !important"},attrs:{"cols":"12"}},[_c('v-select',{staticClass:"ma-0 pa-0",staticStyle:{"max-width":"220px !important","margin-right":"0px !important","margin-bottom":"0px !important","z-index":"5","font-size":"13px !important","max-height":"600px !important"},attrs:{"justify":"end","align":"end","items":_vm.factors,"item-text":"description","item-value":"description","background-color":"secondary","dense":"","height":"31","color":"white","item-color":"white","placeholder":"Select Factors","menu-props":{ maxHeight: 600 },"rounded":"","dark":"","mandatory":""},on:{"change":_vm.updateSelectedGroupDropdown},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"min-height":"31px !important","margin-top":"0px !important"}},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticStyle:{"color":"white !important","min-height":"31px !important","padding-top":"2px !important","font-size":"13px !important"}},[_c('v-list-item-title',{staticStyle:{"font-size":"13px !important","line-height":"1.2rem"},attrs:{"color":"white"},domProps:{"innerHTML":_vm._s(item.description)}})],1)],1)]}}]),model:{value:(_vm.selectedFactor),callback:function ($$v) {_vm.selectedFactor=$$v},expression:"selectedFactor"}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"20px !important"}},[_c('v-col',{staticClass:"ma-0 pa-0 ",attrs:{"cols":"12","sm":"12","xs":"12","xl":"6","lg":"6","md":"6","height":"100%"}},[_c('individual-drivers-table',{attrs:{"selectedFactor":_vm.selectedFactorCP,"waterfallData":_vm.individualWaterfall}})],1),(_vm.isMobile)?_c('v-divider',{staticStyle:{"margin":"1px 15px 1px 15px","border-color":"#F2F2F2","opacity":"1","z-index":"1"},attrs:{"dark":""}}):_vm._e(),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","sm":"12","xs":"12","xl":"6","lg":"6","md":"6","height":"100%"}},[_c('waffle-card',{attrs:{"selectedFactor":_vm.selectedFactorCP,"waterfallObjects":_vm.waterfallObjects}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }